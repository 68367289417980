import { RESET_ALL_HOTSOFT_UPDATES, RESET_HOTSOFT_UPDATE_DETAILS, SAVE_ALL_HOTSOFT_UPDATES, SAVE_HOTSOFT_UPDATE_DETAILS } from "../types";

const INITIAL_STATE = {
    hotsoftUpdates: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 50,
    },
    hotsoftUpdateDetails: {},
};

const hotsoftReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SAVE_ALL_HOTSOFT_UPDATES:
            return {
                ...state,
                hotsoftUpdates: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_HOTSOFT_UPDATES:
            return {
                ...state,
                hotsoftUpdates: INITIAL_STATE.hotsoftUpdates,
            };

        case SAVE_HOTSOFT_UPDATE_DETAILS:
            return {
                ...state,
                hotsoftUpdateDetails: payload
            };

        case RESET_HOTSOFT_UPDATE_DETAILS:
            return {
                ...state,
                hotsoftUpdateDetails: INITIAL_STATE.hotsoftUpdateDetails,
            };

        default:
            return state;
    }
};

export default hotsoftReducer;
