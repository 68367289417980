export const processHotsoftCalendarAvailability = (data) => {
    data?.forEach((element, index) => {
        element.data.forEach(el => {
            let filtered = element.rooms.filter(e => el.date === e.date && e?.available === true)

            if (el.available_rooms !== filtered.length) {
                let diff = Math.abs(el.available_rooms - filtered.length);
                for (let i = 0; i < diff; i++) {
                    let ind = element.rooms.findIndex(x => el.date === x.date && x?.available === true);
                    if(data[index].rooms[ind]){
                        data[index].rooms[ind].available = false;
                    }
                }
            }
        })
    });

    return data;
}