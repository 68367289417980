export const LIST_ALL_ACTIVITIES = `query ($pageSize: Int, $pageNumber: Int) {
  list_all_activities(page_size: $pageSize, page_number: $pageNumber){
    message
    data {
      _id
      name
      img
      description
      rate
      is_active
    }
    hasMore
  }
}`;

export const ADD_NEW_ACTIVITY = `mutation ($name: String!, $img: String, $description: String, $rate: Float) {
  add_new_activity(name: $name, img: $img, description: $description, rate: $rate) {
    message
  }
}
`;

export const LIST_ACTIVITY_DETAILS = `query ($id: String) {
  list_activity_details(_id: $id){
    message
    data {
      _id
      name
      img
      description
      rate
      is_active
    }
  }
}`;

export const ACTIVATE_ACTIVITY = `mutation ($id: String!) {
  activate_activity(_id: $id){
    message
  }
}`;

export const UPDATE_ACTIVITY = `mutation ($id: String!, $name: String, $img: String, $description: String, $rate: Float) {
  update_activity(_id: $id, name: $name, img: $img, description: $description, rate: $rate) {
    message
  }
}`;
