import React, { useState } from "react";
import { Box, Button, IconButton, Modal, Stack, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { Download, FileCopy } from "@mui/icons-material";
import { toast } from "react-toastify";

export default function ButtonWithModalContent({ BtnLabel = "", action, isIconButton, btnIcon }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [modal, setModal] = useState(false);
    const [data, setData] = useState("");

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { sm: 600, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    async function handleActionBtn() {
        let res = await action();
        setData(res);
        setModal(true)
    }

    return (
        <>
            {isIconButton ? (
                <IconButton onClick={() => handleActionBtn()}>
                    {btnIcon}
                </IconButton>
            ) : (
                <Button
                    size="small"
                    variant="contained"
                    endIcon={btnIcon ? btnIcon : null}
                    onClick={() => handleActionBtn()}
                >
                    <b>{BtnLabel || "Click"}</b>
                </Button>
            )}
            <Modal open={modal} onClose={() => setModal(false)}>
                <Box sx={modalStyle}>
                    <Box maxHeight={"80vh"} mb={2} overflow="auto">
                        <Typography variant="h4" sx={{ wordWrap: "break-word" }}>
                            {data ?? ""}
                        </Typography>
                    </Box>
                    <Stack direction="row" justifyContent="space-between" alignItems={"end"} spacing={2}>
                        <Stack direction="row" spacing={1} alignItems={"end"}>
                            <Button
                                size="small"
                                variant="contained"
                                endIcon={<FileCopy />}
                                onClick={() => {
                                    navigator.clipboard.writeText(data)
                                    toast.success("Copied!");
                                }}
                            >
                                <b>Copy to clipboard</b>
                            </Button>
                            <a
                                href={`data:text/json;charset=utf-8,${encodeURIComponent(data)}`}
                                download="ota_details.json"
                            >
                                <IconButton size="small">
                                    <Download />
                                </IconButton>
                            </a>
                        </Stack>
                        <Button size="small" onClick={() => setModal(false)}><b>Close</b></Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
}
