import React, { useEffect, useState } from "react";
import { Add, ArrowBack } from "@mui/icons-material";
import { Box, Button, Grid, Modal, Stack, Typography } from "@mui/material";
import { activateSubAgent, listAgentDetails, resetAgentDetails } from "../../store/actions";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { tableStyle } from "../../components";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AddNewSubAgentModalContent from "./addNewSubAgentModalContent";
import AvailabilityUpdation from "../../components/AvailabilityUpdation";

function AgentDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [showModal, setShowModal] = useState(false);

    const { isLoading, subAgentsList, details, showViewMore } = useSelector(
        ({
            loading,
            agents: {
                agentDetails: { details, list, showViewMore },
            },
        }) => ({
            isLoading: loading,
            subAgentsList: list,
            details,
            showViewMore,
        })
    );

    useEffect(() => {
        document.title = 'Room details';
        dispatch(resetAgentDetails());
        fetchMoreData();
        // eslint-disable-next-line
    }, [dispatch, id]);

    const fetchMoreData = () => {
        dispatch(listAgentDetails({ id: id }));
    }

    const columns = [
        { field: "name", headerName: "Name", width: 150 },
        { field: "email", headerName: "Email", width: 250, cellClassName: "name-column--cell", },
        { field: "mobile", headerName: "Mobile", width: 130 },
        {
            field: "is_active",
            headerName: "Availability",
            width: 100,
            renderCell: ({ row: { is_active, _id } }) => {
                return (
                    <AvailabilityUpdation
                        isActive={is_active}
                        action={() => dispatch(activateSubAgent({ id: _id, agentId: id }))}
                    />
                );
            },
        },
    ]

    const CustomFooter = () => {
        return (
            <GridFooterContainer>
                <Stack width="100%" alignItems="center">
                    {showViewMore ? (
                        <Button fullWidth onClick={fetchMoreData}>
                            <b>view more</b>
                        </Button>
                    ) : (
                        <Typography variant="h6">
                            {subAgentsList?.length ? "All datas listed" : ""}
                        </Typography>
                    )}
                </Stack>
            </GridFooterContainer>
        );
    };

    return (
        <Box m="20px" mb={15}>
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
                spacing={2}
                m="20px 0"
            >
                <Button size="small" variant="outlined" onClick={() => navigate(-1)}>
                    <ArrowBack fontSize="small" />
                </Button>
                <Typography fontWeight="bold" variant="h4">
                    AGENT DETAILS
                </Typography>
                <Box width={40} />
            </Stack>
            <Box
                width="100%"
                p={2}
                borderRadius={1}
                backgroundColor={colors.primary[400]}
            >
                <Grid container rowSpacing={2} columnSpacing={4}>
                    <Grid item md={6} xs={12}>
                        <Stack direction="row" spacing={2}>
                            <Typography width={"40%"}>Name:</Typography>
                            <Typography>
                                {details?.first_name ? `${details?.first_name} ${details?.last_name}` : "-"}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack direction="row" spacing={2}>
                            <Typography width={"40%"}>Email:</Typography>
                            <Typography>
                                {details?.email || "-"}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack direction="row" spacing={2}>
                            <Typography width={"40%"}>Mobile:</Typography>
                            <Typography>
                                {details?.mobile || "-"}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack direction="row" spacing={2}>
                            <Typography width={"40%"}>Rooms allowed:</Typography>
                            <Typography>
                                {details?.rooms_allowed || "-"}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack direction="row" spacing={2}>
                            <Typography width={"40%"}>Discount:</Typography>
                            <Typography>
                                {details?.discount || "-"}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Stack
                mt={4}
                direction="row"
                justifyContent="space-between"
                alignItems="end"
            >
                <Header title="SUB AGENTS" mb={0} />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                    }}
                    onClick={() => setShowModal(true)}
                >
                    <Add sx={{ mr: "10px" }} />
                    Add new sub agent
                </Button>
            </Stack>
            <Box
                mt={2}
                height="68vh"
                sx={tableStyle(colors)}
            >
                <DataGrid
                    rows={subAgentsList}
                    columns={columns}
                    components={{ Footer: CustomFooter }}
                    getRowId={(row) => row?._id}
                />
            </Box>

            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
            >
                <>
                    <AddNewSubAgentModalContent
                        id={id}
                        dispatch={dispatch}
                        isLoading={isLoading}
                        setModal={setShowModal}
                        colors={colors}
                    />
                </>
            </Modal>
        </Box>
    );
}

export default AgentDetails;
