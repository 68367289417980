import React, { useState } from "react";
import { Box, Stack, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import Header from "../../../../components/Header";
import FirstPage from "./firstPage";
import SecondPage from "./secondPage";
import { Close } from "@mui/icons-material";

function SwapBookedRoomModal({ room, bookingId, setSwapModal }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [page, setPage] = useState(1);

    const modalBoxStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "90vh",
        width: { sm: 400, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    return (
        <>
            <Box sx={modalBoxStyle}>
                <Stack direction="row" justifyContent="space-between">
                    <Header title="SWAP ROOM" titleVariant="h4" />
                    <Close onClick={() => setSwapModal(false)} sx={{ cursor: "pointer" }} />
                </Stack>
                {page === 1 && (
                    <FirstPage
                        room={room}
                        bookingId={bookingId}
                        setPage={setPage}
                    />
                )}
                {page === 2 && (
                    <SecondPage 
                        room={room}
                        bookingId={bookingId}
                        setPage={setPage} 
                        setSwapModal={setSwapModal} 
                    />
                )}
            </Box>
        </>
    );
}

export default SwapBookedRoomModal;
