import { Close } from '@mui/icons-material';
import { Box, Grid, IconButton, LinearProgress, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header';
import AddNewBooking from './addNewBooking';
import AvailableRoomsList from './availableRoomsList';
import CheckRoomAvailability from './checkRoomAvailability';

function NewBooking() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [progress, setProgress] = useState(33);
    const [checkingdata, setCheckingdata] = useState({});
    const [selectedRooms, setSelectedRooms] = useState([]);

    useEffect(() => {
        document.title = 'New Booking';
    }, [dispatch]);

    return (
        <Box m="20px" mb={15}>
            <Stack
                direction="row"
                // alignItems="center"
                justifyContent={"space-between"}
                spacing={2}
                mt="20px"
            >
                <Header title="NEW BOOKING" subtitle="Add new booking" />
                <Box title="close" height={"40px"}>
                    <IconButton size='large' type="button" onClick={() => navigate(-1)}>
                        <Close fontSize='' />
                    </IconButton>
                </Box>
            </Stack>
            <Grid container>
                <Grid item lg={12} xs={12}>
                    <LinearProgress variant="determinate" value={progress} />
                </Grid>
            </Grid>
            { page === 1 ?
            <CheckRoomAvailability setPage={setPage} setProgress={setProgress} setCheckingdata={setCheckingdata}/>
            : page === 2 ?
            <AvailableRoomsList setPage={setPage} setProgress={setProgress} selectedRooms={selectedRooms} setSelectedRooms={setSelectedRooms}/>
            : page === 3 ?
            <AddNewBooking setPage={setPage} checkingdata={checkingdata} selectedRooms={selectedRooms}/>
            : null }
        </Box>
    )
}

export default NewBooking;