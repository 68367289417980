import React, { useEffect } from "react";
import { Box, useTheme, useMediaQuery, Typography, Grid, Stack, LinearProgress } from "@mui/material";
import { listAllDashboardReports, resetAllDashboardReports } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../theme";
import { Icon } from "@iconify/react";
import Header from "../../components/Header";
import DashboardBooking from "./booking";
// import BarChart from "../../components/BarChart";
// import ProgressCircle from "../../components/ProgressCircle";
// import PieChart from "../../components/PieChart";

const Dashboard = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);

  const { dashboardReports } = useSelector(
    ({
      loading,
      dashboard: { dashboardReports },
    }) => ({
      isLoading: loading,
      dashboardReports,
    })
  );
  // console.log(dashboardReports);

  useEffect(() => {
    document.title = 'Dashboard';
    // localStorage.removeItem("dateNum");
    // localStorage.removeItem("year");
    // localStorage.removeItem("calType");
    // localStorage.removeItem("expanded");
    // localStorage.removeItem("grpBooking");
    // dispatch(resetAllRoomsReports());
    dispatch(resetAllDashboardReports());
    dispatch(listAllDashboardReports());
  }, [dispatch]);

  function calcPercentage(partialValue, totalValue) {
    return Math.round((partialValue / totalValue) * 100)
  } 

  return (
    <Box m="20px" mb={15}>
      {/* HEADER */}
      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
      </Box>

      {/* GRIDS */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Stack
                width="100%"
                direction="row"
                backgroundColor={colors.primary[400]}
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack width={100} height={100} p={2} bgcolor={colors.primary[800]}>
                  <Icon
                    width="60"
                    height="70"
                    icon="icomoon-free:enter"
                    color={colors.greenAccent[600]}
                  />
                </Stack>
                <Stack p={2}>
                  <Typography>TODAYS</Typography>
                  <Typography><b>CHECK-IN</b></Typography>
                </Stack>
                <Stack pr={3}>
                  <Typography fontSize={40}>{dashboardReports?.checkins || 0}</Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Stack
                width="100%"
                direction="row"
                backgroundColor={colors.primary[400]}
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack width={100} height={100} p={2} bgcolor={colors.primary[800]}>
                  <Icon 
                    width="60" 
                    height="70" 
                    icon="icomoon-free:exit" 
                    color={colors.greenAccent[600]}
                  />
                </Stack>
                <Stack p={2}>
                  <Typography>TODAYS</Typography>
                  <Typography><b>CHECK-OUT</b></Typography>
                </Stack>
                <Stack pr={3}>
                  <Typography fontSize={40}>{dashboardReports?.checkouts || 0}</Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Box backgroundColor={colors.primary[400]} p={2}>
                <Stack mb={2}>
                  <Typography>TODAYS</Typography>
                  <Typography><b>OCCUPANCY</b></Typography>
                </Stack>
                {dashboardReports?.occupancies?.map((e, i) => (
                  <Stack key={i} mb={1}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography>{e?.roomtype}</Typography>
                      <Typography fontWeight={700}>{e?.booked_rooms} / {e?.total_rooms}</Typography>
                    </Stack>
                    <LinearProgress
                      sx={{ height: 10 }}
                      color={e?.booked_rooms === e?.total_rooms ? "error" : "info"}
                      variant="determinate"
                      value={calcPercentage(e?.booked_rooms, e?.total_rooms)}
                    />
                  </Stack>
                ))}
                {!dashboardReports?.occupancies?.length ?
                  <Stack p={2} textAlign="center">
                    No data found
                  </Stack>
                : null}
              </Box>
            </Grid>

            {/* <Grid item xs={12} sm={12} md={12}>
              <Box backgroundColor={colors.primary[400]} p="30px">
                <Typography variant="h5">
                  ROOM NIGHT SALES ON
                </Typography>
                <Typography variant="h5" fontWeight="600">
                  JUNE 2023
                </Typography>
                <Box m="20px" height="75vh" p="2px">
                  <PieChart />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mt="25px"
                >
                  <PieChart />
                  <Typography
                    variant="h5"
                    color={colors.greenAccent[500]}
                    sx={{ mt: "15px" }}
                  >
                    $48,352 revenue generated
                  </Typography>
                  <Typography>
                    Includes extra misc expenditures and costs
                  </Typography>
                </Box>
              </Box>
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={12}>
              <Box mt={1} p={2} backgroundColor={colors.primary[400]}>
                <Box textTransform={"uppercase"}>
                  <Typography>Coming 6 months</Typography>
                  <Typography fontWeight="bold">Online booking</Typography>
                </Box>
                <Box height="250px" mt="-20px">
                  <BarChart isDashboard={true} />
                </Box>
              </Box>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <DashboardBooking />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
