import {
    RESET_ACTIVITY_DETAILS,
    RESET_ALL_ACTIVITIES,
    SAVE_ACTIVITY_DETAILS,
    SAVE_ALL_ACTIVITIES,
} from "../types";

const INITIAL_STATE = {
    activities: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 500,
    },
    activityDetails: {},
};

const activitiesReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SAVE_ALL_ACTIVITIES:
            return {
                ...state,
                activities: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_ACTIVITIES:
            return {
                ...state,
                activities: INITIAL_STATE.activities,
            };

        case SAVE_ACTIVITY_DETAILS:
            return {
                ...state,
                activityDetails: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ACTIVITY_DETAILS:
            return {
                ...state,
                activityDetails: INITIAL_STATE.activityDetails,
            };

        default:
            return state;
    }
};

export default activitiesReducer;
