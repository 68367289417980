import { post } from "../../services";
import { ACTIVATE_ROOMTYPE_OFFER, ACTIVATE_ROOMTYPE_OTA_COUNT, ADD_ROOMTYPE_OFFER, ADD_ROOMTYPE_OTA_COUNT, CHECK_ROOMTYPE_OFFER_RATES, LIST_ALL_ROOMTYPE_OFFERS, LIST_ALL_ROOMTYPE_OTA_COUNT, SYNC_ROOMTYPE_OFFER, SYNC_ROOMTYPE_OTA_COUNT, UPDATE_ROOMTYPE_OFFER } from "../../graphql";
import { RESET_ALL_ROOMTYPE_OFFERS, RESET_ALL_ROOMTYPE_OTA_COUNT, SAVE_ALL_ROOMTYPE_OFFERS, SAVE_ALL_ROOMTYPE_OTA_COUNT, TOGGLE_LOADING } from "../types";
import { toast } from "react-toastify";

export const listAllRoomtypeOffers = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            offers: { pageSize, pageNumber, list },
        } = getState().roomtypes;

        let apiResponse = await post("", {
            query: LIST_ALL_ROOMTYPE_OFFERS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_roomtype_offers: { data, hasMore },
            } = apiResponse.data.data;
            let updatedList = [...list, ...data];

            dispatch({
                type: SAVE_ALL_ROOMTYPE_OFFERS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllRoomtypeOffers = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_ROOMTYPE_OFFERS });
    };
};

export const addRoomtypeOffer = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_ROOMTYPE_OFFER,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllRoomtypeOffers());
            dispatch(listAllRoomtypeOffers({ roomtypeId:requestData.roomtypeId }));
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateRoomtypeOffer = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_ROOMTYPE_OFFER,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllRoomtypeOffers());
            dispatch(listAllRoomtypeOffers({ roomtypeId:requestData.roomtypeId }));
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const checkOfferRates = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: CHECK_ROOMTYPE_OFFER_RATES,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                check_roomtype_offer_rates: { data },
            } = apiResponse.data.data;
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return data;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const activateRoomtypeOffer = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ACTIVATE_ROOMTYPE_OFFER,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllRoomtypeOffers());
            dispatch(listAllRoomtypeOffers({ roomtypeId:requestData.roomtypeId }));
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllRoomtypeOtaCount = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            otaCounts: { pageSize, pageNumber, list },
        } = getState().roomtypes;

        let apiResponse = await post("", {
            query: LIST_ALL_ROOMTYPE_OTA_COUNT,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_roomtype_ota_count: { data, hasMore },
            } = apiResponse.data.data;
            let updatedList = [...list, ...data];
            
            dispatch({
                type: SAVE_ALL_ROOMTYPE_OTA_COUNT,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllRoomtypeOtaCount = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_ROOMTYPE_OTA_COUNT });
    };
};

export const addRoomtypeOtaCount = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_ROOMTYPE_OTA_COUNT,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllRoomtypeOtaCount());
            dispatch(listAllRoomtypeOtaCount({ roomtypeId:requestData.roomtypeId }));
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const activateRoomtypeOtaCount = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ACTIVATE_ROOMTYPE_OTA_COUNT,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllRoomtypeOtaCount());
            dispatch(listAllRoomtypeOtaCount({ roomtypeId:requestData.roomtypeId }));
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const syncRoomtypeOffer = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: SYNC_ROOMTYPE_OFFER,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllRoomtypeOffers());
            dispatch(listAllRoomtypeOffers({ roomtypeId: requestData.roomtypeId }));
            toast.success("Data will be synced shortly!")
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const syncRoomtypeOtaCount = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: SYNC_ROOMTYPE_OTA_COUNT,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllRoomtypeOtaCount());
            dispatch(listAllRoomtypeOtaCount({ roomtypeId: requestData.roomtypeId }));
            toast.success("Data will be synced shortly!")
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};