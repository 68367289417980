
import { unAuthorizedPost } from "../../services";
import { LOGIN_WITH_OTP, LOGIN_WITH_PASSWORD, SEND_OTP, VERIFY_HOTEL_TOKEN } from "../../graphql";
import { LOGIN_SUCCESS, LOGOUT, TOGGLE_LOADING } from "../types";

export const sendOtp = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: SEND_OTP,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            // let {
            //     send_otp: { message },
            // } = apiResponse.data.data;
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        } else {
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return false;
        }
    };
};

export const loginWithOtp = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: LOGIN_WITH_OTP,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                hotel_login: { token },
            } = apiResponse.data.data;

            dispatch({
                type: LOGIN_SUCCESS,
                payload: token,
            });

            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const loginWithPassword = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: LOGIN_WITH_PASSWORD,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                login_with_password: { token },
            } = apiResponse.data.data;

            dispatch({
                type: LOGIN_SUCCESS,
                payload: token,
            });

            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const adminLoginToHotel = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: VERIFY_HOTEL_TOKEN,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                verify_hotel_token: { status },
            } = apiResponse.data.data;

            if(status === true){
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: requestData.token,
                });
            }

            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const logoutUser = () => {
    return (dispatch) => {
        localStorage.clear();
        dispatch({ type: LOGOUT });
    };
};
