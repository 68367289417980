import { post } from "../../services";
import { LIST_ALL_DASHBOARD_REPORTS } from "../../graphql";
import { 
    RESET_ALL_DASHBOARD_REPORTS,
    SAVE_ALL_DASHBOARD_REPORTS, 
    TOGGLE_LOADING 
} from "../types";

export const listAllDashboardReports = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: LIST_ALL_DASHBOARD_REPORTS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_dashboard_reports: { data },
            } = apiResponse.data.data;
            dispatch({
                type: SAVE_ALL_DASHBOARD_REPORTS,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllDashboardReports = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_DASHBOARD_REPORTS });
    };
};

// export const addNewAgent = (requestData) => {
//     return async (dispatch) => {
//         dispatch({ type: TOGGLE_LOADING, payload: true });

//         let apiResponse = await post("", {
//             query: ADD_NEW_AGENT,
//             variables: requestData,
//         });

//         if (apiResponse.data.customStatus) {
//             let {
//                 add_new_agent: { _id },
//             } = apiResponse.data.data;
//             dispatch(resetAllHotelAgents());
//             dispatch(listAllHotelAgents());
//             return _id;
//         }
//         dispatch({ type: TOGGLE_LOADING, payload: false });
//     };
// };