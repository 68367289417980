import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Stack, Typography, useTheme } from "@mui/material";
import { activateAgent, listAllHotelAgents, resetAllHotelAgents } from "../../store/actions";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Add, ArrowForward, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { tableStyle } from "../../components";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AddNewAgentModalContent from "./addNewAgentModalContent";
import AvailabilityUpdation from "../../components/AvailabilityUpdation";
import UpdateAgentModalContent from "./updateAgentModalContent";

const AgentsList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [updateAgentData, setUpdateAgentData] = useState({});

  const { isLoading, agentsList, showViewMore } = useSelector(
    ({
      loading,
      agents: {
        agents: { list, showViewMore },
      },
    }) => ({
      isLoading: loading,
      agentsList: list,
      showViewMore,
    })
  );

  useEffect(() => {
    document.title = 'Agents';
    dispatch(resetAllHotelAgents());
    fetchMoreData();
    // eslint-disable-next-line
  }, [dispatch]);

  const fetchMoreData = () => {
    dispatch(listAllHotelAgents());
  }

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Stack width="100%" alignItems="center">
          {showViewMore ? <Button fullWidth onClick={fetchMoreData}>
            <b>view more</b>
          </Button> : <Typography variant="h6">All datas listed</Typography>}
        </Stack>
      </GridFooterContainer>
    );
  }

  const columns = [
    {
      field: "",
      headerName: "",
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      width: 20,
    },
    {
      field: "first_name",
      headerName: "Name",
      width: 150,
      renderCell: ({ row: { first_name, last_name } }) => `${first_name || "-"} ${last_name || ""}`,
    },
    { field: "email", headerName: "Email", width: 250, cellClassName: "name-column--cell" },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 130,
      renderCell: ({ row: { mobile } }) => (mobile ? mobile : "-"),
    },
    {
      field: "is_active",
      headerName: "Availability",
      width: 100,
      renderCell: ({ row: { is_active, _id } }) => {
        return (
          <AvailabilityUpdation
            isActive={is_active}
            action={() => dispatch(activateAgent({ id: _id }))}
          />
        );
      },
    },
    {
      field: "last_name",
      headerName: "Update",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setUpdateAgentData(row);
              setUpdateModal(true);
            }}
            endIcon={<Edit fontSize="small" />}
            sx={{ fontWeight: 700 }}
          >
            Update
          </Button>
        );
      },
    },
    {
      field: "_id",
      headerName: "View details",
      width: 150,
      renderCell: ({ row: { _id } }) => {
        return (
          <Button
            size="small"
            variant="contained"
            onClick={async () => {
              navigate(`/agent-details/${_id}`);
            }}
            endIcon={<ArrowForward fontSize="small" />}
            sx={{ fontWeight: 700 }}
          >
            View
          </Button>
        );
      },
    },
  ];
  return (
    <Box m="20px" mb={15}>
      <Stack
        direction={{ sm: "row", xs: "column" }}
        justifyContent={{ sm: "space-between", xs: "start" }}
        alignItems={{ sm: "center", xs: "start" }}
        m="20px 0"
      >
        <Header title="AGENTS" subtitle="welcome to Agents" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={() => setShowModal(true)}
          >
            <Add sx={{ mr: "10px" }} />
            Add new agent
          </Button>
        </Box>
      </Stack>
      <Box
        m="8px 0 0 0"
        height="68vh"
        sx={tableStyle(colors)}
      >
        <DataGrid
          rows={agentsList}
          columns={columns}
          // components={{ Footer: CustomFooter }}
          getRowId={(row) => row?._id}
        />
      </Box>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <>
          <AddNewAgentModalContent
            dispatch={dispatch}
            isLoading={isLoading}
            setModal={setShowModal}
            colors={colors}
          />
        </>
      </Modal>
      
      <Modal
        open={updateModal}
        onClose={() => setUpdateModal(false)}
      >
        <>
          <UpdateAgentModalContent
            dispatch={dispatch}
            isLoading={isLoading}
            setModal={setUpdateModal}
            colors={colors}
            updateAgentData={updateAgentData}
          />
        </>
      </Modal>

    </Box>
  );
};

export default AgentsList;
