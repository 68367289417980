import jwtDecode from "jwt-decode";

export const isLoggedIn = (token) => {
    if (token !== null) {
        let decoded = jwtDecode(token);

        if (decoded) {
            const now = Date.now().valueOf() / 1000;
            if (typeof decoded.exp !== "undefined" && decoded.exp < now) {
                return false;
            }
            storeHotelId(decoded.property);
            storeHotelName(decoded.name);
            storeHotelType(decoded.is_dependent);
            storeIsConnectedRooms(decoded.is_connected);
            storeIsRevenueManager(decoded.revenue_manager);
            storeIsHotsoft(decoded.hotsoft);
            storeUserRole(decoded.role);
            return true;
        } else {
            return false;
        }
    }

    return false;
};

const storeHotelType = (isDependent) => {
    if (isDependent && isDependent !== "") {
        localStorage.setItem("isDependent", isDependent);
    }
    else {
        localStorage.setItem("isDependent", "");
    }
};

const storeHotelName = (hotelName) => {
    if (hotelName && hotelName !== "") {
        localStorage.setItem("hotelName", hotelName);
    }
    else {
        localStorage.setItem("hotelName", "");
    }
};

const storeIsConnectedRooms = (isConnectedRooms) => {
    if (isConnectedRooms && isConnectedRooms !== "") {
        localStorage.setItem("isConnectedRooms", isConnectedRooms);
    }
    else {
        localStorage.setItem("isConnectedRooms", "");
    }
};

const storeIsRevenueManager = (isRevenueManager) => {
    if (isRevenueManager && isRevenueManager !== "") {
        localStorage.setItem("isRevenueManager", isRevenueManager);
    }
    else {
        localStorage.setItem("isRevenueManager", "");
    }
};

const storeIsHotsoft = (isHotsoft) => {
    if (isHotsoft && isHotsoft !== "") {
        localStorage.setItem("isHotsoft", isHotsoft);
    }
    else {
        localStorage.setItem("isHotsoft", "");
    }
};

const storeHotelId = (hotelId) => {
    if (hotelId && hotelId !== "") {
        localStorage.setItem("hotelId", hotelId);
    }
    else {
        localStorage.setItem("hotelId", "");
    }
};

const storeUserRole = (userRole) => {
    if (userRole && userRole !== "") {
        localStorage.setItem("userRole", userRole);
    }
    else {
        localStorage.setItem("userRole", "");
    }
};