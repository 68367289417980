import { post } from "../../services";
import { 
    LIST_ALL_BOOKINGS, ADD_NEW_BOOKING, ACTIVATE_MEAL_PLAN, DELETE_MEAL_PLAN, SWAP_BOOKED_ROOMS, UPDATE_BOOKING_DATAS, UPDATE_ALL_BOOKING_STATUS, 
    FETCH_BOOKING_DETAILS, UPDATE_BOOKING_DATES, ADD_GROUP_BOOKING, ADD_NEW_GROUP_BOOKING, UPDATE_BOOKED_ROOM_DETAILS,
    UPDATE_BOOKING_DETAILS, UPDATE_CHECKIN_DETAILS, LIST_ALL_MEAL_PLANS, ADD_NEW_MEAL_PLAN, UPDATE_BOOKED_ROOM_STATUS, RESEND_BOOKING_CONFIRMATION, UPDATE_MEAL_PLAN,
} from "../../graphql";
import { 
    RESET_ALL_BOOKINGS, RESET_ALL_MEAL_PLANS, RESET_BOOKING_DETAILS, SAVE_ALL_BOOKINGS, 
    SAVE_ALL_MEAL_PLANS, 
    SAVE_BOOKING_DETAILS, TOGGLE_LOADING 
} from "../types";
import { listAllRoomsReports, resetAllRoomsReports } from "./calendarActions";
import { toast } from "react-toastify";

export const addNewBooking = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_BOOKING,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllBookingsData());
            dispatch(listAllBookings());
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllBookings = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            bookings: { pageSize, pageNumber },
        } = getState().admin;

        let apiResponse = await post("", {
            query: LIST_ALL_BOOKINGS,
            variables: {
                ...requestData,
                pageSize: requestData?.pageSize || pageSize,
                pageNumber: requestData?.pageNumber || pageNumber,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_bookings: { data, count },
            } = apiResponse.data.data;

            dispatch({
                type: SAVE_ALL_BOOKINGS,
                payload: {
                    list: data,
                    showViewMore: false,
                    pageSize: requestData?.pageSize || pageSize,
                    pageNumber: requestData?.pageNumber || pageNumber,
                    count: count,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllBookingsData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_BOOKINGS });
    };
};

export const listBookingDetails = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_BOOKING_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_booking_details: { data },
            } = apiResponse.data.data;
            dispatch({
                type: SAVE_BOOKING_DETAILS,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetBookingDetails = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_BOOKING_DETAILS });
    };
};

export const updateBookingDetails = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_BOOKING_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetBookingDetails());
            dispatch(listBookingDetails({ bookingId: requestData?.booking_id }));
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateCheckinOrcheckout = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_BOOKING_DATES,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                update_booking_dates: { status, rooms },
            } = apiResponse.data.data;

            dispatch({ type: TOGGLE_LOADING, payload: false });
            return {status, rooms};
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resendBookingEmails = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: RESEND_BOOKING_CONFIRMATION,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateAllBookingStatus = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_ALL_BOOKING_STATUS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetBookingDetails());
            dispatch(listBookingDetails({ bookingId: requestData?.bookingId }));
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateCheckinDetails = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_CHECKIN_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const addNewGroupBooking = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_GROUP_BOOKING,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            // dispatch(resetAllRoomsReports());
            // dispatch(listAllRoomsReports());
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const addGroupBooking = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_GROUP_BOOKING,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            await dispatch(resetAllRoomsReports());
            await dispatch(listAllRoomsReports());
            toast.success("Booking completed");
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateBookedRoomStatus = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_BOOKED_ROOM_STATUS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetBookingDetails());
            dispatch(listBookingDetails({ bookingId: requestData?.booking_id }));
            toast.success("Status updated");
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateBookedRoomsDates = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_BOOKED_ROOM_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetBookingDetails());
            dispatch(listBookingDetails({ bookingId: requestData?.booking_id }));
            toast.success("Dates updated");
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const swapBookedRooms = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: SWAP_BOOKED_ROOMS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetBookingDetails());
            dispatch(listBookingDetails({ bookingId: requestData?.booking_id }));
            toast.success("Rooms swaped");
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateBookingDatas = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_BOOKING_DATAS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetBookingDetails());
            dispatch(listBookingDetails({ bookingId: requestData?.booking_id }));
            toast.success("Details updated");
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const addNewMealPlan = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_MEAL_PLAN,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllMealPlansData());
            dispatch(listAllMealPlans({ roomtypeId: requestData.roomtypeId }));
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllMealPlans = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            mealPlans: { pageSize, pageNumber, list },
        } = getState().booking;

        let apiResponse = await post("", {
            query: LIST_ALL_MEAL_PLANS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_meal_plans: { data, hasMore },
            } = apiResponse.data.data;
            let updatedList = [...list, ...data];

            dispatch({
                type: SAVE_ALL_MEAL_PLANS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const getMealPlansForDropdown = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: LIST_ALL_MEAL_PLANS,
            variables: {
                isForDropdown: true,
                pageSize: 500,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_meal_plans: { data },
            } = apiResponse.data.data;

            dispatch({ type: TOGGLE_LOADING, payload: false });
            return data;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllMealPlansData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_MEAL_PLANS });
    };
};

export const updateMealPlan = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_MEAL_PLAN,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllMealPlansData());
            dispatch(listAllMealPlans({ roomtypeId: requestData.roomtypeId }));
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const activateMealPlan = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ACTIVATE_MEAL_PLAN,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllMealPlansData());
            dispatch(listAllMealPlans({ roomtypeId: requestData.roomtypeId }));
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const deleteMealPlan = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: DELETE_MEAL_PLAN,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllMealPlansData());
            dispatch(listAllMealPlans({ roomtypeId: requestData.roomtypeId }));
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};