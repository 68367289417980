import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Stack, Typography, useTheme } from "@mui/material";
import { activateUser, listAllHotelUsers, resetAllHotelUsers } from "../../store/actions";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Add } from "@mui/icons-material";
import { tableStyle } from "../../components";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AddNewUserModal from "./addNewUserModal";
import AvailabilityUpdation from "../../components/AvailabilityUpdation";

const HotelUsers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const { isLoading, usersList, showViewMore } = useSelector(
    ({
      loading,
      users: {
        users: { list, showViewMore },
      },
    }) => ({
      isLoading: loading,
      usersList: list,
      showViewMore,
    })
  );

  useEffect(() => {
    document.title = 'Staffs';
    dispatch(resetAllHotelUsers());
    fetchMoreData();
    // eslint-disable-next-line
  }, [dispatch]);

  const fetchMoreData = () => {
    dispatch(listAllHotelUsers());
  }

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Stack width="100%" alignItems="center">
          {showViewMore ? <Button fullWidth onClick={fetchMoreData}>
            <b>view more</b>
          </Button> : <Typography variant="h6">All datas listed</Typography>}
        </Stack>
      </GridFooterContainer>
    );
  }

  const columns = [
    {
      field: "first_name",
      headerName: "Name",
      width: 150,
      renderCell: ({ row: { first_name, last_name } }) => (`${first_name || "-"} ${last_name || ""}`),
    },
    { field: "email", headerName: "Email", width: 250, cellClassName: "name-column--cell" },
    { field: "role", headerName: "Role", width: 150, },
    {
      field: "is_active",
      headerName: "Availability",
      width: 100,
      renderCell: ({ row: { is_active, _id } }) => {
        return (
          <AvailabilityUpdation
            isActive={is_active}
            action={() => dispatch(activateUser({ id: _id }))}
          />
        );
      },
    },
  ];
  return (
    <Box m="20px" mb={15}>
      <Stack
        direction={{ sm: "row", xs: "column" }}
        justifyContent={{ sm: "space-between", xs: "start" }}
        alignItems={{ sm: "center", xs: "start" }}
        m="20px 0"
      >
        <Header title="STAFFS" subtitle="welcome to Staffs" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={() => setShowModal(true)}
          >
            <Add sx={{ mr: "10px" }} />
            Add new staff
          </Button>
        </Box>
      </Stack>
      <Box
        m="8px 0 0 0"
        height="68vh"
        sx={tableStyle(colors)}
      >
        <DataGrid
          rows={usersList}
          columns={columns}
          components={{ Footer: CustomFooter }}
          getRowId={(row) => row?._id}
        />
      </Box>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <>
          <AddNewUserModal
            dispatch={dispatch}
            isLoading={isLoading}
            setModal={setShowModal}
            colors={colors}
          />
        </>
      </Modal>
    </Box>
  );
};

export default HotelUsers;
