import React from "react";
import { Box, Divider, Grid, Stack, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import moment from "moment";

function BookingsSummary({ checkin, checkout, numOfAdults, numOfchildren, roomsData = [] }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const FieldAndValue = ({ field, value }) => (
    <Grid item xs={12} md={12}>
      <Stack direction="row" spacing={2} justifyContent={"space-between"}>
        <Typography>{field}</Typography>
        <Typography>{value}</Typography>
      </Stack>
    </Grid>
  );

  return (
    <Box mb={1}>
      <Grid container spacing={2} rowSpacing={1} mb={1}>
        <FieldAndValue field={"Check-in :"} value={moment.unix(checkin).format("DD-MM-YYYY")} />
        <FieldAndValue field={"Check-out :"} value={moment.unix(checkout).format("DD-MM-YYYY")} />
        <FieldAndValue field={"Number of adults :"} value={numOfAdults} />
        <FieldAndValue field={"Number of children :"} value={numOfchildren} />
        {roomsData?.map((el, idx) => (
          <Grid item xs={12} md={12} key={idx}>
            <Stack
              // p={1}
              borderRadius={2}
              overflow="hidden"
              border={1}
              borderColor={colors.greenAccent[600]}
              backgroundColor={colors.primary[500]}
              direction="row"
              spacing={1}
              sx={{ cursor: "pointer" }}
            >
              <Stack bgcolor={colors.greenAccent[600]} p={1} alignItems="center">
                <Typography noWrap>
                  <small>Room No</small>
                </Typography>
                <Typography fontSize={20} fontWeight={600}>
                  {el?.room_no}
                </Typography>
              </Stack>
              <Stack p={1}>
                <Stack color={colors.greenAccent[500]}>
                  <b>{el?.roomtype_id?.name}</b>
                </Stack>
                <Stack>Rs {el?.rate}</Stack>
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
      <Divider />
    </Box>
  );
}

export default BookingsSummary;
