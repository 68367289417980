export const LIST_ALL_HOTSOFT_AVAILAVILITY_UPDATES = `query ($pageSize: Int, $pageNumber: Int, $startDate: Int, $endDate: Int) {
  list_all_hotsoft_availability_updates(page_size: $pageSize, page_number: $pageNumber, start_date: $startDate, end_date: $endDate){
    message
    data {
      _id
      start_date
      end_date
      avail_count
      cron_avail_count
      stop_sell
      roomtype_id {
        _id
        name
      }
    } 
    hasMore
    count
  }
}`;

export const LIST_HOTSOFT_UPDATE_DETAILS = `query ($id: String!) {
  list_hotsoft_update_details(_id: $id){
    message
    data {
      _id
      start_date
      end_date
      avail_count
      cron_avail_count
      stop_sell
      created_at
      updated_at
      cron_updated_at
      hotsoft_hotel_id
      hotsoft_room_id
      roomtype_id {
        _id
        name
        description
      }
    }
  }
}`;