import {
    SAVE_BOOKING_DETAILS,
    RESET_BOOKING_DETAILS,
    SAVE_ALL_MEAL_PLANS,
    RESET_ALL_MEAL_PLANS
} from "../types";

const INITIAL_STATE = {
    bookingDetails: {},
    mealPlans: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 10,
    },
};

const bookingReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SAVE_BOOKING_DETAILS:
            return {
                ...state,
                bookingDetails: payload
            };

        case RESET_BOOKING_DETAILS:
            return {
                ...state,
                bookingDetails: INITIAL_STATE.bookingDetails,
            };

        case SAVE_ALL_MEAL_PLANS:
            return {
                ...state,
                mealPlans: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_MEAL_PLANS:
            return {
                ...state,
                mealPlans: INITIAL_STATE.mealPlans,
            };

        default:
            return state;
    }
};

export default bookingReducer;
