export const LIST_ALL_HOTEL_USERS = `query ($pageSize: Int, $pageNumber: Int) {
  list_all_hotel_users(page_size: $pageSize, page_number: $pageNumber){
    message
    data {
      _id
      first_name
      last_name
      email
      mobile
      role
      is_active
    }
    hasMore
  }
}`;

export const ADD_NEW_USER = `mutation ($firstName: String, $lastName: String, $email: String!, $role: String!) {
  add_new_user(first_name: $firstName, last_name: $lastName, email: $email, role: $role){
    message
  }
}`;

export const ACTIVATE_HOTEL_USER = `mutation ($id: String!) {
  activate_hotel_user(_id: $id){
    message
  }
}`;