import { Box, ImageList, ImageListItem, Modal, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

function ImgListView({ images = [] }) {
    const [imgModal, setImgModal] = useState(false);
    const [img, setImg] = useState("");

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { sm: "fit-content", xs: "90%" },
        maxHeight: "90vh",
        outline: "none",
        boxShadow: 24,
        // p: 2,
    };

    return (
        <Box border={"1px solid #777"} p={"0 10px"} borderRadius={2}>
            {images?.length > 0 && (
                <ImageList
                    variant="quilted"
                    cols={4}
                    rowHeight={121}
                >
                    {images.map((item, i) => (
                        // <a href={item} target="_blank" rel="noreferrer" key={i}>
                            <ImageListItem cols={1} rows={1} className="img-container" key={i}>
                                <img
                                    src={`${item}?w=164&h=164&fit=crop&auto=format`}
                                    srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.title}
                                    loading="eager"
                                    className="image"
                                />
                                <div className="middle">
                                    <div 
                                        className="text"
                                        onClick={() => {
                                            setImg(item);
                                            setImgModal(true);
                                        }}
                                    >
                                        View Image
                                    </div>
                                </div>
                            </ImageListItem>
                        // </a>
                    ))}
                </ImageList>
            )}
            {images?.length < 1 && (
                <Typography m={2} color={"#bbb"}>
                    no images found!
                </Typography>
            )}

            <Modal
                open={imgModal}
                onClose={() => setImgModal(false)}
            >
                <Box sx={modalStyle}>
                    <Stack width={"100%"} height={"100%"}>
                        <a href={img} target="_blank" rel="noreferrer">
                            <img width="100%" height="100%" src={img} alt='' />
                        </a>
                    </Stack>
                </Box>
            </Modal>
        </Box>
    )
}

export default ImgListView