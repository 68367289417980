import { post } from "../../services";
import {
    ACTIVATE_ROOM,
    ADD_NEW_ROOM,
    LIST_ALL_ROOMS,
    LIST_ROOM_DETAILS,
    UPDATE_CONNECTED_ROOMS,
    UPDATE_ROOM,
} from "../../graphql";
import {
    RESET_ALL_ROOMS_LIST,
    RESET_ROOM_DETAILS,
    SAVE_ALL_ROOMS_LIST,
    SAVE_ROOM_DETAILS,
    TOGGLE_LOADING,
} from "../types";

export const addNewRoom = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_ROOM,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllRoomsList());
            dispatch(listAllRooms());
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllRooms = (requestData, isForDropdown) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            rooms: { pageSize, pageNumber, list },
        } = getState().admin;

        let apiResponse = await post("", {
            query: LIST_ALL_ROOMS,
            variables: {
                pageSize: isForDropdown ? 1000 : pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_rooms: { data, hasMore },
            } = apiResponse.data.data;
            let updatedList = [...list, ...data];
            dispatch({
                type: SAVE_ALL_ROOMS_LIST,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllRoomsList = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_ROOMS_LIST });
    };
};

export const updateRoom = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_ROOM,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetRoomDetails());
            dispatch(listRoomDetails({id: requestData?.id}));
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const activateRoom = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ACTIVATE_ROOM,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllRoomsList());
            dispatch(listAllRooms());
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listRoomDetails = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: LIST_ROOM_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_room_details: { data },
            } = apiResponse.data.data;
            dispatch({
                type: SAVE_ROOM_DETAILS,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetRoomDetails = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ROOM_DETAILS });
    };
};

export const updateConnectedRooms = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_CONNECTED_ROOMS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetRoomDetails());
            dispatch(listRoomDetails({ id: requestData?.id }));
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};