import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, MenuItem, Modal, Stack, Typography, useTheme } from "@mui/material";
import { activateHotelOtas, authorizedPost, getOtaPropertyDetails, listAllHotelOtas, resetAllHotelOtas, syncHotelOtas, toggleLoading } from "../../store/actions";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { tableStyle } from "../../components";
import { tokens } from "../../theme";
import { Add, Sync } from "@mui/icons-material";
import Header from "../../components/Header";
import AvailabilityUpdation from "../../components/AvailabilityUpdation";
import ButtonWithConfirmModal from "../../components/ButtonWithConfirmModal";
import moment from "moment";
import { CssTextField } from "../../components/TextField";
import { ADD_NEW_OTA } from "../../graphql";
import ButtonWithModalContent from "../../components/ButtonWithModalContent";
import { otasExceptBooklogic } from "../../data";

const HotelOtas = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const [selectedOta, setSelectedOta] = useState("");
  const [hotelCode, setHotelCode] = useState("");
  const [bearerToken, setBearerToken] = useState("");
  const [open, setOpen] = useState(false);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { sm: 400, xs: "90%" },
    bgcolor: colors.primary[400],
    outline: "none",
    boxShadow: 24,
    p: 2,
  };

  const { isLoading, otasList, showViewMore } = useSelector(
    ({
      loading,
      otas: {
        otas: { list, showViewMore },
      },
    }) => ({
      isLoading: loading,
      otasList: list,
      showViewMore,
    })
  );

  useEffect(() => {
    document.title = 'OTAs';
    dispatch(resetAllHotelOtas());
    fetchMoreData();
    // eslint-disable-next-line
  }, [dispatch]);

  const fetchMoreData = () => {
    dispatch(listAllHotelOtas());
  }

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Stack width="100%" alignItems="center">
          {showViewMore ? <Button fullWidth onClick={fetchMoreData}>
            <b>view more</b>
          </Button> : <Typography variant="h6">All datas listed</Typography>}
        </Stack>
      </GridFooterContainer>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(toggleLoading(true));
    let data = {
      ota: selectedOta,
      hotelCode: hotelCode,
    }
    if (selectedOta === "goibibo"){
      data.bearerToken = bearerToken;
    }
    console.log(data);
    await authorizedPost(ADD_NEW_OTA, data);
    await dispatch(resetAllHotelOtas());
    await fetchMoreData();
    setSelectedOta("");
    setHotelCode("");
    setBearerToken("");
    setOpen(false);
    dispatch(toggleLoading(false));
  }

  const columns = [
    { field: "ota", headerName: "Name", width: 150, cellClassName: "name-column--cell" },
    { field: "hotel_code", headerName: "Hotel code", width: 150, },
    {
      field: "is_active",
      headerName: "Availability",
      width: 130,
      renderCell: ({ row: { is_active, _id } }) => {
        return (
          <AvailabilityUpdation
            isActive={is_active}
            action={() => dispatch(activateHotelOtas({ id: _id }))}
          />
        );
      },
    },
    {
      field: "_id",
      headerName: "Sync",
      width: 130,
      renderCell: ({ row: { _id } }) => {
        return (
          <ButtonWithConfirmModal
            BtnLabel="Sync"
            text="Are you sure to sync ?"
            action={() => dispatch(syncHotelOtas({ id: _id }))}
            btnIcon={<Sync />}
          />
        );
      },
    },
    {
      field: "last_sync",
      headerName: "Last sync",
      width: 180,
      renderCell: ({ row: { last_sync } }) => last_sync ? moment.unix(last_sync).format("DD-MMM-YYYY - HH:mm") : "-",
    },
    {
      field: "sync_status",
      headerName: "Sync status",
      width: 120,
      renderCell: ({ row: { sync_status } }) => sync_status ? sync_status : "-",
    },
    {
      field: "",
      headerName: "OTA property details",
      width: 160,
      renderCell: ({ row: { _id } }) => {
        return (
          <ButtonWithModalContent
            BtnLabel="Get details"
            action={async() => {
              let res = await dispatch(getOtaPropertyDetails({ id: _id }));
              return res;
            }}
            // btnIcon={<Sync />}
          />
        );
      },
    },
  ];
  return (
    <Box m="20px" mb={15}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        m="20px 0"
      >
        <Header title="OTAS" subtitle="welcome to OTAs" />
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: { sm: "10px 20px", xs: "5px 10px" },
            }}
            onClick={() => setOpen(true)}
          >
            <Add sx={{ mr: "10px" }} />
            Add new OTA
          </Button>
        </Box>
      </Stack>
      <Box
        m="8px 0 0 0"
        height="68vh"
        sx={tableStyle(colors)}
      >
        <DataGrid
          rows={otasList}
          columns={columns}
          components={{ Footer: CustomFooter }}
          getRowId={(row) => row?._id}
        />
      </Box>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={modalStyle}>
          <Header title="ADD NEW OTA" titleVariant="h4" />
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <CssTextField
                fullWidth
                required
                select
                size="small"
                label="OTA"
                value={selectedOta ?? ""}
                onChange={(e) => setSelectedOta(e.target.value)}
              >
                {otasExceptBooklogic?.map((ota, i) => (
                  <MenuItem value={ota} key={i}>
                    {ota}
                  </MenuItem>
                ))}
              </CssTextField>
              <CssTextField
                fullWidth
                required
                size="small"
                type="text"
                label="Hotel code"
                placeholder="Hotel code"
                value={hotelCode ?? ""}
                onChange={(e) => setHotelCode(e.target.value?.replace(" ", ""))}
              />
              {selectedOta === "goibibo" ? (
                <CssTextField
                  fullWidth
                  required={selectedOta === "goibibo"}
                  size="small"
                  type="text"
                  label="Bearer token"
                  placeholder="Bearer token"
                  value={bearerToken ?? ""}
                  onChange={(e) => setBearerToken(e.target.value)}
                />
              ) : null}
              <Button
                fullWidth
                size="large"
                type="submit"
                color="secondary"
                variant="contained"
                sx={{ fontSize: "15px" }}
              >
                {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
              </Button>
            </Stack>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default HotelOtas;
