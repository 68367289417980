import { post } from "../../services";
import { ACTIVATE_HOTEL_OTAS, GET_OTA_PROPERTY_DETAILS, LIST_ALL_HOTEL_OTAS, SYNC_HOTEL_OTAS } from "../../graphql";
import { RESET_ALL_HOTEL_OTAS, SAVE_ALL_HOTEL_OTAS, TOGGLE_LOADING } from "../types";
import { toast } from "react-toastify";


export const listAllHotelOtas = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            otas: { pageSize, pageNumber, list },
        } = getState().otas;

        let apiResponse = await post("", {
            query: LIST_ALL_HOTEL_OTAS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_hotel_otas: { data, hasMore },
            } = apiResponse.data.data;
            let updatedList = [...list, ...data];
            // console.log(apiResponse);
            dispatch({
                type: SAVE_ALL_HOTEL_OTAS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllHotelOtas = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_HOTEL_OTAS });
    };
};

export const activateHotelOtas = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ACTIVATE_HOTEL_OTAS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllHotelOtas());
            dispatch(listAllHotelOtas());
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const syncHotelOtas = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: SYNC_HOTEL_OTAS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllHotelOtas());
            dispatch(listAllHotelOtas());
            toast.success("Data will be synced shortly!")
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const getOtaPropertyDetails = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: GET_OTA_PROPERTY_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                get_ota_property_details: { data },
            } = apiResponse.data.data;
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return data;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};