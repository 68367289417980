import { post } from "../../services";
import {
    ACTIVATE_ACTIVITY,
    ADD_NEW_ACTIVITY,
    LIST_ACTIVITY_DETAILS,
    LIST_ALL_ACTIVITIES,
    UPDATE_ACTIVITY,
} from "../../graphql";
import {
    RESET_ACTIVITY_DETAILS,
    RESET_ALL_ACTIVITIES,
    SAVE_ACTIVITY_DETAILS,
    SAVE_ALL_ACTIVITIES,
    TOGGLE_LOADING
} from "../types";

export const listAllActivities = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            activities: { pageSize, pageNumber, list },
        } = getState().activities;

        let apiResponse = await post("", {
            query: LIST_ALL_ACTIVITIES,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_activities: { data, hasMore },
            } = apiResponse.data.data;
            let updatedList = [...list, ...data];
            // console.log(apiResponse);
            dispatch({
                type: SAVE_ALL_ACTIVITIES,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllActivities = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_ACTIVITIES });
    };
};

export const addNewActivity = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_ACTIVITY,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            await dispatch(resetAllActivities());
            await dispatch(listAllActivities());
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listActivityDetails = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: LIST_ACTIVITY_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_activity_details: { data },
            } = apiResponse.data.data;
            dispatch({
                type: SAVE_ACTIVITY_DETAILS,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetActivityDetails = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ACTIVITY_DETAILS });
    };
};

export const activateActivity = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ACTIVATE_ACTIVITY,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllActivities());
            dispatch(listAllActivities());
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateActivity = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_ACTIVITY,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllActivities());
            dispatch(listAllActivities());
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};