/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Modal, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { addNewFacility, listAllFacilities, resetAllFacilitiesData } from "../../store/actions";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { tableStyle } from "../../components";
import { tokens } from "../../theme";
import { Formik } from "formik";
import { Add } from "@mui/icons-material";
import Header from "../../components/Header";
import * as yup from "yup";

const Facilities = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { isLoading, facilitiesList, showViewMore } = useSelector(
    ({
      loading,
      admin: {
        facilities: { list, showViewMore },
      },
    }) => ({
      isLoading: loading,
      facilitiesList: list,
      showViewMore,
    })
  );

  useEffect(() => {
    document.title = 'Facilities';
    dispatch(resetAllFacilitiesData());
    fetchMoreData();
  },[dispatch])

  const fetchMoreData = () => {
    dispatch(listAllFacilities());
  }

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Stack width="100%" alignItems="center">
          {showViewMore ? <Button fullWidth onClick={fetchMoreData}>
            <b>view more</b>
          </Button> : <Typography variant="h6">All datas listed</Typography>}
        </Stack>
      </GridFooterContainer>
    );
  }

  const columns = [
    // { field: "_id", headerName: "No", width: 200 },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      cellClassName: "name-column--cell",
    },
  ];

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: smScreen ? 400 : "90%",
    bgcolor: colors.primary[400],
    outline: "none",
    boxShadow: 24,
    p: 2,
  };

  const handleFormSubmit = async(values) => {
    values.icon = "";
    await dispatch(addNewFacility(values));
    handleClose();
  };
  const initialValues = {
    name: "",
  };
  const checkoutSchema = yup.object().shape({
    name: yup.string().required("Required"),
  });

  return (
    <Box m="20px" mb={15}>
      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="20px 0"
      >
        <Header title="FACILITIES" subtitle="welcome to Facilities" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={handleOpen}
          >
            <Add sx={{ mr: "10px" }} />
            Add new facility
          </Button>
        </Box>
      </Box>
      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={tableStyle(colors)}
      >
        <DataGrid 
          checkboxSelection 
          rows={facilitiesList} 
          columns={columns} 
          components={{Footer: CustomFooter}}
          getRowId={(row) => row?._id}
          />
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={modalStyle}>
          <Header title="ADD NEW FACILITY" titleVariant="h4" />

          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>

                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    name="name"
                    error={!!touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                  />
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ fontSize: "15px" }}
                  >
                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                  </Button>
                </Stack>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </Box>
  );
};

export default Facilities;
