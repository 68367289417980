import React, { useEffect, useRef, useState } from "react";
import { 
    Box, Button, IconButton, InputAdornment, Typography, ButtonGroup, Grid, 
    MenuItem, Stack, Table, useTheme, TableRow, TextField, Checkbox,
    TableBody, TableCell, TableContainer, TableHead, CircularProgress, 
} from "@mui/material";
import { 
    ArrowBackIosNew, ArrowForward, ArrowForwardIos, CalendarMonth, Close, ExpandMore, KeyboardArrowRight, Refresh, Search 
} from "@mui/icons-material";
import { listAllRoomsReports, resetAllRoomsReports } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import Header from "../../components/Header";
// import GroupBookingSidebar from "./groupBookingSidebar";
import moment from "moment";
import "./style.css"
import { toast } from "react-toastify";
import MonthlyReports from "./monthlyReports";
import CalendarPopover from "../../components/calendarPopover";

const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
const years = (num = 10) => {
    const year = new Date(moment().add(5, "year")).getFullYear();
    return Array.from({ length: num }, (v, i) => year - num + i + 1);
}

const WeeklyReports = () => {
    const d = new Date();
    const [dateNum, setDateNum] = useState(localStorage.getItem("dateNum") || d.getMonth());
    const [year, setYear] = useState(localStorage.getItem("year") || d.getFullYear());
    const theme = useTheme();
    const startInputRef = useRef();
    const endInputRef = useRef();
    const navigate = useNavigate();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const [type, setType] = useState(localStorage.getItem("calType") || "week");
    const [startDate, setStartDate] = useState(localStorage.getItem("startDate") || moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(localStorage.getItem("endDate") || moment().add(7, "days").format("YYYY-MM-DD"));
    const [expanded, setExpanded] = useState(JSON.parse(localStorage.getItem("expanded")) || []);
    const [selection, setSelection] = useState(false);
    const [selectedRooms, setSelectedRooms] = useState([]);
    const [selectedRoomsData, setSelectedRoomsData] = useState([]);
    const [selectedRowNumbers, setSelectedRowNumbers] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);
    const [bookingDetails, setBookingDetails] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    // const [showSidebar, setShowSidebar] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event, room, item) => {
        setBookingDetails({
            ...item,
            ...room,
        }) 
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const { roomsReports } = useSelector(({ calendar: { roomsReports } }) => ({
        roomsReports,
    }));
    // console.log(roomsReports);
    useEffect(() => {
        document.title = 'Calendar';
        // if (!roomsReports?.length) {
            if (type === "week") {
                dataFetch();
            } else {
                handleMonthlyDataFetch(dateNum, year, true);
            }
        // }
    // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        if(!expanded?.length){
            let roomtypeNames = roomsReports?.map(a => a.roomtype._id);
            setExpanded(roomtypeNames);
            localStorage.setItem("expanded", JSON.stringify(roomtypeNames));
        }
    // eslint-disable-next-line
    }, [roomsReports]);

    const clearSelectedDatas = () => {
        setSelectedRooms([]);
        setSelectedRoomsData([]);
        setSelectedRowNumbers([]);
        setSelectedDates([]);
    }

    const dataFetch = async() => {
        // dispatch(resetAllRoomsReports());
        await dispatch(listAllRoomsReports({
            startDate: moment(startDate, "YYYY-MM-DD").format("DD-MMM-YYYY"),
            endDate: moment(endDate, "YYYY-MM-DD").format("DD-MMM-YYYY"),
            noLoading: true,
        }));
        clearSelectedDatas();
    }

    const handleMonthlyDataFetch = async(m = dateNum, y = year, noLoading = false) => {
        let start = `01-${months[m]}-${y}`;
        let end = moment(start, 'DD-MMM-YYYY').endOf('month').format('DD-MMM-YYYY');
        // dispatch(resetAllRoomsReports());
        await dispatch(listAllRoomsReports({ startDate: start, endDate: end, noLoading: noLoading}));
        clearSelectedDatas();
    }

    const handleExpandBtn = (value) => {
        var data = expanded;
        if (data.includes(value)) {
            data = data.filter(item => item !== value);
        } else {
            data = [].concat(data, value);
        }
        setExpanded(data)
    }

    const handleFilterBtn = (start = startDate, end = endDate) => {
        if (end > start) {
            clearSelectedDatas();
            setSelection(false)
            // dispatch(resetAllRoomsReports());
            dispatch(listAllRoomsReports({
                startDate: moment(start, "YYYY-MM-DD").format("DD-MMM-YYYY"),
                endDate: moment(end, "YYYY-MM-DD").format("DD-MMM-YYYY")
            }));
        } else {
            toast.warning("Start date cannot be greater than or equal to end date!")
        }
    }

    const handleRoomSelection = (room, roominfo) => {
        let data = selectedRooms;
        let info = selectedRoomsData;
        if (data?.includes(room)) {
            let index = data?.indexOf(room);
            data[index] = undefined;
            info[index] = undefined;
            data = data.filter(item => item !== undefined );
            info = info.filter(item => item !== undefined );
        } else {
            data = [].concat(data, room);
            info = [].concat(info, roominfo);
        }
        // console.log(roominfo);
        setSelectedRooms(data);
        setSelectedRoomsData(info);
    }

    const handleContinueBtn = () => {
        localStorage.setItem("grpBooking", JSON.stringify(selectedRoomsData));
        setSelection(selection ? false : true);
        navigate("/group-booking")
        // setShowSidebar(true);
    }

    const handleRowSelection = (item, room_id) => {
        let filtered = [];
        if(selectedRowNumbers.includes(room_id)){
            setSelectedRowNumbers(selectedRowNumbers.filter((num) => num !== room_id))
            filtered = selectedRooms.filter(j => j._id !== room_id);
        }else{
            setSelectedRowNumbers([...selectedRowNumbers, room_id]);
            filtered = item.rooms.filter(e => e._id === room_id && e.available && !moment(e.date, "DD-MMM-YYYY").isBefore(moment().startOf("day")));
            filtered = [...selectedRooms, ...filtered];
        }
        filtered = [...new Set(filtered)];
        setSelectedRooms(filtered);

        let info = filtered.map((room, idx) => ({ 
            room_id: room?._id, 
            room_no: room?.room_no, 
            roomtype: item?.roomtype?.name, 
            roomtype_id: item?.roomtype?._id, 
            date: room?.date,
            rate: item?.data[0]?.rate,
            index: idx,
        }))
        setSelectedRoomsData(info);
    }

    const handleColumnSelection = (date) => {
        let filtered = [];
        if(selectedDates.includes(date)){
            setSelectedDates(selectedDates.filter((val) => val !== date))
            filtered = selectedRooms.filter(j => j.date !== date);
        }else{
            setSelectedDates([...selectedDates, date]);
            filtered = roomsReports?.flatMap(el => el.rooms.filter(j => j.available && j.date === date && !moment(j.date, "DD-MMM-YYYY").isBefore(moment().startOf("day"))));
            filtered = [...selectedRooms, ...filtered];
        }
        filtered = [...new Set(filtered)];
        setSelectedRooms(filtered);

        let info = filtered.map((room, idx) => {
            let item = roomsReports.find(obj => obj.rooms.some(e => e._id === room._id));
            return {
                room_id: room?._id,
                room_no: room?.room_no,
                roomtype: item?.roomtype?.name,
                roomtype_id: item?.roomtype?._id,
                date: room?.date,
                rate: item?.data[0]?.rate,
                index: idx,
            }
        })
        setSelectedRoomsData(info);
    }

    const handleClearDateRange = () => {
        let start = moment().format("YYYY-MM-DD")
        let end = moment().add(7, "days").format("YYYY-MM-DD")
        if (start !== startDate || end !== endDate) {
            setStartDate(start)
            setEndDate(end)
            localStorage.setItem("startDate", start)
            localStorage.setItem("endDate", end)
            handleFilterBtn(start, end)
        }
    }

    const ColorCode = ({ size = 15, color, label }) => (
        <Grid item>
            <Stack direction="row" spacing={1} alignItems="center">
                <Box width={size} height={size} bgcolor={color} />
                <Typography>{label}</Typography>
            </Stack>
        </Grid>
    )

    return (
        <Box m="20px" mb={15}>
            {/* <Container disableGutters> */}
                <Stack direction={{sm: "row", xs: "column"}} justifyContent="space-between">
                    <Header title="CALENDAR" subtitle="Full Calendar Interactive Page" />
                    <Stack alignItems="end">
                        <Grid container mb={1} spacing={1} justifyContent={{sm: "flex-end", xs: "flex-start"}}>
                            <ColorCode color={"#4BB543"} label={"Available"}/>
                            {/* <ColorCode color={"#d32f2f"} label={"Unavailable"}/> */}
                            {/* <ColorCode color={"#ff9800"} label={"Booked"}/>
                            <ColorCode color={"#ffe600"} label={"PHS Booking"}/> */}
                            <ColorCode color={"#525252"} label={"Blocked"}/>
                        </Grid>
                        <Grid container spacing={1} justifyContent={{sm: "flex-end", xs: "flex-start"}}>
                            <ColorCode size={10} color={"#8cf2ff"} label={"Direct"}/>
                            <ColorCode size={10} color={"#aaabff"} label={"Agent"}/>
                            <ColorCode size={10} color={"#dbdbdb"} label={"OTA"}/>
                        </Grid>
                    </Stack>
                </Stack>
                <Stack width={"100%"} ml={-2.5} mb={-2.5} bgcolor={colors.primary[400]} p={2} direction="row" spacing={1} position={"fixed"} bottom={20} zIndex={1} boxShadow={"1px -4px 35px -4px rgba(0,0,0,0.75)"}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            size="small"
                            variant="contained"
                            endIcon={!selection ? <ArrowForward /> : ""}
                            onClick={() => {
                                clearSelectedDatas();
                                setSelection(selection ? false : true)
                            }}
                            disabled={localStorage?.getItem("isHotsoft") === "true"}
                        >
                            {selection ? <Close fontSize='small' /> : <b>Book / Block</b>}
                        </Button>
                    </Box>
                    {selection &&
                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                size="small"
                                variant="contained"
                                endIcon={<ArrowForward />}
                                disabled={!selectedRooms?.length}
                                onClick={handleContinueBtn}
                            >
                                <b>Continue</b>
                            </Button>
                        </Box>}
                </Stack>
                <Stack
                    spacing={2}
                    mt={2}
                    direction={{ md: "row", xs: "column" }}
                    alignItems={{ md: "flex-end", xs: "normal" }}
                    justifyContent={"space-between"}
                >
                    <Stack direction="row" alignItems="center" spacing={1} justifyContent="center">
                        <ButtonGroup
                            disableElevation
                            size="small"
                        >
                            <Button
                                variant={type === "week" ? "contained" : "outlined"}
                                onClick={() => {
                                    setType("week")
                                    localStorage.setItem("calType", "week")
                                    dispatch(resetAllRoomsReports());
                                    handleFilterBtn()
                                }}
                            >
                                <b>Week</b>
                            </Button>
                            <Button
                                variant={type === "month" ? "contained" : "outlined"}
                                onClick={() => {
                                    setType("month")
                                    localStorage.setItem("calType", "month")
                                    dispatch(resetAllRoomsReports());
                                    handleMonthlyDataFetch()
                                }}
                            >
                                <b>Month</b>
                            </Button>
                        </ButtonGroup>
                        {type === "week" ? (
                            <IconButton size="small" title="Reset to default dates" onClick={handleClearDateRange}>
                                <Refresh fontSize="small" />
                            </IconButton>
                        ) : null}
                    </Stack>
                    {type === "week" ? (
                        <Stack
                            direction={"row"}
                            justifyContent={{ md: "flex-end", xs: "flex-start" }}
                            spacing={1}
                        >
                            <IconButton onClick={() => {
                                let start = moment(startDate, "YYYY-MM-DD").subtract(7, "days").format("YYYY-MM-DD")
                                let end = startDate
                                setStartDate(start)
                                setEndDate(end)
                                localStorage.setItem("startDate", start)
                                localStorage.setItem("endDate", end)
                                handleFilterBtn(start, end)
                            }}>
                                <ArrowBackIosNew />
                            </IconButton>
                            <TextField
                                fullWidth
                                size="small"
                                type="date"
                                label="Start Date"
                                placeholder="Start Date"
                                sx={{ width: 170 }}
                                inputRef={startInputRef}
                                onClick={() => startInputRef.current.showPicker()} 
                                // onKeyDown={(event) => event.preventDefault()}
                                // onFocus={() => startInputRef.current.showPicker()} 
                                value={startDate || ""}
                                onChange={(newValue) => {
                                    setStartDate(newValue.target.value)
                                    localStorage.setItem("startDate", newValue.target.value)

                                    let endDate = moment(newValue.target.value, "YYYY-MM-DD").add(7, "days").format("YYYY-MM-DD");
                                    setEndDate(endDate)
                                    localStorage.setItem("endDate", endDate)
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CalendarMonth sx={{ cursor: "pointer" }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                fullWidth
                                size="small"
                                type="date"
                                label="End Date"
                                placeholder="End Date"
                                sx={{ width: 170 }}
                                inputRef={endInputRef}
                                onClick={() => endInputRef.current.showPicker()} 
                                inputProps={{ min: startDate }}
                                value={endDate || ""}
                                onChange={(newValue) => {
                                    setEndDate(newValue.target.value)
                                    localStorage.setItem("endDate", newValue.target.value)
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CalendarMonth sx={{ cursor: "pointer" }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={() => handleFilterBtn()}
                                disabled={selection && startDate < moment().format("YYYY-MM-DD")}
                            >
                                {localStorage.getItem("loading2") === "true" ? <CircularProgress size={24}/> : <Search />}
                            </Button>
                            <IconButton onClick={() => {
                                let start = endDate
                                let end = moment(endDate, "YYYY-MM-DD").add(7, "days").format("YYYY-MM-DD")
                                setStartDate(start)
                                setEndDate(end)
                                localStorage.setItem("startDate", start)
                                localStorage.setItem("endDate", end)
                                handleFilterBtn(start, end)
                            }}>
                                <ArrowForwardIos />
                            </IconButton>
                        </Stack>
                    ) : (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <IconButton onClick={() => {
                                let m = dateNum > 0 ? parseInt(dateNum) - 1 : 11;
                                let y = dateNum > 0 ? year : parseInt(year) - 1;
                                handleMonthlyDataFetch(m, y)
                                setDateNum(m)
                                setYear(y)
                                localStorage.setItem("dateNum", m)
                                localStorage.setItem("year", y)
                            }}>
                                <ArrowBackIosNew />
                            </IconButton>
                            <TextField 
                                select 
                                size="small" 
                                variant="standard"
                                value={dateNum === "01" ? "1" : dateNum} 
                                onChange={(e) => {
                                    // handleMonthlyDataFetch(e.target.value)
                                    setDateNum(e.target.value)
                                    localStorage.setItem("dateNum", e.target.value)
                                }}
                            >
                                {months?.map((m, i) => (
                                    <MenuItem value={i} key={m}>
                                        {m}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField 
                                select 
                                size="small" 
                                variant="standard"
                                value={year} 
                                onChange={(e) => {
                                    // handleMonthlyDataFetch(dateNum, e.target.value)
                                    setYear(e.target.value)
                                    localStorage.setItem("year", e.target.value)
                                }}
                            >
                                {years()?.map((y) => (
                                    <MenuItem value={y} key={y}>
                                        {y}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {/* <Typography fontSize={20} width={50}>
                                {year}
                            </Typography> */}
                            <IconButton
                                color="primary"
                                sx={{borderRadius: 1}}
                                onClick={() => handleMonthlyDataFetch(dateNum, year)}
                            >
                                {localStorage.getItem("loading2") === "true" ? <CircularProgress size={24}/> : <Search />}
                            </IconButton>
                            <IconButton onClick={() => {
                                let m = dateNum < 11 ? parseInt(dateNum) + 1 : 0;
                                let y = dateNum < 11 ? year : parseInt(year) + 1;
                                handleMonthlyDataFetch(m, y)
                                setDateNum(m)
                                setYear(y)
                                localStorage.setItem("dateNum", m)
                                localStorage.setItem("year", y)
                            }}>
                                <ArrowForwardIos />
                            </IconButton>
                        </Stack>
                    )}
                </Stack>

                {type === "week" ? (
                <>
                {roomsReports?.length ?
                    // <Grid container>
                    //     <Grid item sm={12} md={12} lg={12}>
                    //         <div className="table-wrapper">
                    <div className="reports" style={{ backgroundColor: colors.primary[400] }}>
                        <TableContainer>
                            <Box component={Table} alignItems="center">
                                {/* <Table> */}
                                <TableHead>
                                    <TableRow>
                                    <th style={{ backgroundColor: colors.blueAccent[700] }}>Date</th>
                                    {roomsReports[0]?.data?.map((item, index) => {
                                        let date = new Date(item.date);
                                        return (
                                            <th
                                                key={index}
                                                title={item?.date}
                                                style={{
                                                    color: moment(date).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY") ? "yellow" : "",
                                                    backgroundColor: date.getDay() === 6 || date.getDay() === 0 ? colors.redAccent[700] : colors.blueAccent[700],
                                                }}
                                            >
                                                <Typography width={100} noWrap>
                                                <b>{item?.date}</b>
                                                </Typography>
                                                <Box width={20}>
                                                    <Checkbox
                                                        size="small"
                                                        checked={selectedDates.includes(item?.date)}
                                                        sx={{ p: 0, display: selection ? "" : "none" }}
                                                        onClick={() => handleColumnSelection(item?.date)}
                                                        disabled={moment(item.date, "DD-MMM-YYYY").isBefore(moment().startOf("day"))}
                                                    />
                                                </Box>
                                            </th>
                                        )
                                    })}
                                    </TableRow>
                                </TableHead>
                                {roomsReports?.map((item, index) => {
                                    return (
                                        <TableBody key={index}>
                                            <TableRow>
                                                <TableCell 
                                                    className="typename"
                                                    style={{ backgroundColor: "#2e7c67", color: "#fff" }} 
                                                    onClick={() => handleExpandBtn(item?.roomtype?._id)}
                                                    title={item?.roomtype?.name}
                                                >
                                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                        <Typography fontWeight={600} width={140} noWrap textTransform="capitalize">
                                                            {item?.roomtype?.name}
                                                        </Typography>
                                                        <IconButton size="small">
                                                            {expanded.includes(item?.roomtype?._id) ? <ExpandMore /> : <KeyboardArrowRight />}
                                                        </IconButton>
                                                    </Stack>
                                                </TableCell>

                                                {item?.data?.map((e, i) => (
                                                    <TableCell key={i}>
                                                        <Stack direction={"row"} spacing={1} justifyContent="space-between">
                                                            <Stack direction={"row"} spacing={3}>
                                                            <div 
                                                                className="avl-num" 
                                                                style={{color:e?.available_rooms < 1 ? "#d32f2f" : ""}}
                                                            >
                                                                <b>{e?.available_rooms}</b>
                                                            </div>
                                                            {e?.booked_rooms ? <div className="bkd-num"><b>{e?.booked_rooms}</b></div> : null}
                                                            </Stack>
                                                            <div>₹{e?.rate}</div>
                                                        </Stack>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                            {expanded?.includes(item?.roomtype?._id) &&
                                                <>
                                                    {item?.room_nums?.map(({ _id, room_no }) => (
                                                        <TableRow key={_id} style={{ backgroundColor: colors.primary[500] }}>
                                                            <TableCell title={room_no} style={{ backgroundColor: colors.primary[800] }}>
                                                                <Stack direction="row" alignItems="center">
                                                                    <Typography fontWeight={600} width={150} noWrap textTransform="capitalize">
                                                                        {room_no}
                                                                    </Typography>
                                                                    <Box width={20}>
                                                                        <Checkbox
                                                                            size="small"
                                                                            checked={selectedRowNumbers.includes(_id)}
                                                                            sx={{ p: 0, display: selection ? "" : "none" }}
                                                                            onClick={() => handleRowSelection(item, _id)}
                                                                        />
                                                                    </Box>
                                                                </Stack>
                                                            </TableCell>
                                                            {item?.rooms?.map((room, roomIndex) => {
                                                                if (room?._id === _id) {
                                                                    let date = new Date(room?.date);
                                                                    if (room?.available) {
                                                                        return (
                                                                            <TableCell 
                                                                                key={roomIndex} 
                                                                                width={"200px"}
                                                                                className="avl"
                                                                                style={{
                                                                                    cursor: selection && !moment(date).isBefore(moment().startOf("day")) ? "pointer" : "not-allowed",
                                                                                    backgroundColor: moment(date).isBefore(moment().startOf("day")) ? "grey" : selectedRooms.includes(room) ? "green" : "",
                                                                                    // borderBottom: date.getDay() === 6 || date.getDay() === 0 ? `4px solid ${colors.redAccent[700]}` : "",
                                                                                }}
                                                                                onClick={selection && !moment(date).isBefore(moment().startOf("day")) ? () => {
                                                                                    handleRoomSelection(room, { 
                                                                                        room_id: room?._id, 
                                                                                        room_no: room?.room_no, 
                                                                                        roomtype: item?.roomtype?.name, 
                                                                                        roomtype_id: item?.roomtype?._id, 
                                                                                        date: room?.date,
                                                                                        rate: roomsReports[index]?.data[0]?.rate,
                                                                                        index: roomIndex,
                                                                                    })
                                                                                } : null}
                                                                            >
                                                                                <Box width={20}>
                                                                                    <Checkbox 
                                                                                        size="small" 
                                                                                        checked={selectedRooms.includes(room)}
                                                                                        sx={{ p: 0, display: selection && !moment(date).isBefore(moment().startOf("day")) ? "" : "none" }} 
                                                                                    />
                                                                                </Box>
                                                                            </TableCell>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <TableCell 
                                                                                key={roomIndex} 
                                                                                width={"200px"}
                                                                                onClick={(e) => handleClick(e, room, item)} 
                                                                                className={room?.status === "hold" ? "hold" : room?.type === "phs" ? "phs" : "bkd"}
                                                                                // style={{
                                                                                //     borderBottom: date.getDay() === 6 || date.getDay() === 0 ? `4px solid ${colors.redAccent[700]}` : "",
                                                                                // }}
                                                                            >
                                                                                <Box
                                                                                    className={
                                                                                        room?.booking_from === "direct_booking" ? "direct" : 
                                                                                        room?.booking_from === "agent" ? "agent" :
                                                                                        ["ota", "bookingdotcom", "yatra", "goibibo", "agoda", "expedia", "booklogic"].includes(room?.booking_from) ? "ota" : "other"
                                                                                    }
                                                                                    title="expand" width={100}
                                                                                    overflow="hidden"
                                                                                    borderRadius={10}
                                                                                    pl={1} pr={1}
                                                                                >
                                                                                    <Typography fontWeight={600} fontSize={14} p={0} noWrap>
                                                                                        <small>{room?.guest_name}</small>
                                                                                    </Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                        )
                                                                    }
                                                                } else {
                                                                    return null;
                                                                }
                                                            })}
                                                        </TableRow>
                                                    ))}
                                                </>}
                                        </TableBody>
                                    )
                                })}
                                {/* <caption>All room types listed</caption> */}
                                {/* </Table> */}
                            </Box>
                        </TableContainer>
                    </div>
                    :
                    <Stack
                        textAlign="center"
                        height="200px"
                        justifyContent="center"
                    >
                        Loading...
                    </Stack>
                }
                </>) : (
                    <MonthlyReports
                        selection={selection}
                        handleRoomSelection={handleRoomSelection}
                        selectedRooms={selectedRooms}
                        selectedRowNumbers={selectedRowNumbers}
                        selectedDates={selectedDates}
                        handleRowSelection={handleRowSelection}
                        handleColumnSelection={handleColumnSelection}
                    />
            )}
            {/* </Container> */}

            <CalendarPopover
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                bookingDetails={bookingDetails}
                colors={colors}
                navigate={navigate}
            />

            {/* <Drawer
                anchor={"right"}
                open={showSidebar}
                onClose={() => setShowSidebar(false)}
            >
                <GroupBookingSidebar 
                    selectedRooms={selectedRoomsData} 
                    dispatch={dispatch} 
                    setShowSidebar={setShowSidebar}
                    colors={colors}
                />
            </Drawer> */}
        </Box>
    );
};

export default WeeklyReports;
