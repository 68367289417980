import React from 'react'
import { Box, Button, ListItemIcon, ListItemText, MenuItem, Popover, Stack } from '@mui/material'
import { CalendarMonth, Email, FolderShared, MeetingRoomOutlined, Person, Source } from "@mui/icons-material";
import moment from 'moment';

function CalendarPopover({ open, anchorEl, handleClose, bookingDetails, colors, navigate }) {
    return (
        <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
    >
        <Box minWidth={300}>
            <Box
                p={1}
                textAlign="center"
                fontWeight={600}
                bgcolor={colors.blueAccent[700]}
            >
                {bookingDetails?.booking_id}
            </Box>
            <Stack p={1}>
                <MenuItem>
                    <ListItemIcon>
                        <MeetingRoomOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        {`${bookingDetails?.roomtype?.name} - ${bookingDetails?.room_no}` || "-"}
                    </ListItemText>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <CalendarMonth fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{bookingDetails?.checkin_date ? moment.unix(bookingDetails?.checkin_date).format("DD-MMM-YYYY") : "-"} {"-"} {bookingDetails?.checkout_date ? moment.unix(bookingDetails?.checkout_date).format("DD-MMM-YYYY") : "-"}</ListItemText>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <Person fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{bookingDetails?.guest_name || "-"}</ListItemText>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <Email fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{bookingDetails?.guest_email || "-"}</ListItemText>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <Source fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{bookingDetails?.booking_from?.replace("_", " ") || "-"}</ListItemText>
                </MenuItem>
                {bookingDetails?.agent_name ? (
                    <MenuItem>
                        <ListItemIcon>
                            <FolderShared fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{bookingDetails?.agent_name || "-"}</ListItemText>
                    </MenuItem>
                ) : null}
                <Button
                    size="small"
                    variant="contained"
                    disabled={!bookingDetails?.booking_id}
                    onClick={() => navigate(`/booking-details/${bookingDetails?.booking_id}`)}
                >
                    <b>View more details</b>
                </Button>
            </Stack>
        </Box>
    </Popover>
    )
}

export default CalendarPopover;