import React, { useState } from "react";
import { Box, Button, CircularProgress, InputAdornment, Stack, useTheme } from "@mui/material";
import { CalendarMonth, Close, DateRange } from "@mui/icons-material";
import { tokens } from "../../../../theme";
import { CssTextField } from "../../../../components/TextField";
import { useDispatch, useSelector } from "react-redux";
import { updateBookedRoomsDates } from "../../../../store/actions";
import Header from "../../../../components/Header";
import moment from "moment";

function UpdateRoomsDatesModal({ room, bookingId, setUpdateModal }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const [checkin, setCheckin] = useState(moment.unix(room?.checkin_date).format("YYYY-MM-DD"));
    const [checkout, setCheckout] = useState(moment.unix(room?.checkout_date).format("YYYY-MM-DD"));

    const { isLoading } = useSelector(({ loading }) => ({ isLoading: loading }));

    const modalBoxStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "90vh",
        width: { sm: 400, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    const handleSubmitBtn = async () => {
        let data = {
            id: room?.id,
            booking_id: bookingId,
            checkinDate: moment(checkin).unix(),
            checkoutDate: moment(checkout).unix(),
        };

        let status = await dispatch(updateBookedRoomsDates(data));
        if(status){
            if(status){
                setUpdateModal(false);
            }
        }
    }

    return (
        <>
            <Box sx={modalBoxStyle}>
                <Stack direction="row" justifyContent="space-between">
                    <Header title="UPDATE BOOKING DATES" titleVariant="h4" />
                    <Close onClick={() => setUpdateModal(false)} sx={{ cursor: "pointer" }} />
                </Stack>
                <Stack spacing={2}>
                <CssTextField
                    fullWidth
                    required
                    type="date"
                    placeholder="Checkin Date"
                    label="Checkin Date"
                    onChange={(e) => setCheckin(e.target.value)}
                    value={checkin}
                    inputProps={{ min: moment().format("YYYY-MM-DD") }}
                    name="checkinDate"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <DateRange />
                            </InputAdornment>
                        ),
                    }}
                />
                <CssTextField
                    fullWidth
                    required
                    type="date"
                    placeholder="Checkout Date"
                    label="Checkout Date"
                    onChange={(e) => setCheckout(e.target.value)}
                    value={checkout}
                    inputProps={{ min: checkin }}
                    name="checkinDate"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <CalendarMonth />
                            </InputAdornment>
                        ),
                    }}
                />
                <Button 
                    variant='contained' 
                    onClick={handleSubmitBtn}
                    disabled={
                        moment.unix(room?.checkin_date).format("YYYY-MM-DD") === checkin &&
                        moment.unix(room?.checkout_date).format("YYYY-MM-DD") === checkout
                    } 
                >
                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                </Button>
            </Stack>
            </Box>
        </>
    );
}

export default UpdateRoomsDatesModal;
