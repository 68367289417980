export const createRoomNumbersArray = (data) => {
    let array = data.map((obj) => {
        //to filter out duplicates
        let unique = obj.rooms.filter((obj, index, self) =>
            index === self.findIndex(o => o._id === obj._id)
        );
        
        unique = unique.map(({ _id, room_no }) => ({ _id, room_no }))
        return { ...obj, room_nums: unique };
    })
    return array
}