import React, { useEffect, useState } from 'react';
import { checkRoomAvailability, listAllRoomTypes, resetAvalableRoomsData } from '../../../store/actions';
import { Button, CircularProgress, InputAdornment, MenuItem, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RoomPreferences } from '@mui/icons-material';
import { CssTextField } from '../../../components/TextField';

function FirstPage({ bookingDetails, setSwapData, setPage }) {
    const dispatch = useDispatch();
    const [selectedRoomType, setSelectedRoomType] = useState("");

    const { isLoading, roomTypes } = useSelector(
        ({
            loading,
            admin: { roomTypes: { list } }
        }) => ({
            isLoading: loading,
            roomTypes: list,
        })
    );

    useEffect(() => {
        dispatch(listAllRoomTypes({ isForDropdown: true }));
    }, [dispatch]);

    const handleSwapBtn = async () => {
        let roomsData = [{
            room_number: 1,
            no_of_adults: bookingDetails?.rooms[0]?.no_of_adults,
            no_of_childs: bookingDetails?.rooms[0]?.no_of_childs,
        }]
        if (selectedRoomType && selectedRoomType !== "no_preference") {
            roomsData[0].roomtype_id = selectedRoomType
        }
        let data = {
            booking_id: bookingDetails?.booking_id,
            checkinDate: bookingDetails?.checkin_date,
            checkoutDate: bookingDetails?.checkout_date,
            rooms: roomsData,
        };
        setSwapData(data);
        await dispatch(resetAvalableRoomsData());
        await dispatch(checkRoomAvailability(data));
        setPage(2);
    }


    return (
        <>
            <Stack spacing={2}>
                <CssTextField
                    fullWidth
                    select
                    value={selectedRoomType || "no_preference"}
                    label="Room Type"
                    onChange={(e) => setSelectedRoomType(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <RoomPreferences />
                            </InputAdornment>
                        ),
                    }}
                >
                    <MenuItem value={"no_preference"}>
                        {"No Preference"}
                    </MenuItem>
                    {roomTypes?.map((roomType, index) => (
                        <MenuItem value={roomType._id} key={index}>
                            {roomType.name}
                        </MenuItem>
                    ))}
                </CssTextField>
                <Button variant='contained' onClick={handleSwapBtn}>
                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Continue</b>}
                </Button>
            </Stack>
        </>
    )
}

export default FirstPage