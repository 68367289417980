import { post } from "../../services";
import { 
    LIST_ALL_HOTSOFT_AVAILAVILITY_UPDATES, 
    LIST_HOTSOFT_UPDATE_DETAILS 
} from "../../graphql";
import { 
    RESET_ALL_HOTSOFT_UPDATES, 
    RESET_HOTSOFT_UPDATE_DETAILS, 
    SAVE_ALL_HOTSOFT_UPDATES, 
    SAVE_HOTSOFT_UPDATE_DETAILS, 
    TOGGLE_LOADING 
} from "../types";


export const listAllHotsoftUpdates = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            hotsoftUpdates: { pageSize, pageNumber },
        } = getState().hotsoft;

        let apiResponse = await post("", {
            query: LIST_ALL_HOTSOFT_AVAILAVILITY_UPDATES,
            variables: {
                ...requestData,
                pageSize: requestData?.pageSize || pageSize,
                pageNumber: requestData?.pageNumber || pageNumber,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_hotsoft_availability_updates: { data, hasMore, count },
            } = apiResponse.data.data;

            dispatch({
                type: SAVE_ALL_HOTSOFT_UPDATES,
                payload: {
                    list: data,
                    showViewMore: hasMore,
                    pageSize: requestData?.pageSize || pageSize,
                    pageNumber: requestData?.pageNumber || pageNumber,
                    count: count,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllHotsoftUpdates = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_HOTSOFT_UPDATES });
    };
};

export const listHotsoftUpdateDetails = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: LIST_HOTSOFT_UPDATE_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_hotsoft_update_details: { data },
            } = apiResponse.data.data;
            dispatch({
                type: SAVE_HOTSOFT_UPDATE_DETAILS,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetHotsoftUpdateDetails = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_HOTSOFT_UPDATE_DETAILS });
    };
};