import React, { useRef, useState } from 'react'
// import { KeyboardArrowDown } from '@mui/icons-material'
import { Box, Button, CircularProgress, Divider, Grid, InputAdornment, Link, Modal, Radio, Stack, Typography } from '@mui/material'
import { ArrowBack, ArrowForward, CalendarMonth, Close, Home, KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp, MeetingRoom, Person, Search } from '@mui/icons-material';
// import Header from '../../components/Header';
import SelectedRoomsModal from './selectedRoomsModal';
import noImage from "../../assets/img/no_image0.jpeg";
import SimpleImageSlider from "react-simple-image-slider";
import moment from 'moment';
import "./styles.css";
import { Formik } from 'formik';
import { CssTextField } from '../../components/TextField';
import { checkAvailability, resetAvailabilityData } from '../../store/actions';
import { useSearchParams } from 'react-router-dom';

const getRoomOnlyPlan = (roomtype) => [
    {
        _id: "",
        name: "EP",
        description: "Room Only",
        rate: 0,
        total_price: roomtype.room_only_rate || 0,
        actual_price: roomtype.actual_price || 0,
        tax_amount: roomtype.tax_amount || 0,
        roomtype_id: "",
        is_active: true,
    },
];

function AvailableRoomtypes({ roomtypes = [], colors, setPage, roomtypeId, setRoomtypeId, isLoading, hotelDetails, dispatch, code, isActivitiesAvailable }) {
    const checkinRef = useRef();
    const checkoutRef = useRef();
    const [selected, SetSelected] = useState({});
    const [open, setOpen] = useState(false);
    const [priceModal, setPriceModal] = useState(false);
    const [policyModal, setPolicyModal] = useState(false);
    const [imgModal, setImgModal] = useState(false);
    const [img, setImg] = useState("");
    const [mealPlan, setMealPlan] = useState("");
    const [expanded, setExpanded] = useState([]);
    // console.log(roomtypes);
    const [searchParams, setSearchParams] = useSearchParams();
    var adults = searchParams.get("adults");
    var children = searchParams.get("children");
    var checkin = searchParams.get("checkin");
    var checkout = searchParams.get("checkout");
    var checkInDate = searchParams.get("CheckInDate") || checkin ? checkin : null;
    var checkOutDate = searchParams.get("CheckOutDate") || checkout ? checkout : null;

    let datas = localStorage.getItem("checkingData");
    datas = JSON.parse(datas);

    let start = moment.unix(datas?.checkinDate);
    let end = moment.unix(datas?.checkoutDate );
    let diff = Math.ceil(moment.duration(start.diff(end)).asDays());
    diff = Math.abs(diff);

    const handleOpenModal = (item) => {
        localStorage.setItem("selected", JSON.stringify(item));
        SetSelected(item);
        setRoomtypeId(item?._id);
        // setOpen(true);
        setPage(isActivitiesAvailable ? 3 : 3.1);
    }
    const handleClose = () => setOpen(false);

    const handlexpandBtn = (id) => {
        var data = expanded;
        if (data.includes(id)) {
            data = data.filter(item => item !== id);
        } else {
            data = [].concat(data, id);
        }
        setExpanded(data)
    }

    const initialValues = {
        checkinDate: checkInDate,
        checkoutDate: checkOutDate,
        numOfAdults: adults,
        numOfChilds: children,
        totalRooms: 1,
    };

    const handleFormSubmit = async (values) => {
        let data = {
            ...values,
            hotelCode: code,
            checkinDate: moment(values.checkinDate, "YYYY-MM-DD").unix(),
            checkoutDate: moment(values.checkoutDate, "YYYY-MM-DD").unix(),
            // isPrivate: pref === "private" ? true : false,
        }
        // console.log(data);
        dispatch(resetAvailabilityData());
        dispatch(checkAvailability(data));
        localStorage.setItem("checkingData", JSON.stringify(data));
        setPage(2);
    }

    const modalStyle = (wid) => {
        return {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { sm: wid, xs: "90%" },
            bgcolor: colors.primary[400],
            outline: "none",
            boxShadow: 24,
            p: 2,
        }
    };

    return (
        <>
        <Stack justifyContent="start" alignItems="center">
        <Stack mb={5} width="100%">
            <Grid container spacing={2}>
                {!checkInDate && !checkOutDate ? (
                <Grid item xs={12}>
                    {/* <Button variant='contained' onClick={() => setPage(1)}>
                        <ArrowBack />
                    </Button> */}
                </Grid>
                ) : null}
                {checkInDate && checkOutDate ? (
                <Grid item md={12} xs={12} mb={2}>
                    <Stack
                        p={1}
                        // pl={2}
                        // pr={2}
                        spacing={{ md: 2, xs: 1 }}
                        width="100%"
                        direction="row"
                        bgcolor={colors.greenAccent[700]}
                        // color="#000"
                        justifyContent="space-between"
                    >
                        <Stack direction="row" spacing={1}>
                            <Home />
                            <Typography textTransform={"uppercase"} fontWeight="bold">
                                {hotelDetails?.name || "-"}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack
                        p={2}
                        // bgcolor={colors.primary[400]}
                        bgcolor={"rgb(0, 0, 0, 0.7)"}
                        style={{ backdropFilter: `blur(3px)` }}
                    >
                        <Formik onSubmit={handleFormSubmit} initialValues={initialValues}>
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2} alignItems="end">
                                        <Grid item md={6} xs={12}>
                                            <Typography><b>Check-in date</b></Typography>
                                            <CssTextField
                                                fullWidth
                                                required
                                                size='small'
                                                type="date"
                                                placeholder="Check-in date"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    if (moment(e.target.value).add(1, "days").format("YYYY-MM-DD") > values.checkoutDate) {
                                                        setFieldValue(
                                                            'checkoutDate',
                                                            moment(e.target.value).add(1, "days").format("YYYY-MM-DD")
                                                        );
                                                    }
                                                }}
                                                value={values.checkinDate}
                                                name="checkinDate"
                                                error={!!touched.checkinDate && !!errors.checkinDate}
                                                helperText={touched.checkinDate && errors.checkinDate}
                                                inputProps={{ min: moment().format("YYYY-MM-DD") }}
                                                inputRef={checkinRef}
                                                onClick={() => checkinRef.current.showPicker()}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <CalendarMonth sx={{ cursor: "pointer" }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            // disabled={page === 2}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography><b>Check-out date</b></Typography>
                                            <CssTextField
                                                fullWidth
                                                required
                                                size="small"
                                                type="date"
                                                placeholder="Checkout Date"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.checkoutDate}
                                                name="checkoutDate"
                                                error={!!touched.checkoutDate && !!errors.checkoutDate}
                                                helperText={touched.checkoutDate && errors.checkoutDate}
                                                inputProps={{ min: moment(values.checkinDate).add(1, "days").format("YYYY-MM-DD") }}
                                                inputRef={checkoutRef}
                                                onClick={() => checkoutRef.current.showPicker()}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <CalendarMonth sx={{ cursor: "pointer" }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            // disabled={page === 2}
                                            />
                                        </Grid>
                                        {/* {isPrivate ? (
                                            <Grid item md={12} xs={12}>
                                                <Typography><b>Book</b></Typography>
                                                <RadioGroup
                                                    row
                                                    value={pref}
                                                    onChange={(e) => setPref(e.target.value)}
                                                >
                                                    <FormControlLabel value="private" control={<Radio />} label="Entire Property" />
                                                    <FormControlLabel value="Room_only" control={<Radio />} label="Individual Room" />
                                                </RadioGroup>
                                            </Grid>
                                        ) : null} */}
                                        <Grid item md={12} xs={12}>
                                            <Button
                                                fullWidth
                                                // size="small"
                                                type="submit"
                                                color="secondary"
                                                variant="contained"
                                                // disabled={page === 2}
                                                sx={{ fontSize: "15px" }}
                                            >
                                                {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Check availability</b>}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Stack>
                    <Divider sx={{ mt: 4, borderColor: "#333"}}/>
                </Grid>
                ) : null}


                {roomtypes.map((roomtype, index) => {
                const mPlans = roomtype?.meal_plan_rates?.length ? roomtype?.meal_plan_rates : getRoomOnlyPlan(roomtype);
                return(
                    <Grid item xs={12} key={index}>
                        <Stack p={2} bgcolor={"rgb(0, 0, 0, 0.7)"} style={{ backdropFilter: `blur(5px)` }} spacing={2} borderRadius={3}>
                            <Typography textTransform="uppercase" fontWeight={700} fontSize={20} color={colors.greenAccent[500]}>
                                {roomtype.name}
                            </Typography>
                            <Grid container>
                                <Grid item xs={12} md={3.5}>
                                    <Stack height={200} className="img-container" borderRadius={2} overflow="hidden">
                                        {roomtype.images[0] ?
                                            <SimpleImageSlider
                                                width={"100%"}
                                                height={"100%"}
                                                images={roomtype.images}
                                                showBullets={true}
                                                showNavs={true}
                                                navMargin={1}
                                                className={roomtype.images[0] ? 'image' : ""}
                                                autoPlay
                                                autoPlayDelay={5}
                                                onClick={(idx) => {
                                                    setImgModal(true)
                                                    setImg(roomtype.images[idx || 0])
                                                }}
                                            />
                                            : <img src={`${noImage}?w=164&h=164&fit=crop&auto=format`} width={"100%"} height={"100%"} alt='' />}
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={8.5} pl={{xs: 0, md: 2}} pt={{xs: 2, md: 0}}>
                                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                                        <Stack direction="row" spacing={2}>
                                            <Stack justifyItems={"center"} spacing={1}>
                                                <Typography>Max occupancy / room</Typography>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    spacing={1}
                                                    p={0.5}
                                                    textAlign="center"
                                                    border={1}
                                                    borderRadius={1}
                                                    borderColor={colors.greenAccent[500]}
                                                    // bgcolor={colors.primary[700]}
                                                    boxShadow={15}
                                                    fontSize={20}

                                                >
                                                    <Person />
                                                    <b>{roomtype.max_no_of_people}</b>
                                                </Stack>
                                            </Stack>
                                            <Stack justifyItems={"center"} spacing={1}>
                                                <Typography>Available rooms</Typography>
                                                {/* <hr /> */}
                                                <Stack
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    spacing={1}
                                                    p={0.5}
                                                    textAlign="center"
                                                    border={1}
                                                    borderRadius={1}
                                                    borderColor={colors.greenAccent[500]}
                                                    // bgcolor={colors.primary[700]}
                                                    boxShadow={15}
                                                    fontSize={20}
                                                >
                                                    <MeetingRoom />
                                                    <b>{roomtype.available_rooms}</b>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        <Stack textAlign="end">
                                            <Typography><i>for {roomtype.max_no_of_people === 1 ? "1 person" : "2 people"} {roomtype.display_room_no ? `x ${roomtype.display_room_no} rooms` : null} :</i></Typography>
                                            <Typography color={"#aaa"}>{roomtype?.total_price / diff} x {diff} {"day(s)"}</Typography>
                                            {roomtype?.actual_price && roomtype?.actual_price > roomtype?.total_price ? <Typography fontSize={16}><s>₹{roomtype?.actual_price}</s></Typography> : null}
                                            <Typography fontSize={20} color={colors.greenAccent[500]}><b>₹{(roomtype?.room_only_rate).toFixed(2)}</b></Typography>
                                            <Typography color={"#aaa"} fontSize={"small"}>Plus taxes</Typography>
                                        </Stack>
                                    </Stack>
                                    <Box mt={4}>
                                        <Typography mb={1}>Rates</Typography>
                                        <Grid container spacing={1}>
                                            {mPlans?.map((plan) => (
                                                <Grid item key={plan._id} xs={12}>
                                                    <Box
                                                        p={1}
                                                        borderRadius={1}
                                                        border={"1px solid #666"}
                                                        // border={plan._id === mealPlan ? `1px solid ${colors.greenAccent[400]}` : "1px solid #666"}
                                                        // bgcolor={plan._id === mealPlan ? colors.greenAccent[700] : ""}
                                                        sx={{ cursor: "pointer" }}
                                                    >
                                                        <Stack direction={{xs: "column", md: "row"}} alignItems="start" justifyContent="space-between">
                                                            <Stack alignItems="start" justifyContent="space-between" spacing={1}>
                                                                <Typography>
                                                                    <b>{plan?.name}</b> {`${plan?.description ? `- ${plan?.description}` : ""}`}
                                                                </Typography>
                                                                <Button
                                                                    size="small"
                                                                    sx={{ letterSpacing: 1, borderRadius: 20, p: 0, pr: 1 }}
                                                                    onClick={() => {
                                                                        SetSelected({ ...roomtype, meal_plan: plan });
                                                                        setMealPlan(plan)
                                                                        setPriceModal(true);
                                                                        localStorage.setItem("selected", JSON.stringify({ ...roomtype, meal_plan: plan }));
                                                                    }}
                                                                    endIcon={<KeyboardArrowRight />}
                                                                >
                                                                    <b>{"view details"}</b>
                                                                </Button>
                                                            </Stack>
                                                            <Stack direction="row" spacing={1} alignItems="end">
                                                                <Stack>
                                                                    {plan?.actual_price && plan?.actual_price > plan?.total_price ? <Typography fontSize={16}><s>₹{plan?.actual_price}</s></Typography> : null}
                                                                    <Stack direction="row" spacing={1} alignItems="end">
                                                                        <Typography fontSize={20} color={colors.greenAccent[500]}><b>₹{(plan?.total_price).toFixed(2)}</b></Typography>
                                                                        <Typography color={"#aaa"} fontSize={"small"} noWrap>Plus taxes</Typography>
                                                                    </Stack>
                                                                </Stack>

                                                                <Button
                                                                    variant='contained'
                                                                    size='small'
                                                                    onClick={() => handleOpenModal({ ...roomtype, meal_plan: plan })}
                                                                    disabled={plan?.total_price < 1 || roomtype.available_rooms < 1}
                                                                    sx={{whiteSpace: "nowrap"}}
                                                                    // endIcon={<ArrowForward />}
                                                                ><b>Book Now</b></Button>
                                                            </Stack>
                                                            
                                                        </Stack>
                                                    </Box>
                                                </Grid>
                                            ))}
                                            {/* <Grid item><Typography fontSize="small">/ person</Typography></Grid> */}
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Grid>
                )})}


            </Grid>
            {roomtypes?.length < 1 && (
                <Stack mt={2} alignItems="center" justifyContent="center" bgcolor={colors.primary[400]} spacing={1} width={"100%"} height={300}>
                    <Search fontSize='large' />
                    <Typography textAlign="center">
                        No rooms are available in the given date range. Try a different date range.
                    </Typography>
                </Stack>
            )}

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={modalStyle(400)}>
                    <SelectedRoomsModal roomtypeId={roomtypeId} setPage={setPage} selected={selected} colors={colors}/>
                </Box>
            </Modal>

                    <Modal
                        open={priceModal}
                        onClose={() => setPriceModal(false)}
                    >
                        <Box sx={modalStyle(800)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        borderBottom={"1px solid #666"}
                                    >
                                        <Stack>
                                        <Typography
                                            fontWeight={700}
                                            textTransform={"uppercase"}
                                        >
                                            {selected?.name}
                                        </Typography>
                                        <Typography
                                            mb={1}
                                            fontWeight={700}
                                            color={colors.greenAccent[500]}
                                        >
                                            {mealPlan.description}
                                        </Typography>
                                        </Stack>
                                        <Close fontSize='small' sx={{ cursor: "pointer" }} onClick={() => setPriceModal(false)}/>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography fontWeight={600} mb={1}>Cancellation Policy</Typography>
                                    {hotelDetails?.cancel_policy ? (
                                        <Typography p={1} border={"1px solid #666"} maxHeight={"70vh"} overflow="auto">
                                            {hotelDetails?.cancel_policy ?? "-"}
                                        </Typography>
                                    ) : (
                                        <Box fontSize={15} p={1} border={"1px solid #666"} maxHeight={"70vh"} overflow="auto">
                                            <u>Cancellation Policy:</u>
                                            <ul>
                                                <li>No refund is applicable for NO SHOW</li>
                                                <li>Booking Cancellation on 30 Days window will refund 100% amount</li>
                                                <li>Booking Cancellation on 15 Days window will be charge 50% amount</li>
                                                <li>Booking Cancellation on 7 days window will be charge 100% amount</li>
                                            </ul>
                                            <u>Terms and conditions:</u>
                                            <div>
                                                As per the current government protocol, every tourist/guest needs to present at least 1 covid vaccination
                                                certificate or latest (72 hours) RTPCR negative certificate at the time check-in to the resort.Every guest needs to
                                                present any of the valid ID proofs at the time of check-in
                                            </div>
                                            <ul>
                                                <li>Out side guests are not welcome due to Covid protocol. Prior intimation needs to be given to the resort in
                                                    case of visit of outside guests and they are requested to leave the resort by 09.30 PM by all means.</li>
                                                <li>Pets are not allowed in the resort</li>
                                                <li>Any parties/functions or loud voice not permitted after 09.00 PM</li>
                                                <li>Outside food is not permitted in the resort</li>
                                                <li>Check-in Time is 02:00 PM and check-out time is 11:00 AM</li>
                                            </ul>
                                        </Box>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography fontWeight={600} mb={1}>Price Details</Typography>
                                    <Stack
                                        p={1}
                                        spacing={1}
                                        border={"1px solid #666"}
                                    >
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography color={"#aaa"} fontSize={"small"}>Sub Total</Typography>
                                            <Typography fontWeight={600}>₹{(parseFloat(selected?.meal_plan?.total_price)).toFixed(2) || "-"}</Typography>
                                        </Stack>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography color={"#aaa"} fontSize={"small"}>Taxes and Fees</Typography>
                                            <Typography fontWeight={600}>₹{selected?.meal_plan?.tax_amount || "-"}</Typography>
                                        </Stack>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography>Grand Total</Typography>
                                            <Typography fontWeight={600}>₹{(parseFloat(selected?.meal_plan?.total_price) + parseFloat(selected?.meal_plan?.tax_amount)).toFixed(2) || "-"}</Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Modal>

            <Modal
                open={policyModal}
                onClose={() => setPolicyModal(false)}
            >
                <Box sx={modalStyle(600)}>
                    <Box fontSize={15} maxHeight={"80vh"} overflow="auto">
                        <u>Cancellation Policy:</u>
                        <ul>
                            <li>No refund is applicable for NO SHOW</li>
                            <li>Booking Cancellation on 30 Days window will refund 100% amount</li>
                            <li>Booking Cancellation on 15 Days window will be charge 50% amount</li>
                            <li>Booking Cancellation on 7 days window will be charge 100% amount</li>
                        </ul>
                        <u>Terms and conditions:</u>
                        <div>
                            As per the current government protocol, every tourist/guest needs to present at least 1 covid vaccination
                            certificate or latest (72 hours) RTPCR negative certificate at the time check-in to the resort.Every guest needs to
                            present any of the valid ID proofs at the time of check-in
                        </div>
                        <ul>
                            <li>Out side guests are not welcome due to Covid protocol. Prior intimation needs to be given to the resort in
                                case of visit of outside guests and they are requested to leave the resort by 09.30 PM by all means.</li>
                            <li>Pets are not allowed in the resort</li>
                            <li>Any parties/functions or loud voice not permitted after 09.00 PM</li>
                            <li>Outside food is not permitted in the resort</li>
                            <li>Check-in Time is 02:00 PM and check-out time is 11:00 AM</li>
                        </ul>
                    </Box>
                    <Stack alignItems="end">
                        <Button onClick={() => setPolicyModal(false)}><b>Close</b></Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal
                open={imgModal}
                onClose={() => setImgModal(false)}
            >
                <Box sx={modalStyle("50%")}>
                    <Stack width={"100%"} height={"100%"}>
                        <img src={img} alt=''/>
                    </Stack>
                </Box>
            </Modal>
        </Stack>
        </Stack>
        </>
    )
}

export default AvailableRoomtypes