import React, { useState } from "react";
import {
    Box, Button, CircularProgress, Grid,
    IconButton, Stack, Typography,useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { swapBookedRooms } from "../../../../store/actions";
import { ArrowBack } from "@mui/icons-material";
import { tokens } from "../../../../theme";
import Header from "../../../../components/Header";

const SecondPage = ({ room, bookingId, setPage, setSwapModal }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selectedRooms, setSelectedRooms] = useState([]);

    const { isLoading, availableRoomsData } = useSelector(
        ({
            loading,
            admin: {
                availableRooms: { list },
            },
        }) => ({
            isLoading: loading,
            availableRoomsData: list,
        })
    );
    const handleBackButton = () => setPage(1);

    const handleRoomSelection = (room) => {
        if (selectedRooms?.filter((e) => e.room_id === room?._id)?.length) {
            setSelectedRooms([]);
        } else {
            let data = [
                {
                    room_number: room?.room_no,
                    room_id: room?._id,
                    ...room,
                },
            ];
            setSelectedRooms(data);
        }
    };
    const handleSubmitBtn = async () => {
        let data = {
            booking_id: bookingId,
            id: room?.id,
            room_id: selectedRooms[0]?.room_id,
        };
        console.log(data);
        await dispatch(swapBookedRooms(data));
        setSwapModal(false)
    };

    return (
        <Box>
            <Stack direction="row" alignItems="flex-end" spacing={1} m="10px 0">
                <Box title="go back" height={"40px"}>
                    <IconButton type="button" onClick={handleBackButton}>
                        <ArrowBack />
                    </IconButton>
                </Box>
                <Header title="Available Rooms" titleVariant="h4" />
            </Stack>
            <Box
                ml={-2}
                mr={-2}
                p={2}
                maxHeight={"60vh"}
                overflow="auto"
                border={"1px solid #777"}
                bgcolor={"rgb(0, 0, 0, 0.2)"}
                borderRadius={2}
            >
                <Grid container spacing={1}>
                    {availableRoomsData[0]?.data?.map((room, i) => (
                        <Grid item md={12} xs={12} key={i}>
                            <Stack
                                p={1}
                                borderRadius={2}
                                border={1}
                                borderColor={colors.greenAccent[600]}
                                backgroundColor={
                                    selectedRooms?.filter((e) => e.room_id === room?._id)?.length
                                        ? colors.greenAccent[800]
                                        : colors.primary[500]
                                }
                                direction="row"
                                spacing={1}
                                sx={{ cursor: "pointer" }}
                                onClick={() => handleRoomSelection(room)}
                            >
                                <Stack
                                    bgcolor={colors.greenAccent[600]}
                                    p={1}
                                    alignItems="center"
                                    borderRadius={1}
                                >
                                    <small>Room No</small>
                                    <Typography fontSize={20} fontWeight={600}>
                                        {room?.room_no}
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <Stack>Rate: {room?.rate}</Stack>
                                    <Stack>Number of beds: {room?.no_of_beds}</Stack>
                                    <Stack>Room type: {room?.roomtype_id?.name}</Stack>
                                </Stack>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
                {!availableRoomsData[0]?.data?.length ? (
                    <Stack p={2} alignItems={"center"}>
                        No Rooms Available for selected inputs
                    </Stack>
                ) : null}
            </Box>
            <Grid container spacing={1} mt={1}>
                <Grid item lg={6} xs={6}>
                    <Button
                        fullWidth
                        // size="small"
                        type="submit"
                        color="secondary"
                        variant="outlined"
                        sx={{ fontSize: "15px" }}
                        onClick={() => setSwapModal(false)}
                    >
                        <b>Cancel</b>
                    </Button>
                </Grid>
                <Grid item lg={6} xs={6}>
                    <Button
                        fullWidth
                        // size="small"
                        type="submit"
                        color="secondary"
                        variant="contained"
                        sx={{ fontSize: "15px" }}
                        disabled={!selectedRooms?.length}
                        onClick={handleSubmitBtn}
                    >
                        {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Swap</b>}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SecondPage;
