import React, { useEffect, useState } from "react";
import {
    Box, IconButton, Typography, Stack, Table, useTheme,
    TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox,
} from "@mui/material";
import { ExpandMore, KeyboardArrowRight } from "@mui/icons-material";
// import { listAllRoomsReports, resetAllRoomsReports } from "../../store/actions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import moment from "moment";
import "./style.css";
import CalendarPopover from "../../components/calendarPopover";
import { allMonths } from "../../data";

const MonthlyReports = ({ selection, handleRoomSelection, selectedRooms, handleRowSelection, selectedRowNumbers, handleColumnSelection, selectedDates }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const colors = tokens(theme.palette.mode);
    // const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(JSON.parse(localStorage.getItem("expanded")) || []);
    const [bookingDetails, setBookingDetails] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event, room, item) => {
        setBookingDetails({
            ...item,
            ...room,
        })
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const { roomsReports } = useSelector(({ calendar: { roomsReports } }) => ({
        roomsReports,
    }));
    // console.log(roomsReports);
    // useEffect(() => {
    //     document.title = 'Calendar';
    //     handleMonthlyDataFetch(dateNum, year, true);
    //     // eslint-disable-next-line
    // }, [dispatch]);

    useEffect(() => {
        if (!expanded?.length) {
            let roomtypeNames = roomsReports?.map(a => a.roomtype._id);
            setExpanded(roomtypeNames);
            localStorage.setItem("expanded", JSON.stringify(roomtypeNames));
        }
        // eslint-disable-next-line
    }, [roomsReports]);

    // const handleMonthlyDataFetch = (m = dateNum, y = year, noLoading = false) => {
    //     let start = `01-${months[m]}-${y}`;
    //     let end = moment(start, 'DD-MMM-YYYY').endOf('month').format('DD-MMM-YYYY');
    //     // dispatch(resetAllRoomsReports());
    //     dispatch(listAllRoomsReports({ startDate: start, endDate: end, noLoading: noLoading }));
    // }

    const handleExpandBtn = (value) => {
        var data = expanded;
        if (data.includes(value)) {
            data = data.filter(item => item !== value);
        } else {
            data = [].concat(data, value);
        }
        setExpanded(data)
    }

    return (
        <Box>
            {roomsReports?.length ?
                <div className="reports" style={{ backgroundColor: colors.primary[400] }}>
                    <TableContainer>
                        <Box component={Table} alignItems="center">
                            {/* <Table> */}
                            <TableHead>
                                <TableRow>
                                    <th style={{ backgroundColor: colors.blueAccent[700] }}>Date</th>
                                    {roomsReports[0]?.data?.map((item, index) => {
                                        let date = new Date(item.date);
                                        return (
                                            <th key={index} style={{
                                                color: moment(date).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY") ? "yellow" : "",
                                                backgroundColor: date.getDay() === 6 || date.getDay() === 0 ? colors.redAccent[700] : colors.blueAccent[700],
                                            }}>
                                                <Stack textAlign={"center"}>
                                                    <Typography fontSize={10}>{allMonths[date.getMonth()]}</Typography>
                                                    <Typography><b>{date.getDate()}</b></Typography>
                                                    <Typography fontSize={10}>{date.getFullYear()}</Typography>
                                                </Stack>
                                                <Box width={20}>
                                                    <Checkbox
                                                        size="small"
                                                        checked={selectedDates.includes(item?.date)}
                                                        sx={{ p: 0, display: selection ? "" : "none" }}
                                                        onClick={() => handleColumnSelection(item?.date)}
                                                        disabled={moment(item.date, "DD-MMM-YYYY").isBefore(moment().startOf("day"))}
                                                    />
                                                </Box>
                                            </th>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            {roomsReports?.map((item, index) => {
                                return (
                                    <TableBody key={index}>
                                        <TableRow>
                                            <TableCell
                                                className="typename"
                                                style={{ backgroundColor: "#2e7c67", color: "#fff" }} 
                                                onClick={() => handleExpandBtn(item?.roomtype?._id)}
                                                title={item?.roomtype?.name}
                                            >
                                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                    <Typography fontWeight={600} width={140} noWrap textTransform="capitalize">
                                                        {item?.roomtype?.name}
                                                    </Typography>
                                                    <IconButton size="small">
                                                        {expanded.includes(item?.roomtype?._id) ? <ExpandMore /> : <KeyboardArrowRight />}
                                                    </IconButton>
                                                </Stack>
                                            </TableCell>

                                            {item?.data?.map((e, i) => (
                                                <TableCell key={i}>
                                                    <Stack direction={"column"} spacing={1} textAlign="center">
                                                        <div
                                                            className="avl-num"
                                                            style={{ color: e?.available_rooms < 1 ? "#d32f2f" : "" }}
                                                        >
                                                            <b>{e?.available_rooms}</b>
                                                        </div>
                                                        {e?.booked_rooms ? <div className="bkd-num"><b>{e?.booked_rooms}</b></div> : null}
                                                    </Stack>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        {expanded?.includes(item?.roomtype?._id) &&
                                            <>
                                                {item?.room_nums?.map(({ _id, room_no }) => (
                                                    <TableRow key={_id} style={{ backgroundColor: colors.primary[500] }}>
                                                        <TableCell title={room_no} style={{ backgroundColor: colors.primary[800] }}>
                                                            <Stack direction="row" alignItems="center">
                                                                <Typography fontWeight={600} width={150} noWrap textTransform="capitalize">
                                                                    {room_no}
                                                                </Typography>
                                                                <Box width={20}>
                                                                    <Checkbox
                                                                        size="small"
                                                                        checked={selectedRowNumbers.includes(_id)}
                                                                        sx={{ p: 0, display: selection ? "" : "none" }}
                                                                        onClick={() => handleRowSelection(item, _id)}
                                                                    />
                                                                </Box>
                                                            </Stack>
                                                        </TableCell>
                                                        {item?.rooms?.map((room, roomIndex) => {
                                                            if (room?._id === _id) {
                                                                let date = new Date(room?.date);
                                                                if (room?.available) {
                                                                    return (
                                                                        <TableCell
                                                                            key={roomIndex}
                                                                            width={"200px"}
                                                                            className="avl"
                                                                            style={{
                                                                                cursor: selection && !moment(date).isBefore(moment().startOf("day")) ? "pointer" : "not-allowed",
                                                                                backgroundColor: moment(date).isBefore(moment().startOf("day")) ? "grey" : selectedRooms.includes(room) ? "green" : "",
                                                                                // borderBottom: date.getDay() === 6 || date.getDay() === 0 ? `4px solid ${colors.redAccent[700]}` : "",
                                                                            }}
                                                                            onClick={selection && !moment(date).isBefore(moment().startOf("day")) ? () => {
                                                                                handleRoomSelection(room, {
                                                                                    room_id: room?._id,
                                                                                    room_no: room?.room_no,
                                                                                    roomtype: item?.roomtype?.name,
                                                                                    roomtype_id: item?.roomtype?._id,
                                                                                    date: room?.date,
                                                                                    // rate: roomsReports[index]?.data[0]?.rate / (roomsReports[index]?.data?.length - 1),
                                                                                    rate: roomsReports[index]?.data[0]?.rate,
                                                                                    index: roomIndex,
                                                                                })
                                                                            } : null}
                                                                        >
                                                                            <Box width={20}>
                                                                                <Checkbox
                                                                                    size="small"
                                                                                    checked={selectedRooms.includes(room)}
                                                                                    sx={{ p: 0, display: selection && !moment(date).isBefore(moment().startOf("day")) ? "" : "none" }}
                                                                                />
                                                                            </Box>
                                                                        </TableCell>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <TableCell
                                                                            key={roomIndex}
                                                                            width={"200px"}
                                                                            onClick={(e) => handleClick(e, room, item)}
                                                                            className={room?.status === "hold" ? "hold" : room?.type === "phs" ? "phs" : "bkd"}
                                                                            // style={{
                                                                            //     borderBottom: date.getDay() === 6 || date.getDay() === 0 ? `4px solid ${colors.redAccent[700]}` : "",
                                                                            // }}
                                                                        >
                                                                            <Box
                                                                                className={
                                                                                    room?.booking_from === "direct_booking" ? "direct" :
                                                                                        room?.booking_from === "agent" ? "agent" :
                                                                                            ["ota", "bookingdotcom", "yatra", "goibibo", "agoda", "expedia", "booklogic"].includes(room?.booking_from) ? "ota" : "other"
                                                                                }
                                                                                title="expand" 
                                                                                overflow="hidden"
                                                                                width={20}
                                                                                borderRadius={1}
                                                                            >
                                                                                <Typography fontWeight={600} fontSize={14} p={0} noWrap>
                                                                                    <small>{room?.guest_name}</small>
                                                                                </Typography>
                                                                            </Box>
                                                                        </TableCell>
                                                                    )
                                                                }
                                                            } else {
                                                                return null;
                                                            }
                                                        })}
                                                    </TableRow>
                                                ))}
                                            </>}
                                    </TableBody>
                                )
                            })}
                            {/* <caption>All room types listed</caption> */}
                            {/* </Table> */}
                        </Box>
                    </TableContainer>
                </div>
                :
                <Stack
                    textAlign="center"
                    height="200px"
                    justifyContent="center"
                >
                    Loading...
                </Stack>
            }
            {/* </Container> */}

            <CalendarPopover
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                bookingDetails={bookingDetails}
                colors={colors}
                navigate={navigate}
            />
        </Box>
    );
};

export default MonthlyReports;
