import React, { useState } from 'react';
import { Build, Edit, KeyboardArrowDown, SwapHoriz } from '@mui/icons-material';
import { Button, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Modal, Stack, Typography } from '@mui/material';
import UpdateBookedRoomStatus from './update-booking-status/updateBookedRoomStatus';
import UpdateRoomsDatesModal from './update-booking-dates-modal/update-rooms-dates';
import SwapBookedRoomModal from './swap-room-modal/swap-booked-rooms';
import { StatusChip } from '../../components/Chips';
import moment from 'moment';

export default function RoomDetails({ bookingId, rooms, isCustomPrice, colors, modalStyle }) {
    const [updateModal, setUpdateModal] = useState(false);
    const [swapModal, setSwapModal] = useState(false);
    const [updateStatusModal, setUpdateStatusModal] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleMenuClick = (event, element) => {
        setSelectedRoom({
            ...element,
            id: element?._id,
        })
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {rooms?.map((element, index) => (
                <Grid item md={6} xs={12} key={index}>
                    <Stack
                        p={2}
                        borderRadius={2}
                        border={1}
                        borderColor={colors.greenAccent[600]}
                        bgcolor={colors.primary[500]}
                        // direction="row"
                        alignItems="start"
                        spacing={2}
                        // sx={{ cursor: "pointer" }}
                    >
                        <Stack width="100%" direction="row" alignItems="start" justifyContent="space-between">
                            <Stack
                                bgcolor={colors.greenAccent[700]}
                                p={1.5}
                                alignItems="center"
                                borderRadius={1}
                                width={100}
                            >
                                <Typography noWrap>
                                    <small>Room No</small>
                                </Typography>
                                <Typography fontSize={20} fontWeight={600}>
                                    {element?.room_id?.room_no}
                                </Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="end" width="100%">
                                <Button
                                    size="small"
                                    variant="outlined"
                                    endIcon={<KeyboardArrowDown />}
                                    onClick={(event) => handleMenuClick(event, element)}
                                // disabled={bookingDetails?.type !== "normal"}
                                >
                                    <b>Options</b>
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={handleMenuClose}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem
                                        // disabled={["checkin", "cancelled"].includes(bookingDetails?.status)}
                                        onClick={() => {
                                            handleMenuClose()
                                            setSwapModal(true)
                                        }}
                                    >
                                        <ListItemIcon>
                                            <SwapHoriz fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Swap Room</ListItemText>
                                    </MenuItem>
                                    <MenuItem
                                        // disabled={["checkin", "cancelled"].includes(bookingDetails?.status)}
                                        onClick={() => {
                                            handleMenuClose()
                                            setUpdateModal(true)
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Edit fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Update Booking Dates</ListItemText>
                                    </MenuItem>
                                    <MenuItem
                                        disabled={moment(moment.unix(element?.checkin_date)).isBefore(moment().startOf("day"))}
                                        onClick={() => {
                                            handleMenuClose()
                                            setUpdateStatusModal(true)
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Build fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Update Room Status</ListItemText>
                                    </MenuItem>
                                </Menu>
                            </Stack>
                        </Stack>
                        <Stack width="100%">
                            {/* <FieldAndValues field={"Check-in date"} value={moment.unix(element?.checkin_date).format("DD-MMM-YYYY")} />
                            <FieldAndValues field={"Check-out date"} value={moment.unix(element?.checkout_date).format("DD-MMM-YYYY")} /> */}
                            <FieldAndValues field={"Roomtype"} component={<Typography color={colors.greenAccent[500]}><b>{element?.room_id?.roomtype_id?.name}</b></Typography>} />
                            <FieldAndValues field={"Dates"} value={element?.dates} />
                            <FieldAndValues field={"Status"} component={<StatusChip status={element?.status}/>} />
                            <FieldAndValues field={"Number of adults"} value={element?.no_of_adults} />
                            <FieldAndValues field={"Number of children"} value={element?.no_of_childs} />
                            <FieldAndValues field={"Meal plan"} value={element?.meal_plan?.name} />
                            <FieldAndValues field={"Meal price of adult"} value={element?.adult_meals_price} />
                            <FieldAndValues field={"Meal price of child"} value={element?.child_meals_price} />
                            <FieldAndValues field={"Total meals amount"} value={element?.meal_plan?.rate} />
                            <FieldAndValues field={"Total price"} value={isCustomPrice ? "Package" : element?.total_price} />
                        </Stack>
                    </Stack>
                    {/* <Stack mt={2}>
                                <Button variant="contained" onClick={handleOpen}>
                                    <b>Swap room</b>
                                </Button>
                            </Stack> */}
                </Grid>
            ))}

            <Modal open={updateStatusModal} onClose={() => setUpdateStatusModal(false)} sx={modalStyle}>
                <>
                    <UpdateBookedRoomStatus
                        room={selectedRoom}
                        bookingId={bookingId}
                        setUpdateStatusModal={setUpdateStatusModal}
                    />
                </>
            </Modal>
            <Modal open={updateModal} onClose={() => setUpdateModal(false)} sx={modalStyle}>
                <>
                    <UpdateRoomsDatesModal
                        room={selectedRoom}
                        bookingId={bookingId}
                        setUpdateModal={setUpdateModal}
                    />
                </>
            </Modal>
            <Modal open={swapModal} onClose={() => setSwapModal(false)} sx={modalStyle}>
                <>
                    <SwapBookedRoomModal
                        room={selectedRoom}
                        bookingId={bookingId}
                        setSwapModal={setSwapModal}
                    />
                </>
            </Modal>
        </>
    )
};


const FieldAndValues = ({ field = "", value = "", component }) => {
    return (
        <Stack 
            p={0.5} 
            direction="row" 
            justifyContent="space-between" 
            borderBottom={"1px solid #888"}
        >
            <Typography>{field}:</Typography>
            {component ? component : <Typography>{value}</Typography>}
        </Stack>
    )
}