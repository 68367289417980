import React from "react";
import { Autocomplete } from "@mui/material";
import { CssTextField } from "./TextField";

function SearchAndSelectInput({ list = [], value = "", keyname = "name", required = false, label = "", size = "small", disabled = false, onChange }) {

  const optionsList = list.map((el) => ({
    label: el[keyname] || el?.email || "",
    id: el._id,
  }));

  const filtered = optionsList.filter((e) => e.id === value)
  
  return (
    <>
      <Autocomplete
        // disablePortal
        fullWidth
        freeSolo
        forcePopupIcon
        size={size}
        disabled={disabled}
        value={filtered[0] ?? ""}
        options={optionsList || []}
        onChange={(e, value) => onChange(value?.id ?? "")}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <CssTextField required={required} {...params} label={label} />}
      />
    </>
  );
}

export default SearchAndSelectInput;
