import { Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import noImage from "../../assets/img/no_image0.jpeg";
import PlusOrMinusButton from "../../components/PlusOrMinusButton";

function Activities({ activities, setPage, colors, selectedActivities, setselectedActivities }) {

  const handleActivitySelection = (value, index) => {
    let arr = activities;
    arr[index].count = value;
    let filtered = arr.filter((e) => e.count && e.count !== 0);
    setselectedActivities(filtered);
  };

  const getCount = (id) => selectedActivities?.find((e) => e._id === id)?.count || 0;
  const handleNextButton = () => setPage(3.1);

  return (
    <Stack justifyContent="start" alignItems="center">
      <Grid container justifyContent="center" mt={2} mb={5}>
        <Grid item md={12} xs={12}>
          {/* <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            p={2}
            borderRadius={2}
            bgcolor={"rgb(0, 0, 0, 0.7)"}
          >
            <Button variant="contained" onClick={() => setPage(2)}>
              <ArrowBack />
            </Button>
            <Typography flexGrow={1} fontSize={20} fontWeight={700}>
              Enhance Your Stay
            </Typography>
          </Stack> */}
          <Stack
            p={2}
            bgcolor={"rgb(0, 0, 0, 0.7)"}
            style={{ backdropFilter: `blur(5px)` }}
            borderRadius={2}
            // mt={2}
          >
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Typography flexGrow={1} fontSize={20} fontWeight={700}>
                  Enhance Your Stay
                </Typography>
              </Grid>
              {activities.map((activity, index) => (
                <Grid item xs={12} sm={6} md={4} key={activity._id}>
                  <Stack bgcolor={colors.primary[400]} p={2} borderRadius={1}>
                    <img
                      src={activity.img || `${noImage}?w=164&h=164&fit=crop&auto=format`}
                      alt=""
                      width="100%"
                      height="170px"
                    />
                    <Typography mt={1} fontWeight={600} fontSize={18}>
                      {activity.name}
                    </Typography>
                    <Typography noWrap color={"#ccc"}>
                      {activity.description || "-"}
                    </Typography>
                    {/* <Link fontSize={15}>View Details</Link> */}
                    <Stack mt={1} direction="row" alignItems="end" justifyContent="space-between" spacing={2} height={45}>
                      <PlusOrMinusButton
                        min={0}
                        max={20}
                        name="numOfChilds"
                        value={0}
                        handleChange={(value) => handleActivitySelection(value, index)}
                      />
                      <Stack textAlign="end" minWidth={"40%"}>
                        <Typography noWrap display={getCount(activity._id) ? "" : "none"}>
                          ₹{parseFloat(activity?.rate || 0).toFixed(2)} x {getCount(activity._id)}
                        </Typography>
                        <Typography fontWeight={600} fontSize={18}>
                          ₹{getCount(activity._id) ? parseFloat((activity?.rate || 0) * getCount(activity._id)).toFixed(2) : parseFloat(activity?.rate || 0).toFixed(2)}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              ))}
              {!activities.length ? (
                <Grid item xs={12}>
                  <Stack p={2} pt={10} pb={10} textAlign={"center"}>
                    {" "}
                    No data found!{" "}
                  </Stack>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Stack mt={2} spacing={2} direction="row" justifyContent="end">
                  {!selectedActivities?.length ? (
                    <Button variant="outlined" onClick={handleNextButton}>
                      <b>Skip</b>
                    </Button>
                  ) : null}
                  <Button
                    // size="small"
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ fontSize: "15px" }}
                    disabled={!selectedActivities.length}
                    onClick={handleNextButton}
                  >
                    <b>Add Selected Services</b>
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default Activities;
