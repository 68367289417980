export const REGISTRATION_STATUS = `query{
    registration_status{
      status
    }
  }`;

export const HOTEL_PROFILE = `query{
    hotel_profile{
      message
      data {
        _id
        name
        email
        address
        status
        mobile
        type
        is_active
        title
        first_name
        last_name
        address1
        address2
        country
        state
        city
        phone
        fax
        slug
        logo
        hotel_img
        cancel_policy
        terms_conditions
        postal_code
        min_child_age
        max_child_age
        property {
          name
          type
          address
          city
          location
          country
          state
          postal_code
          finance_email
          finance_mobile
          reservation_email
          reservation_mobile
          c_form_email
          land_phone
          fax
          green_hotel
        }
        contact_person {
          name
          email
          mobile
          designation
          address
          phone
        }
        gstin
        bank_details {
          acc_holder_name
          acc_no
          ifsc
          acc_type
          bank_name
          branch_name
        }
        bke_discount
        bke_partial_pmt
      }
    }
  }`;

export const UPDATE_HOTEL_PROFILE = `mutation (
    $name: String,
    $email: String,
    $address: String,
    $status: String,
    $mobile: String,
    $type: String,
    $is_active: Boolean,
    $title: String,
    $first_name: String,
    $last_name: String,
    $password: String,
    $address1: String,
    $address2: String,
    $country: String,
    $state: String,
    $city: String,
    $phone: String,
    $fax: String,
    $postal_code: String,
    $logo: String,
    $hotel_img: String,
    $min_child_age: Float,
    $max_child_age: Float,
    $property: update_hotel_profile_property,
    $contact_person: update_hotel_profile_contact_person,
    $gstin: String,
    $bank_details: update_hotel_profile_bank_details,
    $bke_discount: Float,
    $bke_partial_pmt: Boolean,
    $cancel_policy: String,
) {
    update_hotel_profile(
      name: $name,
      email: $email,
      address: $address,
      status: $status,
      mobile: $mobile,
      type: $type,
      is_active: $is_active,
      title: $title,
      first_name: $first_name,
      last_name: $last_name,
      password: $password,
      address1: $address1,
      address2: $address2,
      country: $country,
      state: $state,
      city: $city,
      phone: $phone,
      fax: $fax,
      postal_code: $postal_code,
      logo: $logo,
      hotel_img: $hotel_img,
      min_child_age: $min_child_age,
      max_child_age: $max_child_age,
      property: $property,
      contact_person: $contact_person,
      gstin: $gstin,
      bank_details: $bank_details,
      bke_discount: $bke_discount,
      bke_partial_pmt: $bke_partial_pmt,
      cancel_policy: $cancel_policy,
    ){
      message
    }
  }`;

export const CREATE_ROOMTYPES = `mutation ($isForReg: Boolean!, $roomtypes: [create_roomtypes_roomtypes]!) {
    create_roomtypes(is_for_reg: $isForReg, roomtypes: $roomtypes){
      message
    }
  }`;