/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { activateMealPlan, addNewMealPlan, listAllMealPlans, resetAllMealPlansData } from '../../store/actions';
import { Box, Button, CircularProgress, Modal, Stack, Switch, Typography } from '@mui/material';
import { CssTextField } from '../../components/TextField';
import { useSelector } from 'react-redux';
import { tableStyle } from '../../components';
import { DataGrid } from '@mui/x-data-grid';
import { Add, Edit } from '@mui/icons-material';
import Header from '../../components/Header';
import AvailabilityUpdation from '../../components/AvailabilityUpdation';
import UpdateMealPlanModalContent from './updateMealPlanModalContent';

function MealPlansOfRoomType({ roomtypeId, dispatch, colors }) {
    const [values, setValues] = useState({});
    const [temp, setTemp] = useState("");
    const [updateModal, setUpdateModal] = useState(false);
    const [updateMealPlanData, setUpdateMealPlanData] = useState({});
    const [isForChild, setIsForChild] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { sm: 400, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
      };

    const { isLoading, mealPlans, showViewMore } = useSelector(
        ({
            loading,
            booking: { mealPlans: { list, showViewMore } }
        }) => ({
            isLoading: loading,
            mealPlans: list,
            showViewMore
        })
    );

    useEffect(() => {
        dispatch(resetAllMealPlansData());
        dispatch(listAllMealPlans({ 
            roomtypeId: roomtypeId
        }));
        // eslint-disable-next-line
    },[])

    const columns = [
        {
            field: "name",
            headerName: "Name",
            cellClassName: "name-column--cell",
            width: 200,
        },
        { field: "rate", headerName: "Rate", width: 150 },
        { field: "description", headerName: "Description", width: 300 },
        {
            field: "is_active",
            headerName: "Availability",
            width: 100,
            renderCell: ({ row: { is_active, _id } }) => {
                return (
                    <AvailabilityUpdation
                        isActive={is_active}
                        action={() => dispatch(activateMealPlan({ id: _id, roomtypeId: roomtypeId }))}
                    />
                );
            },
        },
        {
            field: "_id",
            headerName: "Update",
            width: 150,
            renderCell: ({ row }) => {
                return (
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                            setUpdateMealPlanData(row)
                            setUpdateModal(true)
                        }}
                        endIcon={<Edit fontSize="small" />}
                        sx={{ fontWeight: 700 }}
                    >
                        Update
                    </Button>
                )
            },
        },
        // {
        //     field: "_id",
        //     headerName: "Delete",
        //     width: 100,
        //     renderCell: ({ row: {_id } }) => {
        //         return (
        //             <IconButton 
        //                 onClick={() => dispatch(activateMealPlan({ id: _id, roomtypeId: roomtypeId }))}
        //                 title='Delete'
        //             >
        //                 <Delete />
        //             </IconButton>
        //         );
        //     },
        // },
    ];

    const handleChange = (event) => {
        let fieldname = event?.target?.name;
        let data = values;
        data[fieldname] = event?.target?.value;
        setValues(data);
        setTemp(event.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        values.roomtypeId = roomtypeId;
        values.isForChild = isForChild;
        console.log(values);
        dispatch(addNewMealPlan(values))
        handleClose();
    }
  
    return (
        <>
            <Box mb={15}>
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"end"}
                    m="20px 0"
                >
                    <Typography variant='h4' fontWeight="bold">
                        MEAL PLANS
                    </Typography>
                    <Box>
                        <Button
                            sx={{
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: { sm: "10px 20px", xs: "5px 10px" },
                            }}
                            onClick={handleOpen}
                        >
                            <Add sx={{ mr: "10px" }} />
                            Add new meal plan
                        </Button>
                    </Box>
                </Box>
                <Box
                    m="8px 0 0 0"
                    height="350px"
                    sx={tableStyle(colors)}
                >
                    <DataGrid
                        rows={mealPlans}
                        columns={columns}
                        // components={{ Footer: CustomFooter }}
                        getRowId={(row) => row?._id}
                    />
                </Box>

                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <Box sx={modalStyle}>
                        <Header title="ADD NEW MEAL PLAN" titleVariant="h4" />
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={2}>
                                <CssTextField
                                    fullWidth
                                    required
                                    size="small"
                                    type="text"
                                    label="Name"
                                    name="name"
                                    value={values?.name || ""}
                                    onChange={handleChange}
                                />
                                <CssTextField
                                    fullWidth
                                    size="small"
                                    type="text"
                                    label="Description"
                                    name="description"
                                    value={values?.description || ""}
                                    onChange={handleChange}
                                />
                                 <CssTextField
                                    fullWidth
                                    required
                                    size="small"
                                    type="number"
                                    label="Rate"
                                    name="rate"
                                    value={values?.rate || ""}
                                    onChange={handleChange}
                                />
                                <Stack direction="row" alignItems="center">
                                    <Typography noWrap>Applicable for child</Typography>
                                    <Switch
                                        checked={isForChild || false}
                                        onChange={(e) => setIsForChild(e.target.checked)}
                                    />
                                </Stack>
                                <Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                    sx={{ fontSize: "15px" }}
                                >
                                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                                </Button>
                            </Stack>
                        </form>
                    </Box>
                </Modal>

                <Modal
                    open={updateModal}
                    onClose={() => setUpdateModal(false)}
                >
                    <>
                        <UpdateMealPlanModalContent
                            roomtypeId={roomtypeId}
                            dispatch={dispatch}
                            isLoading={isLoading}
                            setModal={setUpdateModal}
                            colors={colors}
                            updationData={updateMealPlanData}
                        />
                    </>
                </Modal>
            </Box>
        </>
    )
}

export default MealPlansOfRoomType;