import React, { useEffect, useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Grid, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { authorizedPost, checkOfferRates, toggleLoading } from "../../../store/actions";
import { LIST_ROOMTYPE_OFFER_DETAILS } from "../../../graphql";
import AddNewRate from "./addNewRate";
import moment from "moment";

function RateDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState({});
    const [isUpdating, setIsUpdating] = useState(false);
    const [rates, setRates] = useState([]);
    const [ratesModal, setRatesModal] = useState(false);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { sm: "fit-content", xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    // console.log(hotsoftUpdateDetails);
    useEffect(() => {
        document.title = 'Offer Details';
        fetchData();
        // eslint-disable-next-line
    }, [dispatch, id, isUpdating]);

    const fetchData = async () => {
        dispatch(toggleLoading(true));
        let res = await authorizedPost(LIST_ROOMTYPE_OFFER_DETAILS, { id: id });
        if (res.status) {
            // console.log(res);
            setData(res.data.list_roomtype_offer_details.data);
        }
        dispatch(toggleLoading(false));
    }

    const handleCheckRatesButton = async(val) => {
        if(val.plus_percentage == 0 && val.minus_percentage == 0){
            return
        }
        let req = {
            roomtypeId: data?.roomtype_id?._id,
            startDate: data.start_date,
            endDate: data.end_date,
            plusPercentage: val.plus_percentage || 0,
            minusPercentage: val.minus_percentage || 0,
        };
        let rates = await dispatch(checkOfferRates(req));
        if(rates){
            // console.log(rates);
            setRates(rates);
            setRatesModal(true);
        }
    }

    const KeyAndValue = ({ keyName, value, render, sx, colored, fullWidth }) => (
        <Grid item md={fullWidth ? 12 : 6} xs={12}>
            <Stack direction="row" spacing={2} sx={sx}>
                <Typography width={"50%"}>{keyName}:</Typography>
                {value ?
                    <Typography color={colored ? "primary" : ""}>
                        {value}
                    </Typography>
                    : render}
            </Stack>
        </Grid>
    )

    return (
        <Box m="20px" mb={15}>
            {!isUpdating ? (
                <>
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"flex-end"}
                        spacing={2}
                        m="20px 0"
                    >
                        <Button size="small" variant="outlined" onClick={() => navigate(-1)}>
                            <ArrowBack fontSize="small" />
                        </Button>
                        <Typography fontWeight="bold" variant="h4">
                            OFFER DETAILS
                        </Typography>
                        <Button
                            size='small'
                            variant='outlined'
                            onClick={() => setIsUpdating(isUpdating ? false : true)}
                        >
                            <b>Update</b>
                        </Button>
                    </Stack>
                    <Box
                        width="100%"
                        p={2}
                        borderRadius={1}
                        backgroundColor={colors.primary[400]}
                    >
                        <Grid container rowSpacing={2} columnSpacing={4}>
                            <KeyAndValue keyName={"Type"} value={data?.type?.replace("_", " ") || "-"} sx={{ textTransform: "capitalize" }} colored />
                            <KeyAndValue keyName={"Room type"} value={data?.roomtype_id?.name || "-"} />
                            <KeyAndValue keyName={"Start date"} value={data?.start_date ? moment.unix(data?.start_date).format("DD-MM-YYYY") : "-"} />
                            <KeyAndValue keyName={"End date"} value={data?.end_date ? moment.unix(data?.end_date).format("DD-MM-YYYY") : "-"} />
                            {data?.type !== "occupancy_based" ? (
                                <>
                                    <KeyAndValue keyName={"Double occupancy rate"} value={data?.standard_rate || "-"} />
                                    <KeyAndValue keyName={"Triple occupancy rate"} value={data?.max_occupancy_rate || "-"} />
                                    <KeyAndValue keyName={"Single occupancy rate"} value={data?.single_occupancy_rate || "-"} />
                                    <KeyAndValue keyName={"Extra bed rate"} value={data?.extra_bed_rate || "-"} />
                                    <KeyAndValue keyName={"Child rate"} value={data?.child_rate || "-"} />
                                </>
                            ) : null}
                            <KeyAndValue keyName={"Last sync"} value={data?.last_sync ? moment.unix(data?.last_sync).format("DD-MMM-YYYY - HH:mm") : "-"} />
                            <KeyAndValue keyName={"Sync status"} value={data?.sync_status || "-"} />
                            {/* <KeyAndValue keyName={"Stop sell"} value={data?.stop_sell ? <Check color="success"/> : <Close color="error"/>}/>
                            <KeyAndValue keyName={"Travelnet count"} value={data?.cron_avail_count ?? "-"}/>
                            <KeyAndValue keyName={"Hotsoft hotel id"} value={data?.hotsoft_hotel_id || "-"}/>
                            <KeyAndValue keyName={"Hotsoft room id"} value={data?.hotsoft_room_id || "-"}/>
                            <KeyAndValue keyName={"Roomtype"} value={data?.roomtype_id?.name || "-"}/> */}
                        </Grid>
                    </Box>
                    <Grid container spacing={2} mt={1}>
                        {data.type === "occupancy_based" && data?.percentages?.map((el, idx) => (
                            <Grid item xs={6} key={idx}>
                                <Box
                                    width="100%"
                                    p={2}
                                    borderRadius={1}
                                    backgroundColor={colors.primary[400]}
                                >
                                    <KeyAndValue keyName={"Percentage"} value={`${el.percentage || "0"}%`} />
                                    {el.minus_percentage > 0 ? (
                                        <KeyAndValue keyName={"Rate"} value={`${el.minus_percentage}% ↓`} />
                                    ) : null}
                                    {el.plus_percentage > 0 ? (
                                        <KeyAndValue keyName={"Rate"} value={`${el.plus_percentage}% ↑`} />
                                    ) : null}
                                    <Typography fontSize="small"><mark style={{backgroundColor: "gray"}}>{`if ${el.percentage}% - ${idx === 0 ? "100" : data?.percentages[idx - 1].percentage}% room is available, rate will ${el.plus_percentage > 0 ? "increase" : "decrease"}`}</mark></Typography>
                                    <Button
                                        size='small'
                                        variant='contained'
                                        onClick={() => handleCheckRatesButton(el)}
                                        sx={{mt: 1}}
                                        disabled={el.plus_percentage == 0 && el.minus_percentage == 0}
                                    >
                                        Check rates
                                    </Button>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </>
            ) : (
                <AddNewRate colors={colors} dispatch={dispatch} roomtypeId={data?.roomtype_id?._id} setIsAdding={setIsUpdating} updatingData={data}/>
            )}

            <Modal
                open={ratesModal}
                onClose={() => setRatesModal(false)}
            >
                <Box sx={modalStyle}>
                    <Box sx={{ maxHeight: "80vh", overflow: "auto" }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: "fit-content" }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell align="right">Double occupancy rate</TableCell>
                                        <TableCell align="right">Triple occupancy rate</TableCell>
                                        <TableCell align="right">Single occupancy rate</TableCell>
                                        {/* <TableCell align="right">Extra bed rate</TableCell>
                                        <TableCell align="right">Child rate</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rates.map((row) => (
                                        <TableRow
                                            key={row.date}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" sx={{ whiteSpace: "nowrap" }}>
                                                {row.date}
                                            </TableCell>
                                            <TableCell align="right">{row.standard_rate}</TableCell>
                                            <TableCell align="right">{row.max_occupancy_rate}</TableCell>
                                            <TableCell align="right">{row.single_occupancy_rate}</TableCell>
                                            {/* <TableCell align="right">{row.extra_bed_rate}</TableCell>
                                            <TableCell align="right">{row.child_rate}</TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default RateDetails;
