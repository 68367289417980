import { post } from "../../services";
import { createRoomNumbersArray, processHotsoftCalendarAvailability } from "../../utils";
import { 
    LIST_ALL_AVAILABILITY_REPORTS,
    LIST_ALL_CALENDAR_REPORTS, 
    LIST_ALL_INVENTORY_REPORTS, 
    LIST_ALL_INVENTORY_SCHEDULES, 
    LIST_ALL_ROOMS_REPORTS, 
    LIST_INVENTORY_SCHEDULE_DETAILS,
} from "../../graphql";
import { 
    RESET_ALL_CALENDAR_REPORTS, 
    RESET_ALL_INVENTORY_REPORTS, 
    RESET_ALL_ROOMS_REPORTS, 
    RESET_ALL_INVENTORY_SCHEDULES,
    RESET_INVENTORY_SCHEDULE_DETAILS,
    SAVE_ALL_CALENDAR_REPORTS, 
    SAVE_ALL_INVENTORY_REPORTS, 
    SAVE_ALL_ROOMS_REPORTS, 
    SAVE_ALL_INVENTORY_SCHEDULES,
    SAVE_INVENTORY_SCHEDULE_DETAILS,
    TOGGLE_LOADING,
    SAVE_ALL_AVAILABILITY_REPORTS,
    RESET_ALL_AVAILABILITY_REPORTS, 
} from "../types";
import moment from "moment";

export const listAllCalendarReports = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: LIST_ALL_CALENDAR_REPORTS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_calendar_reports: { data },
            } = apiResponse.data.data;

            let arr = []
            await data?.map(item => {
                if (item?.total_bookings > 0) {
                    arr.push({
                        ...item,
                        id: (Math.random() * 1000).toString(),
                        title: `${item.total_bookings} check-in`,
                        date: moment(new Date(item.date)).format("YYYY-MM-DD"),
                    })
                }
                return 0;
            });
            
            dispatch({
                type: SAVE_ALL_CALENDAR_REPORTS,
                payload: arr,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllCalendarReports = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_CALENDAR_REPORTS });
    };
};

export const listAllRoomsReports = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: requestData?.noLoading ? false : true });
        localStorage.setItem("loading2", "true");

        let apiResponse = await post("", {
            query: LIST_ALL_ROOMS_REPORTS,
            variables: requestData,
        });

        dispatch(resetAllRoomsReports());
        if (apiResponse.data.customStatus) {
            let {
                list_all_rooms_reports1: { data },
            } = apiResponse.data.data;

            if (localStorage?.getItem("isHotsoft") === "true") {
                data = processHotsoftCalendarAvailability(data)
            }

            dispatch({
                type: SAVE_ALL_ROOMS_REPORTS,
                payload: createRoomNumbersArray(data),
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
        localStorage.setItem("loading2", "false");
        localStorage.removeItem("grpBooking");
    };
};

export const resetAllRoomsReports = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_ROOMS_REPORTS });
    };
};

export const listAllAvailabilityReports = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: LIST_ALL_AVAILABILITY_REPORTS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_availability_reports: { data },
            } = apiResponse.data.data;

            if (localStorage?.getItem("isHotsoft") === "true") {
                data = processHotsoftCalendarAvailability(data)
            }

            dispatch({
                type: SAVE_ALL_AVAILABILITY_REPORTS,
                payload: createRoomNumbersArray(data),
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllAvailabilityReports = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_AVAILABILITY_REPORTS });
    };
};

export const listAllInventoryReports = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: LIST_ALL_INVENTORY_REPORTS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_inventory_reports: { data },
            } = apiResponse.data.data;
            dispatch({
                type: SAVE_ALL_INVENTORY_REPORTS,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllInventoryReports = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_INVENTORY_REPORTS });
    };
};

export const listAllInventorySchedules = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            inventorySchedules: { pageSize, pageNumber },
        } = getState().calendar;

        let apiResponse = await post("", {
            query: LIST_ALL_INVENTORY_SCHEDULES,
            variables: {
                ...requestData,
                pageSize: requestData?.pageSize || pageSize,
                pageNumber: requestData?.pageNumber || pageNumber,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_inventory_schedules: { data, hasMore, count },
            } = apiResponse.data.data;
            // let updatedList = [...list, ...data];

            dispatch({
                type: SAVE_ALL_INVENTORY_SCHEDULES,
                payload: {
                    list: data,
                    showViewMore: hasMore,
                    pageSize: requestData?.pageSize || pageSize,
                    pageNumber: requestData?.pageNumber || pageNumber,
                    count: count,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllInventorySchedules = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_INVENTORY_SCHEDULES });
    };
};

export const listInventoryScheduleDetails = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: LIST_INVENTORY_SCHEDULE_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_inventory_schedule_details: { data },
            } = apiResponse.data.data;
            dispatch({
                type: SAVE_INVENTORY_SCHEDULE_DETAILS,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetInventoryScheduleDetails = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_INVENTORY_SCHEDULE_DETAILS });
    };
};