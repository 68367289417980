import React, { useState } from 'react'
import { Box, Button, CircularProgress, IconButton, InputAdornment, Stack } from '@mui/material'
import { CssTextField } from '../../components/TextField';
import Header from '../../components/Header'
import * as yup from "yup";
import { Formik } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { changeHotelPassword } from '../../store/actions';

function ChangePassword({ dispatch, isLoading, setShowModal }) {
    const [showPassword, setShowPassword] = useState(false);

    const initialValues = {
        password: "",
        confirmPassword: "",
    }
    const checkoutSchema = yup.object().shape({
        password: yup.string().required("Required").min(8),
        confirmPassword: yup.string().required("Required").oneOf(
            [yup.ref('password'), null], 'Passwords must match'
        ),
    });
    const handleSubmit = async(values) => {
        let res = await dispatch(changeHotelPassword(values));
        if(res){
            setShowModal(false);
        }
    }

    return (
        <Box>
            <Header title="CHANGE PASSWORD" titleVariant="h4" />
            <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={checkoutSchema}>
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2}>
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                label="Password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.password || ""}
                                name="password"
                                error={!!touched.password && !!errors.password}
                                helperText={touched.password && errors.password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size='small'
                                                onClick={() => setShowPassword(showPassword ? false : true)}
                                            >
                                                {showPassword ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <CssTextField
                                autoComplete='off'
                                fullWidth
                                required
                                size="small"
                                type="password"
                                placeholder="Confirm Password"
                                label="Confirm Password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.confirmPassword || ""}
                                name="confirmPassword"
                                error={!!touched.confirmPassword && !!errors.confirmPassword}
                                helperText={touched.confirmPassword && errors.confirmPassword}
                            />
                            <Button fullWidth type='submit' variant='contained'>
                                {isLoading ? <CircularProgress color="inherit" size={24} />  : <b>Submit</b>}
                            </Button>
                        </Stack>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default ChangePassword;