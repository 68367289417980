import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Box, Button, CircularProgress, Divider, FormControlLabel, Grid, InputAdornment, MenuItem, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { ArrowBack, Email, KeyboardArrowRight, Person, Phone } from '@mui/icons-material';
import { addBooking } from '../../store/actions';
import { CssTextField } from '../../components/TextField';
// import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import moment from 'moment';

function BookNow({ code, colors, isLoading, setPage, roomtypeId, dispatch, setPaymentData, isActivitiesAvailable, selectedActivities, hotelDetails }) {
    // const navigate = useNavigate();
    // const [mPlans, setMPlans] = useState([]);
    // const [mealPlan, setMealPlan] = useState("no_preference");
    // const [mealPlanAmount, setMealPlanAmount] = useState(0);
    const [isPartialPmt, setIsPartialPmt] = useState(false);
    let datas = localStorage.getItem("checkingData");
    datas = JSON.parse(datas);
    let selected = localStorage.getItem("selected");
    selected = JSON.parse(selected);
    // let activities = localStorage.getItem("activities");
    // activities = JSON.parse(activities);
    const totalActivitiesPrice = selectedActivities?.reduce((total, item) => total + (item.rate * item.count), 0);
    const totalActivitiesTax = (totalActivitiesPrice * 18 / 100);
    const grandTotal = (selected.meal_plan.total_price + selected.meal_plan.tax_amount + (selectedActivities?.length ? totalActivitiesPrice + totalActivitiesTax : 0)).toFixed(2);
    // console.log(totalActivitiesPrice);

    let start = moment.unix(datas?.checkinDate);
    let end = moment.unix(datas?.checkoutDate );
    let diff = Math.ceil(moment.duration(start.diff(end)).asDays());
    diff = Math.abs(diff);

    // const { pricing } = useSelector(({ bookingEngine: { pricing } }) => ({
    //     pricing,
    // }));

    useEffect(() => {
        if(hotelDetails?.bke_partial_pmt === true) {
            setIsPartialPmt(true);
        }
        // fetchMealPlans();
    // eslint-disable-next-line
    }, [])

    // const fetchMealPlans = async() => {
    //     let mPlans = await dispatch(listMealPlans({ roomtypeId: roomtypeId }));
    //     if(mPlans?.length){
    //         const sortedPlans = mPlans.sort((a, b) => a.rate - b.rate);
    //         setMPlans(sortedPlans);
    //         setMealPlan(sortedPlans[0]?._id)
    //         setMealPlanAmount(sortedPlans[0]?.rate)
    //     } else {
    //         setMPlans([{
    //             _id: "no_preference",
    //             name: "CP",
    //             rate: 0,
    //         }])
    //     }
    // }

    const initialValues = {
        title: "Mr",
        name: "",
        first_name: "",
        last_name: "",
        email: "",
        guest_mobile: "",
        guest_address: "",
        remarks: "",
        buyer_pincode: "682304",
    };

    const validationSchema = yup.object().shape({
        first_name: yup
            .string()
            .matches(/^\S*$/, "Spaces not allowed")
            .matches(/^\w*$/, "Special chars not allowed")
            .required("Required"),
        last_name: yup
            .string()
            .matches(/^\S*$/, "Spaces not allowed")
            .matches(/^\w*$/, "Special chars not allowed")
            .required("Required"),
        email: yup.string().email("Invalid email").required("Required"),
        guest_mobile: yup
            .string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, "Invalid mobile number")
            .max(20, "Invalid mobile number")
            // .matches(/^\d{10}$/, "Invalid mobile number")
            .required("Required"),
        // buyer_pincode: yup.number().required("Required"),
    })

    const handleFormSubmit = async (values) => {
        values.email = values?.email?.toLowerCase();
        let val = {
            ...values,
            hotel_code: code,
            roomtype_id: roomtypeId,
            no_of_adults: datas?.numOfAdults,
            no_of_childs: datas?.numOfChilds,
            total_rooms: datas?.totalRooms,
            checkin_date: datas?.checkinDate,
            checkout_date: datas?.checkoutDate,
            name: `${values.first_name} ${values.last_name || ""}`,
            meal_plan_id: selected.meal_plan._id ? selected.meal_plan._id : "",
            activities: selectedActivities?.map(({_id, count }) => ({_id, count})),
            partial_pmt: isPartialPmt,
            // first_name: values?.name,
            // last_name: "a",
        }
        console.log(val);
        let res = await dispatch(addBooking(val));
        if (res) {
            setPaymentData({
                ...res,
                ...values,
            })
            setPage(4);
            // navigate(`/booking/${code}/payment`)
        }
    }

    return (
        <>
            <Stack alignItems="center">
                <Grid container spacing={2} justifyContent="center" mb={5}>
                    <Grid item md={12} xs={12}>
                        {/* <Button variant='contained' onClick={() => setPage(isActivitiesAvailable ? 3 : 2)}>
                            <ArrowBack />
                        </Button> */}
                        <Stack p={2} bgcolor={"rgb(0, 0, 0, 0.7)"} style={{ backdropFilter: `blur(5px)` }} borderRadius={2} mt={2}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={4}>
                                    {selected ? (
                                        <>
                                            <Stack bgcolor={colors.primary[400]} p={1} borderRadius={1}>
                                                <Stack p={1} spacing={1}>
                                                    <Stack direction="row" justifyContent="space-between">
                                                        <Stack width={"50%"} borderRight={"1px solid #666"}>
                                                            <Typography color={"#aaa"} fontSize={"small"}>Check In</Typography>
                                                            <Typography fontWeight={600}>{moment.unix(datas?.checkinDate).format("DD-MMM-YYYY") || "-"}</Typography>
                                                        </Stack>
                                                        <Stack width={"50%"} textAlign="right">
                                                            <Typography color={"#aaa"} fontSize={"small"}>Check Out</Typography>
                                                            <Typography fontWeight={600}>{moment.unix(datas?.checkoutDate).format("DD-MMM-YYYY") || "-"}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                    <Divider />

                                                    <Stack direction="row" justifyContent="space-between">
                                                        <Typography color={"#aaa"} fontSize={"small"}>No of nights</Typography>
                                                        <Typography fontWeight={600}>{diff ?? "0"} {diff === 1 ? "Night" : "Nights"}</Typography>
                                                    </Stack>
                                                    <Stack direction="row" justifyContent="space-between">
                                                        <Typography color={"#aaa"} fontSize={"small"}>Room</Typography>
                                                        <Typography fontWeight={600}>{`${datas?.totalRooms} x ${selected.name}` || "-"}</Typography>
                                                    </Stack>
                                                    <Stack direction="row" justifyContent="space-between">
                                                        <Typography color={"#aaa"} fontSize={"small"}>Guests</Typography>
                                                        <Typography fontWeight={600}>{datas?.numOfAdults ?? 0}  Adults, {datas?.numOfChilds ?? 0} Children</Typography>
                                                    </Stack>
                                                </Stack>

                                                <Stack
                                                    p={1}
                                                    spacing={1}
                                                    border={"1px solid #666"}
                                                >
                                                    <Stack direction="row" alignItems="center">
                                                        <KeyboardArrowRight color='primary'/> <Typography color={"#aaa"} flexGrow={1} fontSize={"small"}>{selected?.meal_plan?.name} {selected?.meal_plan?.description ? `- ${selected?.meal_plan?.description}`: ""}</Typography>
                                                        <Typography fontWeight={600}>₹{(selected.meal_plan.total_price).toFixed(2) || "-"}</Typography>
                                                    </Stack>
                                                    {selectedActivities?.length ? (
                                                        <Stack direction="row">
                                                            <KeyboardArrowRight color='primary'/><Typography color={"#aaa"} flexGrow={1} fontSize={"small"}>Enhance Your Stay</Typography>
                                                            <Typography fontWeight={600}>₹{totalActivitiesPrice.toFixed(2) || "-"}</Typography>
                                                        </Stack>
                                                    ) : null}
                                                    <Stack direction="row" justifyContent="space-between">
                                                        <Typography color={"#aaa"} fontSize={"small"}>Sub Total</Typography>
                                                        <Typography fontWeight={600}>₹{(selected.meal_plan.total_price + (selectedActivities?.length ? totalActivitiesPrice : 0)).toFixed(2) || "-"}</Typography>
                                                    </Stack>
                                                    <Stack direction="row" justifyContent="space-between">
                                                        <Typography color={"#aaa"} fontSize={"small"}>Taxes and Fees</Typography>
                                                        <Typography fontWeight={600}>₹{(selected.meal_plan.tax_amount + (selectedActivities?.length ? totalActivitiesTax : 0)).toFixed(2)  || "-"}</Typography>
                                                    </Stack>
                                                    <Divider />
                                                    <Stack direction="row" justifyContent="space-between" alignItems="end">
                                                        <Typography>Grand Total</Typography>
                                                        <Typography color="primary" fontSize="20px" fontWeight={600}>₹{grandTotal || "-"}</Typography>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </>
                                    ) : null}
                                </Grid>
                            <Grid item xs={12} md={8}>
                            <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={validationSchema}>
                                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={3}>
                                            <Grid item md={6} xs={12}>
                                                <Stack direction="row">
                                                    <CssTextField
                                                        select
                                                        required
                                                        name="title"
                                                        onChange={handleChange}
                                                        value={values.title}
                                                        sx={{ width: 100 }}
                                                    >
                                                        <MenuItem value={"Mr"}>
                                                            {"Mr"}
                                                        </MenuItem>
                                                        <MenuItem value={"Ms"}>
                                                            {"Ms"}
                                                        </MenuItem>
                                                    </CssTextField>
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        // size="small"
                                                        type="text"
                                                        // label="Name"
                                                        placeholder="Fist name *"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.first_name}
                                                        name="first_name"
                                                        error={!!touched.first_name && !!errors.first_name}
                                                        helperText={touched.first_name && errors.first_name}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Person />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <CssTextField
                                                    required
                                                    fullWidth
                                                    // size="small"
                                                    type="text"
                                                    // label="Name"
                                                    placeholder="Last name *"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.last_name}
                                                    name="last_name"
                                                    error={!!touched.last_name && !!errors.last_name}
                                                    helperText={touched.last_name && errors.last_name}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Person />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <CssTextField
                                                    required
                                                    fullWidth
                                                    // size="small"
                                                    type="email"
                                                    // label="Email"
                                                    placeholder="Email *"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    name="email"
                                                    error={!!touched.email && !!errors.email}
                                                    helperText={touched.email && errors.email}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Email />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <CssTextField
                                                    required
                                                    fullWidth
                                                    // size="small"
                                                    type="number"
                                                    placeholder="Mobile *"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.guest_mobile}
                                                    name="guest_mobile"
                                                    error={!!touched.guest_mobile && !!errors.guest_mobile}
                                                    helperText={touched.guest_mobile && errors.guest_mobile}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Phone />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            {/* <Grid item md={6} xs={12}>
                                                <CssTextField
                                                    fullWidth
                                                    // size="small"
                                                    type="number"
                                                    label="Paid Amount"
                                                    placeholder="Paid Amount"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.paidAmount}
                                                    name="paidAmount"
                                                    onInvalid={e => e.target.setCustomValidity(rateErrorMsg)}
                                                    onInput={e => e.target.setCustomValidity('')}
                                                    error={!!touched.paidAmount && !!errors.paidAmount}
                                                    helperText={touched.paidAmount && errors.paidAmount}
                                                    InputProps={{
                                                        inputProps: { min: 0, max: selectedRooms[0]?.rate },
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Money />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid> */}
                                            {/* <Grid item md={6} xs={12}>
                                                <CssTextField
                                                    fullWidth
                                                    select
                                                    // size="small"
                                                    value={status}
                                                    label="Status"
                                                    onChange={(e) => setStatus(e.target.value)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Build />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                >
                                                    <MenuItem value={"confirmed"}>
                                                        {"Confirmed"}
                                                    </MenuItem>
                                                    <MenuItem value={"hold"}>
                                                        {"Hold"}
                                                    </MenuItem>
                                                </CssTextField>
                                            </Grid> */}

                                            {/* <Grid item md={6} xs={12}>
                                                <CssTextField
                                                    fullWidth
                                                    select
                                                    // size="small"
                                                    value={mealPlan}
                                                    label="Meal Plan"
                                                    onChange={(e) => {
                                                        setMealPlan(e.target.value)
                                                        setMealPlanAmount(mPlans.find((el) => el._id === e.target.value).rate)
                                                    }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Restaurant />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                >
                                                    {!mPlans?.length ?
                                                        <MenuItem value={"no_preference"}>
                                                            {"CP"}
                                                        </MenuItem>
                                                        : null}
                                                    {mPlans?.map((e) => (
                                                        <MenuItem value={e?._id} key={e?._id}>
                                                            <Stack width="100%">
                                                                <Stack
                                                                    width="100%"
                                                                    direction="row"
                                                                    justifyContent="space-between"
                                                                    spacing={2}
                                                                >
                                                                    <Typography>
                                                                        {e?.name?.toUpperCase()}
                                                                    </Typography>
                                                                    <Typography>
                                                                        ₹{e?.rate}
                                                                    </Typography>
                                                                </Stack>
                                                                <Typography color={"#aaa"}><small>{e?.description}</small></Typography>
                                                            </Stack>
                                                        </MenuItem>
                                                    ))}
                                                </CssTextField>
                                                <Stack alignItems="end">
                                                    <Typography fontSize="small">/ person</Typography>
                                                </Stack>
                                            </Grid> */}
                                            {/* <Grid item md={6} xs={12}>
                                                <Typography fontSize="small" mt={-2}>Meal plan</Typography>
                                                <Grid container spacing={1}>
                                                    {mPlans?.map((plan) => (
                                                        <Grid item key={plan._id}>
                                                            <Box
                                                                p={1}
                                                                borderRadius={1}
                                                                border={plan._id === mealPlan ? `1px solid ${colors.greenAccent[400]}` : "1px solid #999"}
                                                                bgcolor={plan._id === mealPlan ? colors.greenAccent[700] : ""}
                                                                sx={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setMealPlan(plan._id)
                                                                    setMealPlanAmount(mPlans.find((el) => el._id === plan._id).rate)
                                                                }}
                                                            >
                                                                <Typography>
                                                                    {plan?.name?.toUpperCase()} = Rs {plan?.rate}
                                                                </Typography>
                                                                {plan?.description ? (
                                                                <Typography color={"#aaa"}><small>{plan?.description}</small></Typography>
                                                                ) : null}
                                                            </Box>
                                                        </Grid>
                                                    ))}
                                                    <Grid item><Typography fontSize="small">/ person</Typography></Grid>
                                                </Grid>
                                            </Grid> */}
                                            {/* <Grid item md={6} xs={12}>
                                                <CssTextField
                                                    required
                                                    fullWidth
                                                    // size="small"
                                                    type="number"
                                                    placeholder="Pincode *"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.buyer_pincode}
                                                    name="buyer_pincode"
                                                    error={!!touched.buyer_pincode && !!errors.buyer_pincode}
                                                    helperText={touched.buyer_pincode && errors.buyer_pincode}
                                                // InputProps={{
                                                //     startAdornment: (
                                                //         <InputAdornment position="start">
                                                //             <Phone />
                                                //         </InputAdornment>
                                                //     ),
                                                // }}
                                                />
                                            </Grid> */}

                                            {/* <Grid item md={6} xs={12}>
                                                <CssTextField
                                                    fullWidth
                                                    multiline
                                                    minRows={3}
                                                    maxRows={Infinity}
                                                    type="text"
                                                    size="small"
                                                    label="Address"
                                                    placeholder="Address"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.guest_address}
                                                    name="guest_address"
                                                    error={!!touched.guest_address && !!errors.guest_address}
                                                    helperText={touched.guest_address && errors.guestAddress}
                                                // InputProps={{
                                                //     startAdornment: (
                                                //         <InputAdornment position="start">
                                                //             <EditLocation />
                                                //         </InputAdornment>
                                                //     ),
                                                // }}
                                                />
                                            </Grid> */}
                                            <Grid item md={6} xs={12}>
                                                <CssTextField
                                                    fullWidth
                                                    multiline
                                                    minRows={3}
                                                    maxRows={Infinity}
                                                    type="text"
                                                    size="small"
                                                    label="Remarks"
                                                    placeholder="Remarks"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.remarks}
                                                    name="remarks"
                                                    error={!!touched.remarks && !!errors.remarks}
                                                    helperText={touched.remarks && errors.remarks}
                                                // InputProps={{
                                                //     startAdornment: (
                                                //         <InputAdornment position="start">
                                                //             <NoteAdd />
                                                //         </InputAdornment>
                                                //     ),
                                                // }}
                                                />
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                    <Typography color="primary" fontSize="20px" textAlign="right">
                                                        Total Price: <b>₹{pricing?.total_price + (datas?.numOfAdults * mealPlanAmount)}</b>
                                                    </Typography>
                                                    <Typography color="#ccc" textAlign="right">
                                                        (including tax)
                                                    </Typography>
                                            </Grid> */}
                                            {hotelDetails?.bke_partial_pmt === true ? (
                                                <Grid item md={12} xs={12}>
                                                    <Stack
                                                        pl={1}
                                                        pr={1}
                                                        spacing={1}
                                                        border={"1px solid #666"}
                                                    >
                                                        <RadioGroup
                                                            value={isPartialPmt}
                                                            onChange={(e) => setIsPartialPmt(e.target.value)}
                                                        >
                                                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                                <FormControlLabel value={true} control={<Radio />} label="I prefer to pay 50% now" />
                                                                <Typography color="primary" fontSize="20px" fontWeight={600}>₹{(grandTotal / 2).toFixed(2) || "-"}</Typography>
                                                            </Stack>
                                                            <Divider />
                                                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                                <FormControlLabel value={false} control={<Radio />} label="I prefer to pay 100% now" />
                                                                <Typography color="primary" fontSize="20px" fontWeight={600}>₹{grandTotal || "-"}</Typography>
                                                            </Stack>
                                                        </RadioGroup>
                                                    </Stack>
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                        <Grid container spacing={2} mt={6}>
                                            {/* <Grid item md={6} xs={12}>
                                                <Button
                                                    fullWidth
                                                    // size="small"
                                                    color="secondary"
                                                    variant="outlined"
                                                    sx={{ fontSize: "15px" }}
                                                    onClick={() => setPage(2)}
                                                >
                                                    <b>Go Back</b>
                                                </Button>
                                            </Grid> */}
                                            <Grid item md={12} xs={12}>
                                                <Button
                                                    fullWidth
                                                    // size="small"
                                                    type="submit"
                                                    color="secondary"
                                                    variant="contained"
                                                    sx={{ fontSize: "15px" }}
                                                >
                                                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Book Now</b>}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                            </Grid>
                            </Grid>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </>
    )
}

export default BookNow