import React, { useEffect } from "react";
import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { listActivityDetails, resetActivityDetails } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

function ActivityDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { activityDetails } = useSelector(({ activities: { activityDetails } }) => ({
        activityDetails,
    }));

    useEffect(() => {
        document.title = 'Activity Details';
        dispatch(resetActivityDetails());
        fetchMoreData();
        // eslint-disable-next-line
    }, [dispatch, id]);

    const fetchMoreData = () => {
        dispatch(listActivityDetails({ id: id }));
    }

    return (
        <Box m="20px" mb={15}>
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
                spacing={2}
                m="20px 0"
            >
                <Button size="small" variant="outlined" onClick={() => navigate(-1)}>
                    <ArrowBack fontSize="small" />
                </Button>
                <Typography fontWeight="bold" variant="h4">
                    ACTIVITY DETAILS
                </Typography>
                <Box width={40} />
            </Stack>
            <Box
                width="100%"
                p={2}
                borderRadius={1}
                backgroundColor={colors.primary[400]}
            >
                <Grid container rowSpacing={2} columnSpacing={4}>
                    <Grid item md={6} xs={12}>
                        <Stack direction="row" spacing={2}>
                            <Typography width={"40%"}>Name:</Typography>
                            <Typography>
                                {activityDetails?.name || "-"}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack direction="row" spacing={2}>
                            <Typography width={"40%"}>Description:</Typography>
                            <Typography>
                                {activityDetails?.description || "-"}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack direction="row" spacing={2}>
                            <Typography width={"40%"}>Rate:</Typography>
                            <Typography>
                                {activityDetails?.rate || "-"}
                            </Typography>
                        </Stack>
                    </Grid>
                    {activityDetails?.img ? (
                        <Grid item md={6} xs={12}>
                            <Stack direction="row" spacing={2}>
                                <Typography width={"40%"}>Image:</Typography>
                                <img width={"60%"} src={activityDetails?.img} alt="" />
                            </Stack>
                        </Grid>
                    ) : null}
                </Grid>
            </Box>
        </Box>
    );
}

export default ActivityDetails;
