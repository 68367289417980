import React, { useRef } from "react";
import { Button, CircularProgress, Grid, InputAdornment, Stack, Typography } from "@mui/material";
import { CssTextField } from "../../components/TextField";
import { Formik } from "formik";
import moment from "moment";
import { CalendarMonth, Close } from "@mui/icons-material";
import PlusOrMinusButton from "../../components/PlusOrMinusButton";
import { requestBooking } from "../../store/actions";
import Header from "../../components/Header";

function RequestBookingModalContent({ code, dispatch, isLoading, setRequestModal }) {
  const checkinRef = useRef();
  const checkoutRef = useRef();

  const initialValues = {
    name: "",
    email: "",
    mobile: "",
    checkinDate: moment().format("YYYY-MM-DD"),
    checkoutDate: moment().add(1, "days").format("YYYY-MM-DD"),
    numOfAdults: 2,
    numOfChilds: 0,
    totalRooms: 1,
  };

  const handleFormSubmit = async (values) => {
    let data = {
      ...values,
      hotelCode: code,
      checkinDate: moment(values.checkinDate, "YYYY-MM-DD").format("DD-MMM-YYYY"),
      checkoutDate: moment(values.checkoutDate, "YYYY-MM-DD").format("DD-MMM-YYYY"),
    };
    console.log(data);
    dispatch(requestBooking(data));
  };

  return (
    <>
      <Formik onSubmit={handleFormSubmit} initialValues={initialValues}>
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="end">
                <Grid item xs={12} sm={12}>
                    <Stack direction="row" justifyContent="space-between" mb={-3}>
                        <Header title="ENQUIRE BOOKING" titleVariant="h4" />
                        <Close onClick={() => setRequestModal(false)} sx={{ cursor: "pointer" }} />
                    </Stack>
                </Grid>
              <Grid item md={12} xs={12}>
                <Typography>
                  <b>Name *</b>
                </Typography>
                <CssTextField
                  fullWidth
                  required
                  size="small"
                  type="text"
                  placeholder="Name *" 
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>
                  <b>Email *</b>
                </Typography>
                <CssTextField
                  fullWidth
                  required
                  size="small"
                  type="email"
                  placeholder="Email *"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>
                  <b>Mobile *</b>
                </Typography>
                <CssTextField
                  fullWidth
                  required
                  size="small"
                  type="number"
                  placeholder="Mobile *"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.mobile}
                  name="mobile"
                  error={!!touched.mobile && !!errors.mobile}
                  helperText={touched.mobile && errors.mobile}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>
                  <b>Check-in date</b>
                </Typography>
                <CssTextField
                  fullWidth
                  required
                  size="small"
                  type="date"
                  placeholder="Check-in date"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    if (
                      moment(e.target.value).add(1, "days").format("YYYY-MM-DD") >
                      values.checkoutDate
                    ) {
                      setFieldValue(
                        "checkoutDate",
                        moment(e.target.value).add(1, "days").format("YYYY-MM-DD")
                      );
                    }
                  }}
                  value={values.checkinDate}
                  name="checkinDate"
                  error={!!touched.checkinDate && !!errors.checkinDate}
                  helperText={touched.checkinDate && errors.checkinDate}
                  inputProps={{ min: moment().format("YYYY-MM-DD") }}
                  inputRef={checkinRef}
                  onClick={() => checkinRef.current.showPicker()}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarMonth sx={{ cursor: "pointer" }} />
                      </InputAdornment>
                    ),
                  }}
                  // disabled={page === 2}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>
                  <b>Check-out date</b>
                </Typography>
                <CssTextField
                  fullWidth
                  required
                  size="small"
                  type="date"
                  placeholder="Checkout Date"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.checkoutDate}
                  name="checkoutDate"
                  error={!!touched.checkoutDate && !!errors.checkoutDate}
                  helperText={touched.checkoutDate && errors.checkoutDate}
                  inputProps={{
                    min: moment(values.checkinDate).add(1, "days").format("YYYY-MM-DD"),
                  }}
                  inputRef={checkoutRef}
                  onClick={() => checkoutRef.current.showPicker()}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarMonth sx={{ cursor: "pointer" }} />
                      </InputAdornment>
                    ),
                  }}
                  // disabled={page === 2}
                />
              </Grid>

              {/* <Grid item md={6} xs={12}></Grid> */}
              <Grid item md={6} xs={6}>
                <Typography>
                  <b>Number of adults / room</b>
                </Typography>
                <PlusOrMinusButton
                  min={1}
                  max={20}
                  name="numOfAdults"
                  value={values.numOfAdults}
                  handleChange={(value) => setFieldValue("numOfAdults", value)}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography>
                  <b>Number of children / room</b>
                </Typography>
                <PlusOrMinusButton
                  min={0}
                  max={20}
                  name="numOfChilds"
                  value={values.numOfChilds}
                  handleChange={(value) => setFieldValue("numOfChilds", value)}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography>
                  <b>Total rooms</b>
                </Typography>
                <PlusOrMinusButton
                  min={1}
                  max={30}
                  name="totalRooms"
                  value={values.totalRooms}
                  handleChange={(value) => setFieldValue("totalRooms", value)}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <Button
                  fullWidth
                  // size="small"
                  type="submit"
                  color="secondary"
                  variant="contained"
                  // disabled={page === 2}
                  sx={{ fontSize: "15px" }}
                >
                  {isLoading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    <b>Enquire booking</b>
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
}

export default RequestBookingModalContent;
