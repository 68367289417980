import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Stack, Typography, useTheme } from "@mui/material";
import { activateActivity, listAllActivities, resetAllActivities } from "../../store/actions";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Add, ArrowForward, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { tableStyle } from "../../components";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AvailabilityUpdation from "../../components/AvailabilityUpdation";
import AddNewActivityModalContent from "./addNewActivityModalContent";
import UpdateActivityModalContent from "./updateActivityModalContent";

const ActivitiesList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [updatingData, setUpdatingData] = useState({});

  const { isLoading, activitiesList, showViewMore } = useSelector(
    ({
      loading,
      activities: {
        activities: { list, showViewMore },
      },
    }) => ({
      isLoading: loading,
      activitiesList: list,
      showViewMore,
    })
  );

  useEffect(() => {
    document.title = 'Activities';
    dispatch(resetAllActivities());
    fetchMoreData();
    // eslint-disable-next-line
  }, [dispatch]);

  const fetchMoreData = () => {
    dispatch(listAllActivities());
  }

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Stack width="100%" alignItems="center">
          {showViewMore ? <Button fullWidth onClick={fetchMoreData}>
            <b>view more</b>
          </Button> : <Typography variant="h6">All datas listed</Typography>}
        </Stack>
      </GridFooterContainer>
    );
  }

  const columns = [
    // {
    //   field: "",
    //   headerName: "",
    //   renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
    //   width: 20,
    // },
    { field: "name", headerName: "Name", width: 250, cellClassName: "name-column--cell" },
    { field: "description", headerName: "Description", width: 200, },
    { field: "rate", headerName: "Rate", width: 100, },
    {
      field: "is_active",
      headerName: "Availability",
      width: 100,
      renderCell: ({ row: { is_active, _id } }) => {
        return (
          <AvailabilityUpdation
            isActive={is_active}
            action={() => dispatch(activateActivity({ id: _id }))}
          />
        );
      },
    },
    {
      field: "",
      headerName: "Update",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setUpdatingData(row);
              setUpdateModal(true);
            }}
            endIcon={<Edit fontSize="small" />}
            sx={{ fontWeight: 700 }}
          >
            Update
          </Button>
        );
      },
    },
    {
      field: "_id",
      headerName: "View details",
      width: 150,
      renderCell: ({ row: { _id } }) => {
        return (
          <Button
            size="small"
            variant="contained"
            onClick={async () => {
              navigate(`/activity-details/${_id}`);
            }}
            endIcon={<ArrowForward fontSize="small" />}
            sx={{ fontWeight: 700 }}
          >
            View
          </Button>
        );
      },
    },
  ];
  return (
    <Box m="20px" mb={15}>
      <Stack
        direction={{ sm: "row", xs: "column" }}
        justifyContent={{ sm: "space-between", xs: "start" }}
        alignItems={{ sm: "center", xs: "start" }}
        m="20px 0"
      >
        <Header title="ACTIVITIES" subtitle="welcome to Activities" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={() => setShowModal(true)}
          >
            <Add sx={{ mr: "10px" }} />
            Add new activity
          </Button>
        </Box>
      </Stack>
      <Box
        m="8px 0 0 0"
        height="68vh"
        sx={tableStyle(colors)}
      >
        <DataGrid
          rows={activitiesList}
          columns={columns}
          // components={{ Footer: CustomFooter }}
          getRowId={(row) => row?._id}
        />
      </Box>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <>
          <AddNewActivityModalContent
            dispatch={dispatch}
            isLoading={isLoading}
            setModal={setShowModal}
            colors={colors}
          />
        </>
      </Modal>
      
      <Modal
        open={updateModal}
        onClose={() => setUpdateModal(false)}
      >
        <>
          <UpdateActivityModalContent
            dispatch={dispatch}
            isLoading={isLoading}
            setModal={setUpdateModal}
            colors={colors}
            updatingData={updatingData}
          />
        </>
      </Modal>

    </Box>
  );
};

export default ActivitiesList;
