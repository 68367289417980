import React, { useState } from 'react';
import { listBookingDetails, resetBookingDetails, updateCheckinOrcheckout } from '../../../store/actions';
import { Button, CircularProgress, InputAdornment, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarMonth, DateRange } from '@mui/icons-material';
import { CssTextField } from '../../../components/TextField';
import moment from 'moment';
import { toast } from 'react-toastify';

function FirstPage({ bookingDetails, setRoomsData, setPage, setUpdateModal }) {
    const dispatch = useDispatch();
    const [checkin, setCheckin] = useState(moment.unix(bookingDetails?.checkin_date).format("YYYY-MM-DD"));
    const [checkout, setCheckout] = useState(moment.unix(bookingDetails?.checkout_date).format("YYYY-MM-DD"));

    const { isLoading } = useSelector(({ loading }) => ({ isLoading: loading }));

    const handleSwapBtn = async () => {
        let data = {
            booking_id: bookingDetails?.booking_id,
            checkin_date: moment(checkin).unix(),
            checkout_date: moment(checkout).unix(),
        };

        let res = await dispatch(updateCheckinOrcheckout(data));
        if(res){
            if(res?.status){
                toast.success("Booking dates updated");
                dispatch(resetBookingDetails());
                dispatch(listBookingDetails({ bookingId: bookingDetails?.booking_id }));
                setUpdateModal(false);
            } else {
                setRoomsData(res?.rooms);
                setPage(2);
            }
        }
    }

    return (
        <>
            <Stack spacing={2}>
                <CssTextField
                    fullWidth
                    required
                    type="date"
                    placeholder="Checkin Date"
                    label="Checkin Date"
                    onChange={(e) => setCheckin(e.target.value)}
                    value={checkin}
                    inputProps={{ min: moment().format("YYYY-MM-DD") }}
                    name="checkinDate"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <DateRange />
                            </InputAdornment>
                        ),
                    }}
                />
                <CssTextField
                    fullWidth
                    required
                    type="date"
                    placeholder="Checkout Date"
                    label="Checkout Date"
                    onChange={(e) => setCheckout(e.target.value)}
                    value={checkout}
                    inputProps={{ min: checkin }}
                    name="checkinDate"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <CalendarMonth />
                            </InputAdornment>
                        ),
                    }}
                />
                <Button 
                    variant='contained' 
                    onClick={handleSwapBtn}
                    disabled={
                        moment.unix(bookingDetails?.checkin_date).format("YYYY-MM-DD") === checkin &&
                        moment.unix(bookingDetails?.checkout_date).format("YYYY-MM-DD") === checkout
                    } 
                >
                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                </Button>
            </Stack>
        </>
    )
}

export default FirstPage