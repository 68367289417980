/*eslint-disable*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminLoginToHotel, getRegistrationStatus, listHotelProfile, logoutUser } from "../../store/actions";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "@mui/material";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";

function AdminLogin() {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading } = useSelector(({ loading }) => ({
    loading,
  }));

  useEffect(() => {
    dispatch(logoutUser(false));
    apiCall();
  }, []);

  const apiCall = async() => {
    let status = await dispatch(adminLoginToHotel({token: token}));
    if (status === true){
      let regStatus = await dispatch(getRegistrationStatus());
      await dispatch(listHotelProfile());
      navigate(regStatus ? "/dashboard" : "/registration");
    } else {
      toast.error("Failed to verify token");
    }
  }

  return (
    <Container>
        <Loader visible={loading} />
    </Container>
  );
}

export default AdminLogin;
