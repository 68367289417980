import React from 'react';
import { Box, Typography } from '@mui/material';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts';

function OtaWiseRoomNightSales({ data, colors, monthAndYear }) {
    data = data.map(({ label, value }) => ({ value, label: `${label} - ${value ?? 0}` }));
    return (
        <Box backgroundColor={colors.primary[400]} p="20px" height="100%">
            <Typography variant="h5">
                OTA WISE ROOM NIGHT SALES ON
            </Typography>
            <Typography variant="h4" fontWeight="600" textTransform={"uppercase"}>
                {monthAndYear}
            </Typography>
            {data?.length ? (
                <Box
                    width={"100%"}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mt={1}
                    // ml={-4}
                >
                    <PieChart
                        colors={['#4f7bff', '#4cbef5', '#ff9494', '#b2d15c', '#c674d4', '#fc9765']}
                        series={[
                            {
                                arcLabel: (item) => `${item.value}`,
                                arcLabelMinAngle: 45,
                                outerRadius: 100,
                                paddingAngle: 1,
                                cornerRadius: 1,
                                data,
                            },
                        ]}
                        sx={{
                            [`& .${pieArcLabelClasses.root}`]: {
                                fill: 'white',
                                fontWeight: 'bold',
                            },
                            // '--ChartsLegend-itemWidth': '130px',
                            // '--ChartsLegend-labelSpacing': '5px',
                            // '--ChartsLegend-rootSpacing': '5px',
                            overflow: "visible",
                            marginLeft: -10
                        }}
                        width={400}
                        height={300}
                        // legend={{
                        //     position: { vertical: "bottom", horizontal: "middle" },
                        // }}
                    />
                </Box>
            ) : (
                <Typography mt={15} mb={15} textAlign="center">
                    No Data Available...
                </Typography>
            )}
        </Box>
    )
}

export default OtaWiseRoomNightSales;