import React, { useState } from "react";
import { Box, Button, CircularProgress, InputAdornment, MenuItem, Stack, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateAllBookingStatus } from "../../../store/actions";
import { CssTextField } from "../../../components/TextField";
import { Build, Close } from "@mui/icons-material";
import { tokens } from "../../../theme";
import { toast } from "react-toastify";
import Header from "../../../components/Header";

function UpdateBookingStatus({ bookingDetails, setUpdateStatusModal }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const colors = tokens(theme.palette.mode);
    const [selectedStatus, setSelectedStatus] = useState(bookingDetails?.status);

    const { isLoading } = useSelector(({ loading }) => ({ isLoading: loading }));

    const modalBoxStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "90vh",
        width: { sm: 400, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    const handleCancelBtn = async () => {
        let res = await dispatch(
            updateAllBookingStatus({
                bookingId: bookingDetails?.booking_id,
                status: selectedStatus,
            })
        );
        if (res) {
            toast.success("Status updated");
            setUpdateStatusModal(false);
        }
    };

    return (
        <Box sx={modalBoxStyle}>
            <Stack direction="row" justifyContent="space-between">
                <Header title="UPDATE STATUS" titleVariant="h4" />
                <Close onClick={() => setUpdateStatusModal(false)} sx={{ cursor: "pointer" }} />
            </Stack>
            <Stack spacing={2}>
                <CssTextField
                    fullWidth
                    select
                    value={selectedStatus || "no_preference"}
                    label="Booking status"
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Build />
                            </InputAdornment>
                        ),
                    }}
                >
                    {bookingDetails?.status === "booked" ? (
                        <MenuItem value={"booked"}>{"Booked"}</MenuItem>
                    ) : (
                        <MenuItem value={"confirmed"}>{"Confirmed"}</MenuItem>
                    )}
                    <MenuItem value={"hold"}>{"Block"}</MenuItem>
                    <MenuItem value={"cancelled"}>{"Cancelled"}</MenuItem>
                </CssTextField>
                {/* {selectedStatus === "cancelled" && (
                    <Stack color="#db4f4a">
                        <b>This action will cancel this bookind!</b>
                        <b>You won't be able to revert it</b>
                    </Stack>
                )} */}
                <Button
                    fullWidth
                    variant="contained"
                    onClick={handleCancelBtn}
                    disabled={bookingDetails?.status === selectedStatus}
                >
                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Update</b>}
                </Button>
            </Stack>
        </Box>
    );
}

export default UpdateBookingStatus;
