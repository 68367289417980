import React from 'react';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { CssTextField } from '../../components/TextField';
import { addNewAgent } from '../../store/actions';
import { Formik } from 'formik';
import Header from '../../components/Header';
import * as yup from "yup";

function AddNewAgentModalContent({ dispatch, isLoading, setModal, setAgent, colors }) {

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { sm: 400, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        roomsAllowed: 0,
    };
    const checkoutSchema = yup.object().shape({
        email: yup.string().email("Invalid email").required("Required"),
    });
    const handleFormSubmit = async (values) => {
        console.log(values);
        let newAgentId = await dispatch(addNewAgent(values));
        if(setAgent && newAgentId){
            setAgent(newAgentId)
        }
        setModal(false);
    };

    return (
        <>
            <Box sx={modalStyle}>
                <>
                    <Header title="ADD NEW AGENT" titleVariant="h4" />
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>

                                <Stack spacing={2}>
                                    <CssTextField
                                        required
                                        fullWidth
                                        size="small"
                                        type="text"
                                        label="Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.firstName}
                                        name="firstName"
                                        error={!!touched.firstName && !!errors.firstName}
                                        helperText={touched.firstName && errors.firstName}
                                    />
                                    {/* <CssTextField
                                        fullWidth
                                        size="small"
                                        type="text"
                                        label="Last name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.lastName}
                                        name="lastName"
                                        error={!!touched.lastName && !!errors.lastName}
                                        helperText={touched.lastName && errors.lastName}
                                    /> */}
                                    <CssTextField
                                        required
                                        fullWidth
                                        size="small"
                                        type="email"
                                        label="Email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        name="email"
                                        error={!!touched.email && !!errors.email}
                                        helperText={touched.email && errors.email}
                                    />
                                    <CssTextField
                                        fullWidth
                                        size="small"
                                        type="number"
                                        label="Mobile"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.mobile}
                                        name="mobile"
                                        error={!!touched.mobile && !!errors.mobile}
                                        helperText={touched.mobile && errors.mobile}
                                    />
                                    {/* <CssTextField
                                        fullWidth
                                        size="small"
                                        type="number"
                                        label="Rooms allowed"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.roomsAllowed}
                                        name="roomsAllowed"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        error={!!touched.roomsAllowed && !!errors.roomsAllowed}
                                        helperText={touched.roomsAllowed && errors.roomsAllowed}
                                    /> */}
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        color="secondary"
                                        variant="contained"
                                        sx={{ fontSize: "15px" }}
                                    >
                                        {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                                    </Button>
                                </Stack>
                            </form>
                        )}
                    </Formik>
                </>
            </Box>
        </>
    )
}

export default AddNewAgentModalContent