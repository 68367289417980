import { RESET_ALL_HOTEL_USERS, SAVE_ALL_HOTEL_USERS } from "../types";

const INITIAL_STATE = {
    users: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 10,
    },
};

const usersReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SAVE_ALL_HOTEL_USERS:
            return {
                ...state,
                users: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_HOTEL_USERS:
            return {
                ...state,
                users: INITIAL_STATE.users,
            };

        default:
            return state;
    }
};

export default usersReducer;
