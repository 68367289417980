import React from "react";
import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const Header = ({ title, subtitle, titleVariant="h3", mb="20px" }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box mb={mb}>
      <Typography
        variant={titleVariant}
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{ mb: mb === 0 ? 0 : "5px" }}
      >
        {title}
      </Typography>
      <Typography variant="h5" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
