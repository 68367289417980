import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { BarChart } from '@mui/x-charts';

const getYearlySeriesData = (data) => {
    const series = data?.map((e) => ({
        dataKey: e.year,
        label: e.year,
    }))
    return series?.reverse();
}

const getYearlyDataset = (data) => {
    const dataset = data?.map((e) => {
        var object = e.data.reduce(
            (obj, item) => Object.assign(obj, { [item.year]: item.count }), {}
        );
        return {
            ...object,
            name: e.name,
        }
    })
    return dataset;
}

function RoomNightSalesComparisonYearly({ data, colors }) {
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const series = getYearlySeriesData(data[0]?.data);
    const dataSet = getYearlyDataset(data)

    return (
        <Box backgroundColor={colors.primary[400]} p="20px">
            <Typography variant="h5">
                ROOM NIGHT SALES COMPARISON
            </Typography>
            <Typography variant="h4" fontWeight="600" textTransform={"uppercase"}>
                WITH PREVIOUS YEARS
            </Typography>
            {data?.length ? (
                <Box
                    width={"100%"}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mt={1}
                    // ml={4}
                >
                    <BarChart
                        dataset={dataSet}
                        // colors={["yellow", "lightgreen"]}
                        xAxis={[{ scaleType: 'band', dataKey: 'name' }]}
                        series={series}
                        {...{width: smScreen ? 800 : 500, height: 300}}
                    />
                </Box>
            ) : (
                <Typography mt={15} mb={15} textAlign="center">
                    No Data Available...
                </Typography>
            )}
        </Box>
    )
}

export default RoomNightSalesComparisonYearly;