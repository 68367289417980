import React, { useState } from 'react';
import { Box, Button, Checkbox, Grid, MenuItem, Stack, Switch, Typography, useTheme } from '@mui/material';
import { ChipsInputStyled, CssTextField } from '../../components/TextField';
import { Country, State } from 'country-state-city';
import { Formik } from 'formik';
import SingleImgUploaderInput from '../../components/SingleImgUploaderInput';
import { tokens } from '../../theme';

const titles = [
    "Hotel", "Resort", "Home Stay", "Guest House", "Farm Stay", "Hostel", 
    "House Boat", "Heritage", "Service Apartment", "Ayurvedic Centre",
    "Rental", "Villa", "Boutique Hotel"
];

const greenHotels = [
    "solar panel", "waste managment recycling", "water recycling",
     "use of RO plant", "glass bottle use for water",
];

function PropertyInfo({ setPage, propInfoData, setPropInfoData, contactData, setContactData, basicInfoData, setBasicInfoData, handlePropertySubmit, isRegistration }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // eslint-disable-next-line
    const [name, setName] = useState("");
    const [chips, setChips] = useState(propInfoData?.reservation_email ? propInfoData?.reservation_email?.split(",") : []);
    const [isGreenHotel, setIsGreenHotel] = useState(propInfoData?.green_hotel?.length ? true : false);
    const allCountries = Country.getAllCountries();
    const allStates = State.getStatesOfCountry(propInfoData?.country || "IN");

    const handleValueChange = (event) => {
        let fieldname = event?.target?.name;
        let data = propInfoData;
        data[fieldname] = event?.target?.value;
        setName(event.target.value);
        setPropInfoData(data);
    }

    const handleContactChange = (event) => {
        let fieldname = event?.target?.name;
        let data = contactData;
        data[fieldname] = event?.target?.value;
        setName(event.target.value);
        setContactData(data);
    }

    return (

        <Box>
            <Formik onSubmit={handlePropertySubmit} initialValues={propInfoData}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={{ md: 4, xs: 2 }}>
                            <Grid item md={6} xs={12}>
                                <Stack spacing={2}>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Property Name:*
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="text"
                                            placeholder="Property Name"
                                            name="name"
                                            disabled={isRegistration}
                                            value={propInfoData?.name || ""}
                                            onChange={handleValueChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Property Type:*
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            select
                                            required
                                            size="small"
                                            name="type"
                                            value={propInfoData?.type || "Hotel"}
                                            onChange={handleValueChange}
                                        >
                                            {titles?.map((title, index) => (
                                                <MenuItem value={title} key={index}>
                                                    {title}
                                                </MenuItem>
                                            ))}
                                        </CssTextField>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Address:*
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="text"
                                            placeholder="Address"
                                            name="address"
                                            value={propInfoData?.address || ""}
                                            onChange={handleValueChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            City / Town:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            placeholder="City / Town"
                                            name="city"
                                            value={propInfoData?.city || ""}
                                            onChange={handleValueChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Location:*
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="text"
                                            placeholder="Location"
                                            name="location"
                                            value={propInfoData?.location || ""}
                                            onChange={handleValueChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Country:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            select
                                            required
                                            size="small"
                                            name="country"
                                            value={propInfoData?.country || "IN"}
                                            onChange={handleValueChange}
                                        >
                                            {allCountries?.map((country) => (
                                                <MenuItem value={country.isoCode} key={country.isoCode}>
                                                    {country.name}
                                                </MenuItem>
                                            ))}
                                        </CssTextField>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            State:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            select
                                            required
                                            size="small"
                                            name="state"
                                            value={propInfoData?.state || "Kerala"}
                                            onChange={handleValueChange}
                                        >
                                            <MenuItem value={""} sx={{ display: "none" }}>
                                                {"No Preference"}
                                            </MenuItem>
                                            {allStates?.map((state) => (
                                                <MenuItem value={state.name} key={state.name}>
                                                    {state.name}
                                                </MenuItem>
                                            ))}
                                        </CssTextField>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Postal Code:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            placeholder="Postal Code"
                                            name="postal_code"
                                            value={propInfoData?.postal_code || ""}
                                            onChange={handleValueChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                        <Typography mt={2} width={"40%"}>
                                            Image for email:
                                        </Typography>
                                        <Stack width={"60%"}>
                                            <SingleImgUploaderInput
                                                image={basicInfoData?.logo || ""}
                                                onChange={(img) => {
                                                    let data = basicInfoData;
                                                    data.logo = img;
                                                    setBasicInfoData(data);
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                        <Typography mt={2} width={"40%"}>
                                            Hotel image:
                                        </Typography>
                                        <Stack width={"60%"}>
                                            <SingleImgUploaderInput
                                                image={basicInfoData?.hotel_img || ""}
                                                maxSize={8000000}
                                                onChange={(img) => {
                                                    let data = basicInfoData;
                                                    data.hotel_img = img;
                                                    setBasicInfoData(data);
                                                }}
                                            />
                                            <Typography mt={1} color="#ccc">
                                                Must be less than 8 mb.
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Stack spacing={2}>
                                    {/* <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Website:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            placeholder="Website"
                                            name="website"
                                            value={propInfoData?.website || ""}
                                            onChange={handleChange}
                                        />
                                    </Stack> */}
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Finance Email:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="email"
                                            placeholder="Finance Email"
                                            name="finance_email"
                                            value={propInfoData?.finance_email || ""}
                                            onChange={handleValueChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Finance Mobile:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="number"
                                            placeholder="Finance Mobile"
                                            name="finance_mobile"
                                            value={propInfoData?.finance_mobile || ""}
                                            onChange={handleValueChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Reservation Email(s):*
                                        </Typography>
                                        {/* <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="email"
                                            placeholder="Reservation Email"
                                            name="reservation_email"
                                            value={propInfoData?.reservation_email || ""}
                                            onChange={handleValueChange}
                                        /> */}
                                        <ChipsInputStyled
                                            fullWidth
                                            required={!chips?.length}
                                            clearInputOnBlur
                                            size='small'
                                            value={chips}
                                            validate={(chipValue) => {
                                                return {
                                                    isError: chipValue.length > 1 && String(chipValue).match(/^\S+@\S+\.\S+$/) === null,
                                                    textError: 'Invalid email'
                                                }
                                            }}
                                            onChange={(newValue) => {
                                                setChips(newValue)
                                                let data = propInfoData;
                                                data.reservation_email = newValue?.toString();
                                                setName(newValue)
                                                setPropInfoData(data)
                                            }}
                                            helperText={"Type and press enter"}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Reservation Mobile:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="number"
                                            placeholder="Reservation Mobile"
                                            name="reservation_mobile"
                                            value={propInfoData?.reservation_mobile || ""}
                                            onChange={handleValueChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            C-Form Submission Email:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="email"
                                            placeholder="C-Form Submission Email"
                                            name="c_form_email"
                                            value={propInfoData?.c_form_email || ""}
                                            onChange={handleValueChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Land Phone:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="number"
                                            placeholder="Land Phone"
                                            name="land_phone"
                                            value={propInfoData?.land_phone || ""}
                                            onChange={handleValueChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Fax:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="Number"
                                            placeholder="Fax"
                                            name="fax"
                                            value={propInfoData?.fax || ""}
                                            onChange={handleValueChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                        <Typography mt={1} width={"33%"}>
                                            Green Hotel:
                                        </Typography>
                                        <Stack width={"67%"} spacing={1}>
                                            <Switch
                                                checked={isGreenHotel}
                                                onChange={(e) => setIsGreenHotel(e.target.checked)}
                                            />
                                            {isGreenHotel &&
                                                <CssTextField
                                                    fullWidth
                                                    select
                                                    required
                                                    size="small"
                                                    name="green_hotel"
                                                    value={propInfoData?.green_hotel || []}
                                                    SelectProps={{ multiple: true }}
                                                    onChange={handleValueChange}
                                                >
                                                    {greenHotels?.map((title, index) => (
                                                        <MenuItem value={title} key={index}>
                                                            <Checkbox
                                                                size="small"
                                                                sx={{ p: "0 5px" }}
                                                                checked={propInfoData?.green_hotel?.indexOf(title) > -1}
                                                            />
                                                            {title}
                                                        </MenuItem>
                                                    ))}
                                                </CssTextField>
                                            }
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Typography variant="h4" fontWeight={600} color={colors.greenAccent[600]}>
                                    Contact Person
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Stack spacing={2}>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Name:*
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="text"
                                            placeholder="Name"
                                            name="name"
                                            value={contactData?.name || ""}
                                            onChange={handleContactChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Email:*
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            value={contactData?.email || ""}
                                            onChange={handleContactChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Mobile Number:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="Number"
                                            placeholder="Mobile Number"
                                            name="mobile"
                                            value={contactData?.mobile || ""}
                                            onChange={handleContactChange}
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Stack spacing={2}>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Designation:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            placeholder="Designation"
                                            name="designation"
                                            value={contactData?.designation || ""}
                                            onChange={handleContactChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Address:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            placeholder="Address"
                                            name="address"
                                            value={contactData?.address || ""}
                                            onChange={handleContactChange}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"50%"}>
                                            Phone Number:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            size="small"
                                            type="number"
                                            placeholder="Phone Number"
                                            name="phone"
                                            value={contactData?.phone || ""}
                                            onChange={handleContactChange}
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Box>
                                    <Stack direction="row" justifyContent={isRegistration ? "space-between" : "flex-end"}>
                                        {isRegistration && (
                                            <Button size='small' variant='outlined' onClick={() => setPage(2)}>
                                                <b>Go back</b>
                                            </Button>
                                        )}
                                        <Button type="submit" size='small' variant='contained'>
                                            <b>Submit</b>
                                        </Button>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default PropertyInfo