import { Chip } from "@mui/material";

export const StatusChip = ({ status }) => {
    return (
        <Chip
            size="small"
            variant="outlined"
            label={status === "hold" ? "blocked" : status || "-"}
            color={
                ["confirmed", "booked"].includes(status) ? "success" : 
                ["cancelled", "failed"].includes(status) ? "error" : 
                status === "checkin" ? "info" : 
                "warning"
            }
        />
    )
}