import {
    SAVE_HOTEL_DETAILS,
    RESET_HOTEL_DETAILS,
    SAVE_AVAILABILITY_DATA,
    RESET_AVAILABILITY_DATA,
    SAVE_ROOMTYPE_PRICING,
    RESET_ROOMTYPE_PRICING,
} from "../types";

const INITIAL_STATE = {
    hotelDetails: {},
    availableRoomtypes: [],
    pricing: [],
};

const bookingEngineReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SAVE_HOTEL_DETAILS:
            return {
                ...state,
                hotelDetails: payload,
            };

        case RESET_HOTEL_DETAILS:
            return {
                ...state,
                hotelDetails: INITIAL_STATE.hotelDetails,
            };

        case SAVE_AVAILABILITY_DATA:
            return {
                ...state,
                availableRoomtypes: payload,
            };

        case RESET_AVAILABILITY_DATA:
            return {
                ...state,
                availableRoomtypes: INITIAL_STATE.availableRoomtypes,
            };

        case SAVE_ROOMTYPE_PRICING:
            return {
                ...state,
                pricing: payload,
            };

        case RESET_ROOMTYPE_PRICING:
            return {
                ...state,
                pricing: INITIAL_STATE.pricing,
            };

        default:
            return state;
    }
};

export default bookingEngineReducer;
