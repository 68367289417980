import React, { useState } from 'react';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { CssTextField } from '../../components/TextField';
import { addNewActivity } from '../../store/actions';
import { Formik } from 'formik';
import Header from '../../components/Header';
import ImgUploaderInput from '../../components/ImgUploaderInput';

function AddNewActivityModalContent({ dispatch, isLoading, setModal, colors }) {
    const [images, setImages] = useState([]);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { sm: 400, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
    };
    const initialValues = {
        name: "",
        description: "",
        rate: 0,
    };
    const handleFormSubmit = async (values) => {
        // console.log(values);
        await dispatch(addNewActivity({...values, img: images.length ? images[0] : "" }));
        setModal(false);
    };

    return (
        <>
            <Box sx={modalStyle}>
                <>
                    <Header title="ADD NEW ACTIVITY" titleVariant="h4" />
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>

                                <Stack spacing={2}>
                                    <CssTextField
                                        required
                                        fullWidth
                                        size="small"
                                        type="text"
                                        label="Name"
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                    />
                                    <CssTextField
                                        required
                                        fullWidth
                                        size="small"
                                        type="number"
                                        label="Rate"
                                        name="rate"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.rate}
                                        error={!!touched.rate && !!errors.rate}
                                        helperText={touched.rate && errors.rate}
                                        inputProps={{ min: 0 }}
                                    />
                                    <CssTextField
                                        fullWidth
                                        size="small"
                                        type="text"
                                        label="Description"
                                        name="description"
                                        multiline
                                        minRows={2}
                                        maxRows={2}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.description}
                                        error={!!touched.description && !!errors.description}
                                        helperText={touched.description && errors.description}
                                    />
                                    <ImgUploaderInput
                                        images={images || []}
                                        onChange={(e) => setImages(e)}
                                    />
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        color="secondary"
                                        variant="contained"
                                        sx={{ fontSize: "15px" }}
                                    >
                                        {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                                    </Button>
                                </Stack>
                            </form>
                        )}
                    </Formik>
                </>
            </Box>
        </>
    )
}

export default AddNewActivityModalContent