
import React from "react";
import { Box, Button, Grid, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { useSelector } from "react-redux";
import { ArrowBack } from "@mui/icons-material";
import Header from "../../../components/Header";

const AvailableRoomsList = ({ setPage, setProgress, selectedRooms, setSelectedRooms }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { availableRoomsData } = useSelector(
    ({
      loading,
      admin: {
        availableRooms: { list, showViewMore },
      },
    }) => ({
      isLoading: loading,
      availableRoomsData: list,
    })
  );

  const handleBackButton = () => {
    setPage(1)
    setProgress(33)
    setSelectedRooms([]);
  }

  const handleRoomSelection = (room) => {
    if (selectedRooms?.filter((e) => e.room_id === room?._id)?.length) {
      setSelectedRooms([]);
    } else {
      let data = [{
        room_number: room?.room_no,
        room_id: room?._id,
        ...room
      }]
      // let added = selectedRooms?.concat(data);
      setSelectedRooms(data);
    }
  }

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="flex-end"
        spacing={1}
        m="10px 0"
      >
        <Box title="go back" height={"40px"}>
          <IconButton type="button" onClick={handleBackButton}>
            <ArrowBack />
          </IconButton>
        </Box>
        <Header title="Available Rooms" titleVariant="h4" />
      </Stack>
      <Grid container spacing={1}>
        {availableRoomsData?.map((element, index) => (
          <Grid item lg={12} xs={12} key={index}>
            <Box
              width="100%"
              p={2}
              borderRadius={2}
              backgroundColor={colors.primary[400]}
            >
              <Grid container spacing={1}>
                {/* <Grid item lg={12} xs={12}>
                  <Typography variant="h4"><b>Room {element?.room_number}</b></Typography>
                </Grid> */}
                {element?.data.map((room, i) => (
                  <Grid item md={6} xs={12} key={i}>
                    <Stack
                      // p={1}
                      borderRadius={2}
                      overflow="hidden"
                      border={1}
                      borderColor={colors.greenAccent[600]}
                      backgroundColor={selectedRooms?.filter((e) => e.room_id === room?._id)?.length ? colors.greenAccent[800] : colors.primary[500]}
                      direction="row"
                      spacing={1}
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleRoomSelection(room)}
                    >
                      <Stack bgcolor={colors.greenAccent[600]} p={1} alignItems="center">
                        <Typography noWrap><small>Room No</small></Typography>
                        <Typography fontSize={20} fontWeight={600}>{room?.room_no}</Typography>
                      </Stack>
                      <Stack p={1}>
                        <Stack color={colors.greenAccent[500]} fontSize={20}><b>{room?.roomtype_id?.name}</b></Stack>
                        <Stack>Rs {room?.rate}</Stack>
                        {/* <Stack>Number of beds: {room?.no_of_beds}</Stack> */}
                      </Stack>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
              {!element?.data?.length ?
                <Stack p={2} alignItems={"center"}>
                  No Rooms Available for selected inputs
                </Stack>
                : null}
            </Box>
          </Grid>
        ))}
      </Grid>
      <br />
      <Grid container spacing={1}>
        <Grid item lg={6} xs={6}>
          <Button
            fullWidth
            // size="small"
            type="submit"
            color="secondary"
            variant="outlined"
            sx={{ fontSize: "15px" }}
            onClick={handleBackButton}
          >
            <b>Go Back</b>
          </Button>
        </Grid>
        <Grid item lg={6} xs={6}>
          <Button
            fullWidth
            // size="small"
            type="submit"
            color="secondary"
            variant="contained"
            sx={{ fontSize: "15px" }}
            disabled={!selectedRooms?.length}
            onClick={() => {
              setProgress(90)
              setPage(3)
            }}
          >
            <b>Continue</b>
          </Button>
        </Grid>
      </Grid>
    </Box >
  );
};

export default AvailableRoomsList;