import React, { useEffect, useRef, useState } from "react";
import {
    Box, Button, IconButton, InputAdornment, Typography,
    MenuItem, Stack, Table, useTheme, TableRow, TextField, Checkbox,
    TableBody, TableCell, TableContainer, TableHead, CircularProgress,
} from "@mui/material";
import {
    ArrowBack,
    ArrowBackIosNew, ArrowForwardIos, CalendarMonth, ExpandMore, KeyboardArrowRight, Search
} from "@mui/icons-material";
import { authorizedPost, listAllAvailabilityReports, toggleLoading } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import moment from "moment";
import "./style.css"
import { toast } from "react-toastify";
// import MonthlyReports from "./monthlyReports";
// import CalendarPopover from "../../components/calendarPopover";
import { filterGroupBookingDatas } from "../../utils";
import { ADD_OR_UPDATE_BOOKED_ROOMS } from "../../graphql";
import ButtonWithConfirmModal from "../../components/ButtonWithConfirmModal";
import { allMonths } from "../../data";

const years = (num = 10) => {
    const year = new Date(moment().add(5, "year")).getFullYear();
    return Array.from({ length: num }, (v, i) => year - num + i + 1);
}

const WeeklyAvailabilityReports = () => {
    const { bookingId } = useParams();
    // const booking_id = localStorage.getItem("booking_id");
    const d = new Date();
    const [dateNum, setDateNum] = useState(localStorage.getItem("dateNum") || d.getMonth());
    const [year, setYear] = useState(localStorage.getItem("year") || d.getFullYear());
    const theme = useTheme();
    const startInputRef = useRef();
    const endInputRef = useRef();
    const navigate = useNavigate();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const [type, setType] = useState(localStorage.getItem("calType") || "week");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [expanded, setExpanded] = useState(JSON.parse(localStorage.getItem("expanded")) || []);
    const [selectedRooms, setSelectedRooms] = useState([]);
    const [selectedRoomsData, setSelectedRoomsData] = useState([]);
    const [selectedRowNumbers, setSelectedRowNumbers] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);

    const { availReports, bookingDetails } = useSelector(({ calendar: { availReports }, booking: { bookingDetails } }) => ({
        availReports,
        bookingDetails,
    }));

    // console.log(bookingDetails);
    useEffect(() => {
        document.title = 'Calendar';
        setStartDate(moment.unix(bookingDetails.checkin_date).subtract(1, "day").format("YYYY-MM-DD"));
        setEndDate(moment.unix(bookingDetails.checkout_date).format("YYYY-MM-DD"));
        let diff = moment.unix(bookingDetails.checkout_date).diff(moment.unix(bookingDetails.checkin_date), "days");
        if(diff < 7) {
            setEndDate(moment.unix(bookingDetails.checkout_date).add(6 - diff, "days").format("YYYY-MM-DD"));
        }

        if(startDate && endDate) {
            if (type === "week") {
                dataFetch();
            } else {
                handleMonthlyDataFetch(dateNum, year, true);
            }
        }
        // eslint-disable-next-line
    }, [dispatch, endDate]);

    useEffect(() => {
        // if (!booking_id) {
        //     navigate(-1)
        // }
        autoSelect();
    }, [availReports]);

    useEffect(() => {
        if (!expanded?.length) {
            let roomtypeNames = availReports?.map(a => a.roomtype._id);
            setExpanded(roomtypeNames);
            localStorage.setItem("expanded", JSON.stringify(roomtypeNames));
        }
        // eslint-disable-next-line
    }, [availReports]);

    const autoSelect = () => {
        let blockedRooms = availReports?.flatMap(el => el.rooms.filter(j => !j.available && j.booking_id === bookingDetails._id));
        let blockedRoomsData = blockedRooms.map((room) => ({
            room_id: room?._id,
            room_no: room?.room_no,
            date: room?.date,
        }))

        setSelectedRooms(blockedRooms);
        setSelectedRoomsData(blockedRoomsData);
    }

    const dataFetch = async () => {
        // dispatch(resetAllRoomsReports());
        await dispatch(listAllAvailabilityReports({
            startDate: moment(startDate, "YYYY-MM-DD").format("DD-MMM-YYYY"),
            endDate: moment(endDate, "YYYY-MM-DD").format("DD-MMM-YYYY"),
            // noLoading: true,
        }));
        autoSelect()
    }

    const handleMonthlyDataFetch = async (m = dateNum, y = year, noLoading = false) => {
        let start = `01-${allMonths[m]}-${y}`;
        let end = moment(start, 'DD-MMM-YYYY').endOf('month').format('DD-MMM-YYYY');
        // dispatch(resetAllRoomsReports());
        await dispatch(listAllAvailabilityReports({ startDate: start, endDate: end, noLoading: noLoading }));
        autoSelect();
    }

    const handleExpandBtn = (value) => {
        var data = expanded;
        if (data.includes(value)) {
            data = data.filter(item => item !== value);
        } else {
            data = [].concat(data, value);
        }
        setExpanded(data)
    }

    const handleFilterBtn = (start = startDate, end = endDate) => {
        if (end > start) {
            // setSelectedRooms([])
            // setSelectedRoomsData([])
            // setSelection(false)
            // dispatch(resetAllRoomsReports());
            dispatch(listAllAvailabilityReports({
                startDate: moment(start, "YYYY-MM-DD").format("DD-MMM-YYYY"),
                endDate: moment(end, "YYYY-MM-DD").format("DD-MMM-YYYY")
            }));
        } else {
            toast.warning("Start date cannot be greater than or equal to end date!")
        }
    }

    const handleRoomSelection = (room, roominfo) => {
        let data = selectedRooms;
        let info = selectedRoomsData;
        if (data?.includes(room)) {
            let index = data?.indexOf(room);
            data[index] = undefined;
            info[index] = undefined;
            data = data.filter(item => item !== undefined);
            info = info.filter(item => item !== undefined);
        } else {
            data = [].concat(data, room);
            info = [].concat(info, roominfo);
        }
        setSelectedRooms(data);
        setSelectedRoomsData(info);
    }

    const handleContinueBtn = async () => {
        if (selectedRoomsData.length) {
            dispatch(toggleLoading(true));
            const sortedData = selectedRoomsData.sort((a, b) => new Date(a.date) - new Date(b.date));
            let rooms = filterGroupBookingDatas(sortedData);
            rooms = rooms.map(({ room_id, checkin_date, checkout_date }) => ({
                room_id, 
                checkin_date, 
                checkout_date,
                no_of_adults: 2,
                no_of_childs: 0,
            }))
            
            await authorizedPost(ADD_OR_UPDATE_BOOKED_ROOMS, {
                bookingId: bookingId,
                rooms: rooms,
            })
            dispatch(toggleLoading(false));
            // localStorage.removeItem("booking_id")
            navigate("/calendar");
        }
    }

    const handleRowSelection = (item, room_id) => {
        let filtered = [];
        if(selectedRowNumbers.includes(room_id)){
            setSelectedRowNumbers(selectedRowNumbers.filter((num) => num !== room_id))
            filtered = selectedRooms.filter(j => j._id !== room_id);
        }else{
            setSelectedRowNumbers([...selectedRowNumbers, room_id]);
            filtered = item.rooms.filter(e => e._id === room_id && e.available && !moment(e.date, "DD-MMM-YYYY").isBefore(moment().startOf("day")));
            filtered = [...selectedRooms, ...filtered];
        }
        filtered = [...new Set(filtered)];
        setSelectedRooms(filtered);

        let info = filtered.map((room, idx) => ({ 
            room_id: room?._id, 
            room_no: room?.room_no, 
            roomtype: item?.roomtype?.name, 
            roomtype_id: item?.roomtype?._id, 
            date: room?.date,
            rate: item?.data[0]?.rate,
            index: idx,
        }))
        setSelectedRoomsData(info);
    }

    const handleColumnSelection = (date) => {
        let filtered = [];
        if(selectedDates.includes(date)){
            setSelectedDates(selectedDates.filter((val) => val !== date))
            filtered = selectedRooms.filter(j => j.date !== date);
        }else{
            setSelectedDates([...selectedDates, date]);
            filtered = availReports?.flatMap(el => el.rooms.filter(j => j.available && j.date === date && !moment(j.date, "DD-MMM-YYYY").isBefore(moment().startOf("day"))));
            filtered = [...selectedRooms, ...filtered];
        }
        filtered = [...new Set(filtered)];
        setSelectedRooms(filtered);

        let info = filtered.map((room, idx) => {
            let item = availReports.find(obj => obj.rooms.some(e => e._id === room._id));
            return {
                room_id: room?._id,
                room_no: room?.room_no,
                roomtype: item?.roomtype?.name,
                roomtype_id: item?.roomtype?._id,
                date: room?.date,
                rate: item?.data[0]?.rate,
                index: idx,
            }
        })
        setSelectedRoomsData(info);
    }

    // const ColorCode = ({ size = 15, color, label }) => (
    //     <Grid item>
    //         <Stack direction="row" spacing={1} alignItems="center">
    //             <Box width={size} height={size} bgcolor={color} />
    //             <Typography>{label}</Typography>
    //         </Stack>
    //     </Grid>
    // )

    return (
        <Box m="20px" mb={15}>
            {/* <Container disableGutters> */}
            <Stack direction={{ sm: "row", xs: "column" }} justifyContent="space-between">
                <Header title="UNBLOCK BOOKINGS" subtitle="Unblock bookings Page" />
                {/* <Stack alignItems="end">
                    <Grid container mb={1} spacing={1} justifyContent={{ sm: "flex-end", xs: "flex-start" }}>
                        <ColorCode color={"#4BB543"} label={"Available"} />
                        <ColorCode color={"#d32f2f"} label={"Unavailable"}/>
                        <ColorCode color={"#ff9800"} label={"Booked"}/>
                            <ColorCode color={"#ffe600"} label={"PHS Booking"}/>
                        <ColorCode color={"#525252"} label={"Blocked"} />
                    </Grid>
                    <Grid container spacing={1} justifyContent={{ sm: "flex-end", xs: "flex-start" }}>
                        <ColorCode size={10} color={"#8cf2ff"} label={"Direct"} />
                        <ColorCode size={10} color={"#aaabff"} label={"Agent"} />
                        <ColorCode size={10} color={"#dbdbdb"} label={"OTA"} />
                    </Grid>
                </Stack> */}
            </Stack>
            <Stack width={"100%"} ml={-2.5} mb={-2.5} bgcolor={colors.primary[400]} p={2} direction="row" spacing={1} position={"fixed"} bottom={20} zIndex={1} boxShadow={"1px -4px 35px -4px rgba(0,0,0,0.75)"}>
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        size="small"
                        variant="outlined"
                        // endIcon={<ArrowForward />}
                        onClick={() => navigate(-1)}
                        disabled={localStorage?.getItem("isHotsoft") === "true"}
                    >
                        <ArrowBack fontSize="small" />
                    </Button>
                </Box>
                {/* {bookingDetails.status !== "blocked" ? (
                <Button
                    size="small"
                    variant="outlined"
                    endIcon={<ArrowForward />}
                    // onClick={() => handleContinueBtn("goToUpdation")}
                >
                    <b>Go to details updation page</b>
                </Button>
                ) : null} */}
                <ButtonWithConfirmModal
                    BtnLabel="Update rooms now"
                    text="Are you sure to update selected rooms?"
                    disabled={!selectedRoomsData?.length}
                    action={() => handleContinueBtn()}
                />
            </Stack>
            <Stack
                spacing={2}
                mt={2}
                direction={{ md: "row", xs: "column" }}
                alignItems={{ md: "flex-end", xs: "normal" }}
                justifyContent={"space-between"}
            >
                <Button size="small" variant="outlined" onClick={() => navigate(-1)}>
                    <ArrowBack fontSize="small" />
                </Button>
                {type === "week" ? (
                    <Stack
                        direction={"row"}
                        justifyContent={{ md: "flex-end", xs: "flex-start" }}
                        spacing={1}
                    >
                        <IconButton onClick={() => {
                            let start = moment(startDate, "YYYY-MM-DD").subtract(7, "days").format("YYYY-MM-DD")
                            let end = startDate
                            setStartDate(start)
                            setEndDate(end)
                            localStorage.setItem("startDate", start)
                            localStorage.setItem("endDate", end)
                            handleFilterBtn(start, end)
                        }}>
                            <ArrowBackIosNew />
                        </IconButton>
                        <TextField
                            fullWidth
                            size="small"
                            type="date"
                            label="Start Date"
                            placeholder="Start Date"
                            sx={{ width: 170 }}
                            inputRef={startInputRef}
                            onClick={() => startInputRef.current.showPicker()}
                            // onKeyDown={(event) => event.preventDefault()}
                            // onFocus={() => startInputRef.current.showPicker()} 
                            value={startDate || ""}
                            onChange={(newValue) => {
                                setStartDate(newValue.target.value)
                                localStorage.setItem("startDate", newValue.target.value)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CalendarMonth sx={{ cursor: "pointer" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            fullWidth
                            size="small"
                            type="date"
                            label="End Date"
                            placeholder="End Date"
                            sx={{ width: 170 }}
                            inputRef={endInputRef}
                            onClick={() => endInputRef.current.showPicker()}
                            inputProps={{ min: startDate }}
                            value={endDate || ""}
                            onChange={(newValue) => {
                                setEndDate(newValue.target.value)
                                localStorage.setItem("endDate", newValue.target.value)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CalendarMonth sx={{ cursor: "pointer" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={() => handleFilterBtn()}
                            // disabled={startDate < moment().format("YYYY-MM-DD")}
                        >
                            <Search />
                        </Button>
                        <IconButton onClick={() => {
                            let start = endDate
                            let end = moment(endDate, "YYYY-MM-DD").add(7, "days").format("YYYY-MM-DD")
                            setStartDate(start)
                            setEndDate(end)
                            localStorage.setItem("startDate", start)
                            localStorage.setItem("endDate", end)
                            handleFilterBtn(start, end)
                        }}>
                            <ArrowForwardIos />
                        </IconButton>
                    </Stack>
                ) : (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <IconButton onClick={() => {
                            let m = dateNum > 0 ? parseInt(dateNum) - 1 : 11;
                            let y = dateNum > 0 ? year : parseInt(year) - 1;
                            handleMonthlyDataFetch(m, y)
                            setDateNum(m)
                            setYear(y)
                            localStorage.setItem("dateNum", m)
                            localStorage.setItem("year", y)
                        }}>
                            <ArrowBackIosNew />
                        </IconButton>
                        <TextField
                            select
                            size="small"
                            variant="standard"
                            value={dateNum === "01" ? "1" : dateNum}
                            onChange={(e) => {
                                // handleMonthlyDataFetch(e.target.value)
                                setDateNum(e.target.value)
                                localStorage.setItem("dateNum", e.target.value)
                            }}
                        >
                            {allMonths?.map((m, i) => (
                                <MenuItem value={i} key={m}>
                                    {m}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            select
                            size="small"
                            variant="standard"
                            value={year}
                            onChange={(e) => {
                                // handleMonthlyDataFetch(dateNum, e.target.value)
                                setYear(e.target.value)
                                localStorage.setItem("year", e.target.value)
                            }}
                        >
                            {years()?.map((y) => (
                                <MenuItem value={y} key={y}>
                                    {y}
                                </MenuItem>
                            ))}
                        </TextField>
                        {/* <Typography fontSize={20} width={50}>
                                {year}
                        </Typography> */}
                        <IconButton
                            color="primary"
                            sx={{ borderRadius: 1 }}
                            onClick={() => handleMonthlyDataFetch(dateNum, year)}
                        >
                            {localStorage.getItem("loading2") === "true" ? <CircularProgress size={24} /> : <Search />}
                        </IconButton>
                        <IconButton onClick={() => {
                            let m = dateNum < 11 ? parseInt(dateNum) + 1 : 0;
                            let y = dateNum < 11 ? year : parseInt(year) + 1;
                            handleMonthlyDataFetch(m, y)
                            setDateNum(m)
                            setYear(y)
                            localStorage.setItem("dateNum", m)
                            localStorage.setItem("year", y)
                        }}>
                            <ArrowForwardIos />
                        </IconButton>
                    </Stack>
                )}
            </Stack>


            {availReports?.length ?
                <div className="reports" style={{ backgroundColor: colors.primary[400] }}>
                    <TableContainer>
                        <Box component={Table} alignItems="center">
                            <TableHead>
                                <TableRow>
                                    <th style={{ backgroundColor: colors.blueAccent[700] }}>Date</th>
                                    {availReports[0]?.data?.map((item, index) => {
                                        let date = new Date(item.date);
                                        return (
                                            <th
                                                key={index}
                                                title={item?.date}
                                                style={{
                                                    color: moment(date).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY") ? "yellow" : "",
                                                    backgroundColor: date.getDay() === 6 || date.getDay() === 0 ? colors.redAccent[700] : colors.blueAccent[700],
                                                }}
                                            >
                                                {type === "week" ? (
                                                    <Typography width={100} noWrap>
                                                        <b>{item?.date}</b>
                                                    </Typography>
                                                ) : (
                                                    <Stack textAlign={"center"}>
                                                        <Typography fontSize={10}>{allMonths[date.getMonth()]}</Typography>
                                                        <Typography><b>{date.getDate()}</b></Typography>
                                                        <Typography fontSize={10}>{date.getFullYear()}</Typography>
                                                    </Stack>
                                                )}
                                                <Box width={20}>
                                                    <Checkbox
                                                        size="small"
                                                        checked={selectedDates.includes(item?.date)}
                                                        sx={{ p: 0 }}
                                                        onClick={() => handleColumnSelection(item?.date)}
                                                        disabled={moment(item.date, "DD-MMM-YYYY").isBefore(moment().startOf("day"))}
                                                    />
                                                </Box>
                                            </th>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            {availReports?.map((item, index) => {
                                return (
                                    <TableBody key={index}>
                                        <TableRow>
                                            <TableCell
                                                className="typename"
                                                style={{ backgroundColor: "#2e7c67", color: "#fff" }}
                                                onClick={() => handleExpandBtn(item?.roomtype?._id)}
                                                title={item?.roomtype?.name}
                                            >
                                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                    <Typography fontWeight={600} width={140} noWrap textTransform="capitalize">
                                                        {item?.roomtype?.name}
                                                    </Typography>
                                                    <IconButton size="small">
                                                        {expanded.includes(item?.roomtype?._id) ? <ExpandMore /> : <KeyboardArrowRight />}
                                                    </IconButton>
                                                </Stack>
                                            </TableCell>

                                            {item?.data?.map((e, i) => (
                                                <TableCell key={i}>
                                                    <Stack direction={type === "week" ? "row" : "column"} spacing={type === "week" ? 3 : 1} textAlign={"center"}>
                                                        <div
                                                            className="avl-num"
                                                            style={{ color: e?.available_rooms < 1 ? "#d32f2f" : "" }}
                                                        >
                                                            <b>{e?.available_rooms}</b>
                                                        </div>
                                                        {e?.booked_rooms ? <div className="bkd-num"><b>{e?.booked_rooms}</b></div> : null}
                                                    </Stack>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        {expanded?.includes(item?.roomtype?._id) &&
                                            <>
                                                {item?.room_nums?.map(({ _id, room_no }) => (
                                                    <TableRow key={_id} style={{ backgroundColor: colors.primary[500] }}>
                                                        <TableCell title={room_no} style={{ backgroundColor: colors.primary[800] }}>
                                                            <Stack direction="row" alignItems="center">
                                                                <Typography fontWeight={600} width={150} noWrap textTransform="capitalize">
                                                                    {room_no}
                                                                </Typography>
                                                                <Box width={20}>
                                                                    <Checkbox
                                                                        size="small"
                                                                        checked={selectedRowNumbers.includes(_id)}
                                                                        sx={{ p: 0 }}
                                                                        onClick={() => handleRowSelection(item, _id)}
                                                                    />
                                                                </Box>
                                                            </Stack>
                                                        </TableCell>
                                                        {item?.rooms?.map((room, roomIndex) => {
                                                            if (room?._id === _id) {
                                                                let date = new Date(room?.date);
                                                                if (room?.available) {
                                                                    return (
                                                                        <TableCell
                                                                            key={roomIndex}
                                                                            width={"200px"}
                                                                            className="avl"
                                                                            style={{
                                                                                cursor: !moment(date).isBefore(moment().startOf("day")) ? "pointer" : "not-allowed",
                                                                                backgroundColor: moment(date).isBefore(moment().startOf("day")) ? "grey" : selectedRooms.includes(room) ? "green" : "",
                                                                            }}
                                                                            onClick={!moment(date).isBefore(moment().startOf("day")) ? () => {
                                                                                handleRoomSelection(room, {
                                                                                    room_id: room?._id,
                                                                                    room_no: room?.room_no,
                                                                                    date: room?.date,
                                                                                    // roomtype: item?.roomtype?.name,
                                                                                    // roomtype_id: item?.roomtype?._id,
                                                                                    // rate: availReports[index]?.data[0]?.rate,
                                                                                    // index: roomIndex,
                                                                                })
                                                                            } : null}
                                                                        >
                                                                            <Box width={20}>
                                                                                <Checkbox
                                                                                    size="small"
                                                                                    checked={selectedRooms.includes(room)}
                                                                                    sx={{ p: 0, display: !moment(date).isBefore(moment().startOf("day")) ? "" : "none" }}
                                                                                />
                                                                            </Box>
                                                                        </TableCell>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <TableCell
                                                                            key={roomIndex}
                                                                            width={"200px"}
                                                                            onClick={bookingDetails._id === room.booking_id && !moment(date).isBefore(moment().startOf("day")) ? () => {
                                                                                handleRoomSelection(room, {
                                                                                    room_id: room?._id,
                                                                                    room_no: room?.room_no,
                                                                                    date: room?.date,
                                                                                    // roomtype: item?.roomtype?.name,
                                                                                    // roomtype_id: item?.roomtype?._id,
                                                                                    // rate: availReports[index]?.data[0]?.rate,
                                                                                    // index: roomIndex,
                                                                                })
                                                                            } : null}
                                                                            className={bookingDetails._id === room.booking_id ? "hold" : "unblock-hold"}
                                                                            style={{ backgroundColor: selectedRooms.includes(room) ? "#525252" : "" }}
                                                                        >
                                                                            <Box width={20}>
                                                                                <Checkbox
                                                                                    size="small"
                                                                                    checked={selectedRooms.includes(room)}
                                                                                    sx={{ p: 0, display: bookingDetails._id === room.booking_id ? "" : "none" }}
                                                                                />
                                                                            </Box>
                                                                        </TableCell>
                                                                    )
                                                                }
                                                            } else {
                                                                return null;
                                                            }
                                                        })}
                                                    </TableRow>
                                                ))}
                                            </>}
                                    </TableBody>
                                )
                            })}
                        </Box>
                    </TableContainer>
                </div>
                :
                <Stack
                    textAlign="center"
                    height="200px"
                    justifyContent="center"
                >
                    Loading...
                </Stack>
            }
        </Box>
    );
};

export default WeeklyAvailabilityReports;
