// /* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, MenuItem, Modal, Stack, Typography } from '@mui/material';
import { listAllRooms, resetAllRoomsList, updateConnectedRooms } from '../../../store/actions/roomsActions';
import { CssTextField } from '../../../components/TextField';
import { useSelector } from 'react-redux';
import { tableStyle } from '../../../components';
import { DataGrid } from '@mui/x-data-grid';
import { Add } from '@mui/icons-material';
import Header from '../../../components/Header';

function ConnectedRooms({ connectedRoomsList = [], roomId, dispatch, colors }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [selectedRooms, setSelectedRooms] = useState([]);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { sm: 400, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
      };

    const { isLoading, roomsList } = useSelector(
        ({
            loading,
            admin: { rooms: { list },
            },
        }) => ({
            isLoading: loading,
            roomsList: list,
        })
    );

    useEffect(() => {
        setSelectedRooms(connectedRoomsList?.map((e) => e?._id))
        dispatch(resetAllRoomsList());
        dispatch(listAllRooms( "", true ));
        // eslint-disable-next-line
    },[open])

    const columns = [
        {
            field: "room_no",
            headerName: "Room Number",
            cellClassName: "name-column--cell",
            width: 150,
        },
        {
            field: "roomType",
            headerName: "Room Type",
            width: 200,
            cellClassName: "name-column--cell",
            renderCell: ({ row: { roomtype_id } }) => roomtype_id?.name ?? "-",
        },
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        // let data = connectedRoomsList?.map((e) => e?._id)
        // data = selectedRooms?.filter((e) => !data.includes(e))
        dispatch(updateConnectedRooms({
            id: roomId,
            connectedRooms: selectedRooms,
        }))
        handleClose();
    }
  
    return (
        <>
            <Box mb={15}>
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"end"}
                    m="20px 0"
                >
                    <Typography variant='h4' fontWeight="bold">
                        CONNECTED ROOMS
                    </Typography>
                    <Box>
                        <Button
                            sx={{
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                            }}
                            onClick={handleOpen}
                        >
                            <Add sx={{ mr: "10px" }} />
                            CONNECT ROOM(s)
                        </Button>
                    </Box>
                </Box>
                <Box
                    m="8px 0 0 0"
                    height="350px"
                    sx={tableStyle(colors)}
                >
                    <DataGrid
                        rows={connectedRoomsList}
                        columns={columns}
                        // components={{ Footer: CustomFooter }}
                        getRowId={(row) => row?._id}
                    />
                </Box>

                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <Box sx={modalStyle}>
                        <Header title="CONNECT ROOM(s)" titleVariant="h4" />
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={2}>
                                <CssTextField
                                    select
                                    fullWidth
                                    size="small"
                                    label="Select rooms"
                                    value={selectedRooms || []}
                                    SelectProps={{ multiple: true }}
                                    onChange={(e) => setSelectedRooms(e.target.value)}
                                >
                                    {roomsList?.map((e, i) => (
                                        <MenuItem 
                                            key={i}
                                            value={e._id} 
                                            disabled={e._id === roomId} 
                                            sx={{display: e._id === roomId ? "none" : ""}}
                                        >
                                            <Checkbox sx={{ p: "0 5px" }} size="small" checked={selectedRooms?.indexOf(e._id) > -1} />
                                            {e.room_no} - {e?.roomtype_id?.name}
                                        </MenuItem>
                                    ))}
                                </CssTextField>
                                <Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                    sx={{ fontSize: "15px" }}
                                >
                                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                                </Button>
                            </Stack>
                        </form>
                    </Box>
                </Modal>
            </Box>
        </>
    )
}

export default ConnectedRooms;