/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { MyProSidebarProvider } from "../views/global/sidebar/sidebarContext";
import Loader from "../components/Loader";
import Topbar from "../views/global/Topbar";

import { adminRoutes } from "../routes";
import { isLoggedIn } from "../utils";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { LibraryBooks, CalendarTodayOutlined, TableView, SubdirectoryArrowLeft, Home } from "@mui/icons-material";
// import { getRegistrationStatus } from "../store/actions";

const actions = [
  { icon: <SubdirectoryArrowLeft />, name: 'Go back', to: -1 },
  { icon: <LibraryBooks />, name: '+ New booking', to: "/bookings" },
  { icon: <TableView />, name: 'Calendar', to: "/calendar" },
  { icon: <Home />, name: '+ Create room types', to: "/dashboard" },
  // { icon: <CalendarTodayOutlined />, name: 'Check-in details', to: "/checkin-details" },
];

function AdminLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  // const dispatch = useDispatch();
  // const [regStatus, setRegStatus] = useState(false);

  const { isAuthenticated, loading } = useSelector(({ auth: { token }, loading }) => ({
    isAuthenticated: isLoggedIn(token),
    loading,
  }));

  useEffect(() => {
    if (location.pathname === '/') {
      if (isAuthenticated){
        navigate('/dashboard');
      }
      else {
        navigate('/login');
      }
    }
    // fetchStatus();
  }, [navigate, location.pathname, isAuthenticated])

  // const fetchStatus = async() => {
  //   let reg = await dispatch(getRegistrationStatus());
  //   setRegStatus(reg);
  // }

  return (
    <>
      <Loader visible={loading} />
      <MyProSidebarProvider>
        <div style={{ height: "100%", width: "100%" }}>
          <main>
            <Topbar />
            {adminRoutes.map((prop, key) => {
              if (prop.path && isAuthenticated) {
                return <Routes key={key}> <Route path={prop.path} element={prop.component}/> </Routes>;
              } else {
                return <Navigate to={"/login"} key={key} />;
              }
            })}
          </main>
        </div>
      </MyProSidebarProvider>
      {location.pathname !== "/registration" && 
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'fixed', bottom: 30, right: 25 }}
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => navigate(action.to)}
            sx={{p: 3, bgcolor: "#fff", color: "#000", '&:hover': {
              backgroundColor: "#70d8bd",
            },}}
          />
        ))}
      </SpeedDial>}
    </>
  );
}

export default AdminLayout;
