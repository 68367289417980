/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Button, Checkbox, MenuItem, Modal, Stack, Box,
  useMediaQuery, CircularProgress, Typography, useTheme,
} from "@mui/material";
import {
  listAllFacilities, listAllHighlights, listAllRooms, listAllRoomTypes,
  listRoomDetails, resetAllRoomsList, resetRoomDetails, addNewRoom, activateRoom,
} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import { Add, ArrowForward } from "@mui/icons-material";
import { CssTextField } from "../../components/TextField";
import { useNavigate } from "react-router-dom";
import { tableStyle } from "../../components";
import { tokens } from "../../theme";
import { Formik } from "formik";
import Header from "../../components/Header";
import AvailabilityUpdation from "../../components/AvailabilityUpdation";
import * as yup from "yup";

const Rooms = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selections, setSelections] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [selectedRoomType, setSelectedRoomType] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { isLoading, roomsList, highlights, facilities, roomTypes, showViewMore } = useSelector(
    ({
      loading,
      admin: { rooms: { list, showViewMore }, highlights, facilities, roomTypes,
      },
    }) => ({
      isLoading: loading,
      roomsList: list,
      showViewMore,
      highlights,
      facilities,
      roomTypes
    })
  );

  useEffect(() => {
    document.title = 'Rooms';
    dispatch(resetAllRoomsList());
    fetchMoreData();
    dispatch(listAllRoomTypes({ isForDropdown: true }));
    dispatch(listAllHighlights());
    dispatch(listAllFacilities());
  }, [dispatch]);

  const fetchMoreData = () => {
    dispatch(listAllRooms());
  }

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Stack width="100%" alignItems="center">
          {showViewMore ? <Button fullWidth onClick={fetchMoreData}>
            <b>view more</b>
          </Button> : <Typography variant="h6">All datas listed</Typography>}
        </Stack>
      </GridFooterContainer>
    );
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: smScreen ? 400 : "90%",
    bgcolor: colors.primary[400],
    outline: "none",
    boxShadow: 24,
    p: 2,
  };

  const handleFormSubmit = async (values) => {
    values.highlights = selections;
    values.facilities = selectedFacilities;
    values.roomtypeId = selectedRoomType;
    console.log(values);
    await dispatch(addNewRoom(values));
    setSelections([]);
    handleClose();
  };
  const initialValues = {
    roomNo: "",
    description: "",
    roomSize: "",
  };
  const checkoutSchema = yup.object().shape({
    roomNo: yup.string().required("Required"),
  });

  const columns = [
    {
      field: "room_no",
      headerName: "Room Number",
      cellClassName: "name-column--cell",
      width: 150,
    },
    {
      field: "roomType",
      headerName: "Room Type",
      width: 150,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { roomtype_id } }) => roomtype_id?.name ?? "-",
    },
    { field: "description", headerName: "Description", width: 150 },
    { field: "room_size", headerName: "Room Size", width: 150 },
    {
      field: "is_active",
      headerName: "Availability",
      width: 100,
      renderCell: ({ row: { is_active, _id } }) => {
        return (
          <AvailabilityUpdation
            isActive={is_active}
            action={() => dispatch(activateRoom({ id: _id }))}
          />
        );
      },
    },
    {
      field: "_id",
      headerName: "View details",
      width: 150,
      renderCell: ({ row: { _id } }) => {
        return (
          <Button
            size="small"
            variant="contained"
            onClick={async () => {
              await dispatch(resetRoomDetails());
              await dispatch(listRoomDetails({ id: _id }));
              navigate(`/room-details/${_id}`);
            }}
            endIcon={<ArrowForward fontSize="small" />}
            sx={{ fontWeight: 600 }}
          >
            View
          </Button>
        )
      },
    },
  ];

  return (
    <Box m="20px" mb={15}>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={smScreen ? "center" : "start"}
        m="20px 0"
      >
        <Header title="ROOMS" subtitle="welcome to Rooms" />
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={handleOpen}
          >
            <Add sx={{ mr: "10px" }} />
            Add new room
          </Button>
        </Box>
      </Box>
      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={tableStyle(colors)}
      >
        <DataGrid
          rows={roomsList}
          columns={columns}
          components={{ Footer: CustomFooter }}
          getRowId={(row) => row?._id}
        />
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={modalStyle}>
          <Header title="ADD NEW ROOM" titleVariant="h4" />
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <CssTextField
                    fullWidth
                    size="small"
                    type="text"
                    label="Room Number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.roomNo}
                    name="roomNo"
                    required
                    error={!!touched.roomNo && !!errors.roomNo}
                    helperText={touched.roomNo && errors.roomNo}
                  />
                  <CssTextField
                    fullWidth
                    select
                    required
                    size="small"
                    label="Room Type"
                    value={selectedRoomType}
                    onChange={(e) => setSelectedRoomType(e.target.value)}
                  >
                    {roomTypes?.list?.map((e, i) => (
                      <MenuItem value={e._id} key={i}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                  <CssTextField
                    fullWidth
                    size="small"
                    type="text"
                    label="Description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    name="description"
                    error={!!touched.description && !!errors.description}
                    helperText={touched.description && errors.description}
                  />
                  <CssTextField
                    fullWidth
                    size="small"
                    type="text"
                    label="Room size"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.roomSize}
                    name="roomSize"
                    error={!!touched.roomSize && !!errors.roomSize}
                    helperText={touched.roomSize && errors.roomSize}
                  />
                  <CssTextField
                    fullWidth
                    select
                    size="small"
                    label="Highlights"
                    value={selections}
                    SelectProps={{ multiple: true }}
                    onChange={(e) => setSelections(e.target.value)}
                  >
                    {highlights?.list?.map((e, i) => (
                      <MenuItem value={e._id} key={i}>
                        <Checkbox sx={{ p: "0 5px" }} checked={selections.indexOf(e._id) > -1} />
                        {e.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                  <CssTextField
                    fullWidth
                    select
                    size="small"
                    label="Facilities"
                    value={selectedFacilities}
                    SelectProps={{ multiple: true }}
                    onChange={(e) => setSelectedFacilities(e.target.value)}
                  >
                    {facilities?.list?.map((e, i) => (
                      <MenuItem value={e._id} key={i}>
                        <Checkbox sx={{ p: "0 5px" }} checked={selectedFacilities.indexOf(e._id) > -1} />
                        {e.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ fontSize: "15px" }}
                  >
                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                  </Button>
                </Stack>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </Box>
  );
};

export default Rooms;
