import React, { useState } from "react";
import { CssTextField } from "./TextField";
import { Box, Button, InputAdornment, Typography } from "@mui/material";
import { Image, Upload } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { uploadFile } from "../store/actions";
import { toast } from "react-toastify";
import { getBase64 } from "../utils/fileTobase64";

const allowedExtensions = ["jpg", "jpeg", "png", "svg"];

function SingleImgUploaderInput({ image, onChange, maxSize }) {
    const dispatch = useDispatch();
    const [base64Img, setBase64Img] = useState("");
    const [inputKey, setInputKey] = useState("");
    // const [imgModal, setImgModal] = useState(false);
    const [img, setImg] = useState(image);

    // const modalStyle = {
    //     position: 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: { sm: "fit-content", xs: "90%" },
    //     maxHeight: "90vh",
    //     bgcolor: colors.primary[400],
    //     outline: "none",
    //     boxShadow: 24,
    //     p: 2,
    // };

    const handleImageChange = async (e) => {
        if (e.target.files) {
            const inputFile = e.target.files[0];
            if(maxSize && inputFile?.size > maxSize){
                return toast.error('Maximum file size exceeded');
            }
            const fileExtension = inputFile?.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
              return toast.error("Please input a valid file type");
            }
    
            let data = await getBase64(e.target.files);
            // console.log(data);
            setBase64Img(data);
        }
    };

    // const handleImageDeletion = async () => {
    //     setImgModal(false);
    //     setImg("");
    //     onChange(image)
    // }

    const handleUploadFileBtn = async () => {
        setImg("");
        let imgUrl = await dispatch(uploadFile({ file: base64Img, type: "" }));
        // let imgs = image;
        // imgs.push(imgUrl);
        setImg(imgUrl);
        onChange(imgUrl);
        
        setInputKey(Math.random());
        setBase64Img("");
    };

    return (
        <>
            <CssTextField
                fullWidth
                // size="small"
                type="file"
                label="Image"
                placeholder="Input Image"
                key={inputKey || '' }
                // accept="image/*"
                onChange={handleImageChange}
                // value={imgPath}
                name="image"
                inputProps={{ accept: "image/*" }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="end">
                            <Image />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button
                                title="Upload"
                                size="small"
                                variant="contained"
                                onClick={handleUploadFileBtn}
                                disabled={!base64Img}
                                color="primary"
                                endIcon={<Upload />}
                            >
                                <b>Upload</b>
                            </Button>
                        </InputAdornment>
                    ),
                }}
            />
            {img && (
                <div className="img-container">
                    <img
                        src={`${img}?w=164&h=164&fit=crop&auto=format`}
                        // srcSet={`${image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt={""}
                        loading="eager"
                        className="image"
                    />
                    {/* <div className="middle">
                        <div
                            className="text"
                            onClick={() => {
                                setImg(image);
                                setImgModal(true);
                            }}
                        >
                            View Image
                        </div>
                    </div> */}
                </div>
            )}
            {!img && (
                <Box mt={1}>
                    <Typography noWrap color={"GrayText"}>
                        no image uploaded!
                    </Typography>
                </Box>
            )}

            {/* <Modal
                open={imgModal}
                onClose={() => setImgModal(false)}
            >
                <Box sx={modalStyle}>
                    <Stack width={"100%"} height={"100%"}>
                        <a href={img} target="_blank" rel="noreferrer">
                            <img width="100%" height="100%" src={img} alt='' />
                        </a>
                    </Stack>
                    <Stack direction={{ sm: "row", xs: "column" }} justifyContent="space-between">
                            <small>click image to open in new tab</small>
                            <Stack direction={"row"} spacing={1}>
                                <Button 
                                    size="small"
                                    startIcon={<Delete />}
                                    onClick={handleImageDeletion}
                                ><b>remove image</b></Button>
                                <Button 
                                    size="small" 
                                    variant="contained"
                                    onClick={() => setImgModal(false)}
                                ><b>close</b></Button>
                            </Stack>
                        </Stack>
                </Box>
            </Modal> */}
        </>
    );
}

export default SingleImgUploaderInput;
