import { Button, Stack, Typography } from "@mui/material";
import { GridFooterContainer } from "@mui/x-data-grid";

export const tableStyle = (colors) => {
    return {
        "& .MuiDataGrid-root": {
            border: "none",
            fontSize: 16
        },
        "& .MuiDataGrid-cell": {
            // borderBottom: "none",
        },
        "& .name-column--cell": {
            color: colors.greenAccent[300],
            fontWeight: 600
        },
        "& .id-column--cell": {
            color: colors.greenAccent[300],
            fontSize: "small"
        },
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            borderRadius: "0 0 5px 5px",
            backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
        },
    }
}

export const ViewMoreFooter = ({ showViewMore, fetchMoreData }) => {
    return (
        <GridFooterContainer>
            <Stack width="100%" alignItems="center">
                {showViewMore ? <Button fullWidth onClick={fetchMoreData}>
                    <b>view more</b>
                </Button> : <Typography variant="h6">All datas listed</Typography>}
            </Stack>
        </GridFooterContainer>
    );
}