import React, { useEffect, useRef, useState } from 'react';
import { authorizedPost, toggleLoading } from '../../store/actions';
import { LIST_ALL_BOOKING_REPORTS, LIST_ALL_HOTEL_REPORTS } from '../../graphql';
import { Box, Checkbox, Grid, IconButton, InputAdornment, MenuItem, Stack, TextField, Typography, useTheme } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos, CalendarMonth, Download } from '@mui/icons-material';
import { CssTextField } from '../../components/TextField';
import { useDispatch } from 'react-redux';
import { StatusChip } from '../../components/Chips';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import CsvDownloader from "react-csv-downloader";
import OtaWiseRoomNightSales from './OtaWiseRoomNightSales';
import RoomNightSales from './RoomNightSales';
import Header from '../../components/Header';
import moment from 'moment';
import RoomNightSalesComparisonMonthly from './RoomNightSalesComparisonMonthly';
import RoomNightSalesComparisonYearly from './RoomNightSalesComparisonYearly';
import { PaginationFooter } from '../../components';
import { allMonths, allOtas } from '../../data';

const years = (num = 10) => {
    const year = new Date(moment().add(5, "year")).getFullYear();
    return Array.from({ length: num }, (v, i) => year - num + i + 1);
}

function BookingReports() {
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const startInputRef = useRef();
    const endInputRef = useRef();
    const [reports, setReports] = useState([]);
    const [totals, setTotals] = useState({});
    const [roomNights, setRoomNights] = useState([]);
    const [otaWiseRoomNights, setOtaWiseRoomNights] = useState([]);
    const [monthlyComparison, setMonthlyComparison] = useState([]);
    const [yearlyComparison, setYearlyComparison] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [status, setStatus] = useState([]);
    const [bookingFrom, setBookingFrom] = useState([]);
    const [type, setType] = useState("all");
    const [allBookings, setAllBookings] = useState(null);
    const [year, setYear] = useState(moment().year());
    const [month, setMonth] = useState(moment().month());
    const [pagination, setPagination] = useState({
        count: 1,
        pageSize: 50,
        pageNumber: 1,
    });
    const allStatuses = [
        { name: "Confirmed", value: "confirmed" },
        { name: "Booked", value: "booked" },
        { name: "Cancelled", value: "cancelled" },
        { name: "Blocked", value: "hold" },
    ]
    const allBookingFroms = [
        { name: "Direct booking", value: "direct_booking" },
        { name: "Agent", value: "agent" },
        { name: "OTA", value: allOtas.join() },
        { name: "Booking engine", value: "booking_engine" },
    ]
    const allTypes = [
        // { name: "normal", value: "normal"},
        { name: "PHS", value: "phs" },
    ]
    // console.log(reports);

    useEffect(() => {
        document.title = "Reports";
        fetchTableData();
        // eslint-disable-next-line
    }, [startDate, endDate, status, bookingFrom, type])

    const CustomFooter = () => {
        return (
            <PaginationFooter
                count={pagination.count}
                pageSize={pagination.pageSize}
                pageNumber={pagination.pageNumber}
                fetchMoreData={(page) => fetchTableData(page)}
            />
        );
    }

    const fetchTableData = async (page) => {
        dispatch(toggleLoading(true));
        let req = {
            status: status.join(),
            bookingFrom: bookingFrom.join(),
            type: type === "all" ? "" : type,
            pageSize: pagination.pageSize,
            pageNumber: pagination.pageNumber,
        }
        if (page) {
            req = { ...req, ...page }
        }
        if (startDate && endDate) {
            req.startDate = startDate;
            req.endDate = endDate;
        }
        let response = await authorizedPost(LIST_ALL_BOOKING_REPORTS, req)
        if (response?.data) {
            let { data, count, ...rest } = response.data.list_all_booking_reports;
            setReports(data);
            setTotals(rest);
            setPagination({
                count: parseInt(count),
                pageNumber: page?.pageNumber || pagination.pageNumber,
                pageSize: page?.pageSize || pagination.pageSize,
            });
        }
        dispatch(toggleLoading(false));
    }

    const fetchAllBookings = async (page) => {
        try {
            dispatch(toggleLoading(true));
            let req = {
                pageSize: pagination.count,
                pageNumber: 1,
            }
            if (startDate && endDate) {
                req.startDate = startDate;
                req.endDate = endDate;
            }
            let response = await authorizedPost(LIST_ALL_BOOKING_REPORTS, req)
            if (response?.data) {
                // console.log(response.data.list_all_booking_reports);
                setAllBookings(response.data.list_all_booking_reports.data);

                setTimeout(() => {
                    const csvDownloader = document.getElementById('csv-downloader');
                    if (csvDownloader) {
                        csvDownloader.click();
                    }
                }, 100);
            }
            dispatch(toggleLoading(false));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const generateCsvDownloadData = (data) => {
        let filteredData = data.map((row) => (
            {
                "Booked on": row.booked_at,
                "Booking id": row.booking_id,
                "Check-in date": moment.unix(row.checkin_date).format("YYYY-MM-DD"),
                "Check-out date": moment.unix(row.checkout_date).format("YYYY-MM-DD"),
                "Guest name": row.guest_name,
                "Guest email": row.guest_email,
                "Rate": row.custom_price ? row.custom_price : row.total_price,
                "Paid amount": row.paid_amount,
                "Total rooms": row.total_rooms,
                "Total nights": row.total_nights,
                "Booking from": row.booking_from,
                "Status": row.status,
                "Roomtype(s)": row.roomtypes,
            }
        ));
        return filteredData;
    };

    useEffect(() => {
        fetchHotelReportsData();
        // eslint-disable-next-line
    }, [month, year])

    const fetchHotelReportsData = async () => {
        dispatch(toggleLoading(true));
        let response = await authorizedPost(LIST_ALL_HOTEL_REPORTS, { month_and_year: `${allMonths[month]}-${year}` });
        if (response?.data) {
            // console.log(response.data.list_all_hotel_reports.data);
            const { room_nights, roomtype_nights } = response?.data?.list_all_hotel_reports.data;
            const roomNightsData = [
                {
                    value: room_nights?.direct_bookings,
                    label: "Direct bookings",
                },
                {
                    value: room_nights?.ota_bookings,
                    label: "OTA bookings",
                },
                {
                    value: room_nights?.website_bookings || 0,
                    label: "Website bookings",
                },
            ]
            setRoomNights(!room_nights?.direct_bookings && !room_nights?.ota_bookings ? [] : roomNightsData);
            if (room_nights?.ota_data?.length) {
                const otaRoomNightsData = room_nights?.ota_data?.map((e) => ({
                    label: e.ota?.replace("bookingdotcom", "booking.com"),
                    value: e.count,
                }));
                const totalCount = otaRoomNightsData?.map(item => item.value)?.reduce((prev, next) => prev + next);
                setOtaWiseRoomNights(totalCount < 1 ? [] : otaRoomNightsData?.reverse());
            }
            if (roomtype_nights?.monthly?.length) {
                setMonthlyComparison(roomtype_nights?.monthly);
            }
            if (roomtype_nights?.yearly?.length) {
                setYearlyComparison(roomtype_nights?.yearly);
            }
        }
        dispatch(toggleLoading(false));
    }

    const columns = [
        {
            field: "booked_at",
            headerName: "Booked on",
            width: 110,
        },
        {
            field: "guest_name",
            headerName: "Guest info",
            width: 220,
            renderCell: ({ row: { guest_name, guest_email } }) => {
                return (
                    <Stack>
                        <Typography color={colors.greenAccent[400]}>
                            <b>{guest_name}</b>
                        </Typography>
                        <Typography fontSize={"small"}>
                            {guest_email || ""}
                        </Typography>
                    </Stack>
                )
            },
        },
        {
            field: "roomtypes",
            headerName: "Roomtype(s)",
            width: 170,
            renderCell: ({ row: { roomtypes } }) => {
                if (roomtypes?.length > 1) {
                    return (
                        <>
                            <CssTextField
                                fullWidth
                                select
                                size="small"
                                value={roomtypes}
                                SelectProps={{ multiple: true }}
                            >
                                {roomtypes?.map((day, i) => (
                                    <MenuItem value={day} key={i}>
                                        {day}
                                    </MenuItem>
                                ))}
                            </CssTextField>
                        </>
                    );
                } else {
                    return roomtypes[0] ? `${roomtypes[0]}` : "-";
                }
            },
        },
        {
            field: "checkin_date",
            headerName: "Check-in date",
            width: 120,
            valueGetter: (params) => `${params.row.checkin_date ? moment.unix(params.row.checkin_date).format("YYYY-MM-DD") : "-"}`,
        },
        {
            field: "checkout_date",
            headerName: "Check-out date",
            width: 120,
            valueGetter: (params) => `${params.row.checkout_date ? moment.unix(params.row.checkout_date).format("YYYY-MM-DD") : "-"}`,
        },
        {
            field: "total_price",
            headerName: "Rate",
            width: 90,
            renderCell: ({ row: { custom_price, total_price } }) => `${custom_price ? custom_price : total_price}`,
        },
        {
            field: "paid_amount",
            headerName: "Paid amount",
            width: 100,
            // renderCell: ({ row: { paid_amount } }) => `${paid_amount || "-"}`,
        },
        { field: "total_rooms", headerName: "Total rooms", width: 100 },
        { field: "total_nights", headerName: "Total room nights", width: 130 },
        {
            field: "booking_from",
            headerName: "Booking from",
            width: 170,
            renderCell: ({ row: { booking_from } }) => `${booking_from ? booking_from?.replace("_", " ") : "-"}`,
        },
        {
            field: "status",
            headerName: "Status",
            width: 120,
            renderCell: ({ row: { status } }) => <StatusChip status={status} />,
        },
    ];

    return (
        <Box m="20px" mb={15}>
            <Stack
                direction={{ sm: "row", xs: "column" }}
                justifyContent={{ sm: "space-between", xs: "start" }}
                alignItems={{ sm: "center", xs: "start" }}
                m="20px 0"
            >
                <Header title="REPORTS" subtitle="welcome to Reports" />
                {/* <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                        onClick={() => navigate("/new-booking")}
                    >
                        <Add sx={{ mr: "10px" }} />
                        Add new booking
                    </Button>
                </Box> */}
            </Stack>
            <Grid container spacing={2}>
                <Grid item lg={12} xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                        <IconButton
                            onClick={() => {
                                let m = month > 0 ? parseInt(month) - 1 : 11;
                                let y = month > 0 ? year : parseInt(year) - 1;
                                setMonth(m)
                                setYear(y)
                                // handleMonthlyDataFetch(m, y)
                            }}
                        >
                            <ArrowBackIosNew />
                        </IconButton>
                        <TextField
                            select
                            size="small"
                            variant="outlined"
                            value={month === "01" ? "1" : month}
                            onChange={(e) => setMonth(e.target.value)}
                        >
                            {allMonths?.map((m, i) => (
                                <MenuItem value={i} key={m}>
                                    {m}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            select
                            size="small"
                            variant="outlined"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                        >
                            {years()?.map((year) => (
                                <MenuItem value={year} key={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </TextField>
                        {/* <IconButton
                            color="primary"
                            sx={{ borderRadius: 1 }}
                            onClick={() => handleMonthlyDataFetch(dateNum, year)}
                        >
                            {localStorage.getItem("loading2") === "true" ? <CircularProgress size={24} /> : <Search />}
                        </IconButton> */}
                        <IconButton onClick={() => {
                            let m = month < 11 ? parseInt(month) + 1 : 0;
                            let y = month < 11 ? year : parseInt(year) + 1;
                            setMonth(m)
                            setYear(y)
                            // handleMonthlyDataFetch(m, y)
                        }}>
                            <ArrowForwardIos />
                        </IconButton>
                    </Stack>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <RoomNightSales data={roomNights} colors={colors} monthAndYear={`${allMonths[month]}-${year}`} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <OtaWiseRoomNightSales data={otaWiseRoomNights} colors={colors} monthAndYear={`${allMonths[month]}-${year}`} />
                </Grid>
                <Grid item lg={12} xs={12}>
                    <RoomNightSalesComparisonMonthly data={monthlyComparison} colors={colors} />
                </Grid>
                <Grid item lg={12} xs={12}>
                    <RoomNightSalesComparisonYearly data={yearlyComparison} colors={colors} />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={2} justifyContent="space-between">
                <Grid item>
                    <Stack direction={"row"} spacing={1}>
                        <TextField
                            fullWidth
                            size="small"
                            type="date"
                            label="Start Date"
                            placeholder="Start Date"
                            sx={{ width: 170 }}
                            value={startDate ? moment.unix(startDate).format("YYYY-MM-DD") : ""}
                            onChange={(newValue) => {
                                setStartDate(moment(newValue.target.value, "YYYY-MM-DD").startOf("day").unix())
                            }}
                            inputRef={startInputRef}
                            onClick={() => startInputRef.current.showPicker()}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CalendarMonth sx={{ cursor: "pointer" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            fullWidth
                            size="small"
                            type="date"
                            label="End Date"
                            placeholder="End Date"
                            sx={{ width: 170 }}
                            inputProps={{ min: moment.unix(startDate).format("YYYY-MM-DD") }}
                            value={endDate ? moment.unix(endDate).format("YYYY-MM-DD") : ""}
                            onChange={(newValue) => {
                                setEndDate(moment(newValue.target.value, "YYYY-MM-DD").endOf("day").unix())
                            }}
                            inputRef={endInputRef}
                            onClick={() => endInputRef.current.showPicker()}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CalendarMonth sx={{ cursor: "pointer" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Stack>
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item>
                            <TextField
                                select
                                size="small"
                                variant="outlined"
                                label="Status"
                                value={status?.length ? status : ["all"]}
                                onChange={(e) => setStatus(e?.target?.value?.filter(e => e !== "all"))}
                                sx={{ width: 150 }}
                                SelectProps={{ multiple: true }}
                            >
                                <MenuItem value={"all"} disabled>
                                    <em>All</em>
                                </MenuItem>
                                {allStatuses?.map((e) => (
                                    <MenuItem value={e.value} key={e.value}>
                                        <Checkbox size="small" sx={{ p: "0 5px 0 0" }} checked={status.indexOf(e.value) > -1} />
                                        {e.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <TextField
                                select
                                size="small"
                                variant="outlined"
                                label="Booking from"
                                value={bookingFrom?.length ? bookingFrom : ["all"]}
                                onChange={(e) => setBookingFrom(e?.target?.value?.filter(e => e !== "all"))}
                                sx={{ width: 150 }}
                                SelectProps={{ multiple: true }}
                            >
                                <MenuItem value={"all"} disabled>
                                    <em>All</em>
                                </MenuItem>
                                {allBookingFroms?.map((e) => (
                                    <MenuItem value={e.value} key={e.value}>
                                        <Checkbox size="small" sx={{ p: "0 5px 0 0" }} checked={bookingFrom.indexOf(e.value) > -1} />
                                        {e.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <TextField
                                select
                                size="small"
                                variant="outlined"
                                label="Type"
                                value={type}
                                onChange={(e) => setType(e?.target?.value)}
                                sx={{ width: 150 }}
                            >
                                <MenuItem value={"all"}>
                                    <em>All</em>
                                </MenuItem>
                                {allTypes?.map((e) => (
                                    <MenuItem value={e.value} key={e.value}>
                                        <Checkbox size="small" sx={{ p: "0 5px 0 0" }} checked={type.indexOf(e.value) > -1} />
                                        {e.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item>
                            {/* <CsvDownloader filename="Reports" datas={generateCsvDownloadData(reports)}> */}
                            <IconButton
                                title="Export as CSV"
                                sx={{ borderRadius: "5px", bgcolor: colors.greenAccent[700] }}
                                onClick={fetchAllBookings}
                            >
                                <Download />
                            </IconButton>
                            {allBookings && (
                                <CsvDownloader
                                    datas={generateCsvDownloadData(allBookings)}
                                    filename={`Booking_reports(${pagination.count}) - ${localStorage.getItem("hotelName") ?? ""}`}
                                    // separator=","
                                >
                                    <button id="csv-downloader" style={{ display: 'none' }}>
                                        Download CSV
                                    </button>
                                </CsvDownloader>
                            )}
                            {/* </CsvDownloader> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box
                m="8px 0 0 0"
                width="100%"
                height="80vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        // border: "none",
                        fontSize: 16,
                    },
                    "& .MuiDataGrid-cell": {
                        // borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={reports}
                    columns={columns}
                    components={{ Footer: CustomFooter }}
                    getRowId={(row) => row?.booking_id}
                />
            </Box>
            <Box mt={2} p={0.5} border={"1px solid grey"}>
                <Stack direction={{ xs: "column", md: "row" }} spacing={2} justifyContent="space-evenly">
                    <Typography>Total Room Nights &nbsp;&nbsp; <b>{totals.total_nights}</b></Typography>
                    <Typography>Total Cancelled Room Nights &nbsp;&nbsp; <b>{totals.total_cancelled_nights}</b></Typography>
                    <Typography>Total Confirmed Room Nights &nbsp;&nbsp; <b>{totals.total_confirmed_nights}</b></Typography>
                    <Typography>Total Amount &nbsp;&nbsp; <b>{totals.total_amount}</b></Typography>
                </Stack>
            </Box>
        </Box>
    )
}

export default BookingReports;