/*eslint-disable*/
import moment from "moment";

export function filterGroupBookingDatas(selectedRooms) {
    const rooms = selectedRooms.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.room_id === value.room_id
        ))
    )

    let uu = [];
    rooms.map((room) => {
        let filtered = selectedRooms.filter((ff) => room.room_id === ff.room_id);
        filtered = filtered.sort(function (a, b) {
            return new Date(a.index) - new Date(b.index);
        });
        uu.push(filtered)
    })

    let bb = [];
    let aa = [];
    uu.map((arr) => {
        arr.map((e, i) => {
            let date1 = new Date(e.date);
            date1.setDate(date1.getDate() + 1);
            date1 = moment(date1).format("DD-MMM-YYYY")
            let date2 = moment(new Date(arr[i + 1]?.date)).format("DD-MMM-YYYY");

            if (date1 === date2 && e?._id === arr[i + 1]?._id) {
                aa.push(e);
            } else {
                aa.push(e)
                bb.push(aa);
                aa = [];
            }

        })
    })

    let final = bb.map((item, idx) => {
        let checkout = new Date(item[item.length - 1].date)
        checkout.setDate(checkout.getDate() + 1);
        // if(item[0].date === item[item.length - 1].date){
        // }
        return {
            checkin_date: moment(new Date(item[0].date)).unix(),
            checkout_date: moment(checkout).unix(),
            dates: item.map((e) => {
                return e.date;
            }),
            roomtype_id: item[0].roomtype_id,
            room_id: item[0].room_id,
            room_number: idx,
            room_no: item[0].room_no,
            no_of_adults: 2,
            no_of_childs: 0,
            rate: (item[0].rate * item.length),
        }
    })
    return final;
}