import React, { useState } from 'react'
import { Box, Button, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { tokens } from '../theme';
import { ArrowForward, ExpandMore } from '@mui/icons-material';

function ResponsiveTable({ rows = [], columns = [], CustomFooter, buttonAction, showPagination, cardHead = {} }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const [expanded, setExpanded] = useState([]);

    const handleExpandButton = (id) => {
        if (!expanded.includes(id)) {
            setExpanded([...expanded, id]);
        } else {
            let newArray = expanded;
            newArray = newArray.filter(e => e !== id);
            setExpanded(newArray);
        }
    }

    return (
        <Box>
            {smScreen ? (
                <Box
                    m="8px 0 0 0"
                    width="100%"
                    height="80vh"
                    sx={{
                        "& .MuiDataGrid-root": {
                            // border: "none",
                            fontSize: 16,
                        },
                        "& .MuiDataGrid-cell": {
                            // borderBottom: "none",
                        },
                        "& .name-column--cell": {
                            color: colors.greenAccent[300],
                        },
                        "& .id-column--cell": {
                            color: colors.greenAccent[300],
                            fontSize: "small",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: colors.blueAccent[700],
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: colors.blueAccent[700],
                        },
                        "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colors.grey[100]} !important`,
                        },
                    }}
                >
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        components={{ Toolbar: GridToolbar, Footer: CustomFooter }}
                        getRowId={(row) => row?._id}
                        componentsProps={{
                            toolbar: {
                                csvOptions: { disableToolbarButton: true },
                                printOptions: { disableToolbarButton: true },
                                showQuickFilter: true,
                                // quickFilterProps: { debounceMs: 250 },
                            },
                        }}
                    />
                </Box>
            ) : (
                <Box mt={2}>
                    <Grid container spacing={0.5}>
                        {rows?.map((el, idx) => (
                            <Grid item xs={12} md={6} key={idx}>
                                <Box
                                    p={1}
                                    bgcolor={colors.primary[400]}
                                    fontSize={14}
                                    borderRadius={1}
                                    // onClick={() => navigate(`/booking-details/${el?.booking_id}`)}
                                    sx={{ cursor: "pointer", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: 1 }}
                                >
                                    <Box width={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} gap={1}>
                                        <Box maxWidth={"80%"}>
                                            <Typography color={colors.greenAccent[300]} fontWeight={700} noWrap>{el[cardHead.title] || "-"}</Typography>
                                            {el[cardHead.subtitle] ? <Typography color={colors.grey[300]} noWrap>{el[cardHead.subtitle] || "-"}</Typography> : null}
                                        </Box>
                                        <Box>
                                            <IconButton onClick={() => handleExpandButton(el._id)}><ExpandMore /></IconButton>
                                        </Box>
                                    </Box>

                                    {expanded?.includes(el._id) ? (
                                        <>
                                            <table width={"100%"}>
                                                <tbody>
                                                    {columns?.map((j, k) => {
                                                        if (!j.hideInXs) {
                                                            return (
                                                                <tr key={k}>
                                                                    <td width={130} style={{ padding: "2px" }}>{j.headerName} :</td>
                                                                    <td style={j.cellClassName === "name-column--cell" ? { color: colors.greenAccent[300], fontWeight: 600 } : {}}>
                                                                        {j.renderCell ? j.renderCell({ row: el }) : el[j.field] || "-"}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        } else {
                                                            return null
                                                        }
                                                    })}
                                                </tbody>
                                            </table>
                                            {buttonAction ? (
                                                <Button
                                                    fullWidth
                                                    size='small'
                                                    variant='contained'
                                                    sx={{ fontWeight: 600 }}
                                                    onClick={() => buttonAction(el)}
                                                    endIcon={<ArrowForward fontSize="small" />}
                                                >
                                                    VIEW MORE DETAILS
                                                </Button>
                                            ) : null}
                                        </>
                                    ) : null}
                                </Box>
                            </Grid>
                        ))}

                        {!rows?.length ? (
                            <Grid item xs={12}>
                                <Box height={200} display={"flex"} justifyContent="center" alignItems="center">
                                    No data found!
                                </Box>
                            </Grid>
                        ) : null}

                        {showPagination ? (
                            <Grid mt={2} item xs={12}>
                                <CustomFooter />
                            </Grid>
                        ) : null}
                    </Grid>
                </Box>
            )}
        </Box>
    )
}

export default ResponsiveTable