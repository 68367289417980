export const GET_HOTEL_DETAILS = `query ($hotelCode: String!) {
  list_hotel_details(hotel_code: $hotelCode){
    message
    status
    is_private
    data {
      _id
      name
      address
      logo
      hotel_img
      cancel_policy
      terms_conditions
      postal_code
      min_child_age
      max_child_age
      property {
        name
        type
        address
      }
      max_people {
        individual
        private
      }
      bke_partial_pmt
    }
  }
}`;

export const CHECK_AVAILABILITY = `query ($hotelCode: String!, $checkinDate: Int!, $checkoutDate: Int!, $numOfAdults: Int!, $numOfChilds: Int!, $totalRooms: Int!, $isPrivate: Boolean, $showAll: Boolean) {
  check_availability(hotel_code: $hotelCode, checkin_date: $checkinDate, checkout_date: $checkoutDate, no_of_adults: $numOfAdults, no_of_childs: $numOfChilds, total_rooms: $totalRooms, is_private: $isPrivate, show_all: $showAll){
    message
    data {
      _id
      name
      description
      available_rooms
      total_price
      max_no_of_people
      min_no_of_people
      images
      display_room_no
      actual_price
      tax_amount
      room_only_rate
      meal_plan_rates {
        _id
        name
        description
        total_price
        actual_price
        tax_amount
        rate
      }
    }
  }
}`;

export const CHECK_ROOMTYPE_PRICING = `query ($roomtypeId: String!, $checkinDate: Int!, $checkoutDate: Int!, $numOfAdults: Int!, $numOfChilds: Int!, $totalRooms: Int!, $mealPlanId: String) {
  check_roomtype_pricing(roomtype_id: $roomtypeId, checkin_date: $checkinDate, checkout_date: $checkoutDate, no_of_adults: $numOfAdults, no_of_childs: $numOfChilds, total_rooms: $totalRooms, meal_plan_id: $mealPlanId){
    message
    data {
      _id
      price
      total_price
      tax_amount
      total_price1
      tax_amount1
      rates {
        date
        price
      }
    }
  }
}`;

export const ADD_BOOKING = `mutation ($hotel_code: String!, $roomtype_id: String!, $checkin_date: Int!, $checkout_date: Int!, $no_of_adults: Int!, $no_of_childs: Int!, $total_rooms: Int!, $name: String!, $email: String!, $guest_address: String, $guest_mobile: String!, $first_name: String, $last_name: String, $remarks: String, $meal_plan_id: String, $title: String, $buyer_pincode: String!, $partial_pmt: Boolean, $activities: [add_new_booking_activities]) {
  add_new_booking(hotel_code: $hotel_code, roomtype_id: $roomtype_id, checkin_date: $checkin_date, checkout_date: $checkout_date, no_of_adults: $no_of_adults, no_of_childs: $no_of_childs, total_rooms: $total_rooms, name: $name, email: $email, guest_address: $guest_address, guest_mobile: $guest_mobile, first_name: $first_name, last_name: $last_name, remarks: $remarks, meal_plan_id: $meal_plan_id, title: $title, buyer_pincode: $buyer_pincode, partial_pmt: $partial_pmt, activities: $activities) {
    message
    data {
      private_key
      checksum
      mid
      order_id
      amount
    }
  }
}`;

export const LIST_MEAL_PLANS = `query ($roomtypeId: String!, $pageSize: Int, $pageNumber: Int) {
  list_all_meal_plans(roomtype_id: $roomtypeId, page_size: $pageSize, page_number: $pageNumber){
    message
    data {
      _id
      name
      description
      rate
      roomtype_id
      is_active
    }
    hasMore
  }
}`;

export const LIST_ALL_BOOKING_ENGINE_ACTIVITIES = `query ($hotelCode: String!, $pageSize: Int, $pageNumber: Int) {
  list_all_activities(hotel_code: $hotelCode, page_size: $pageSize, page_number: $pageNumber){
    message
    data {
      _id
      name
      img
      description
      rate
    }
    hasMore
  }
}`;

export const BOOKING_ENQUIRY = `query ($hotelCode: String!, $name: String!, $email: String!, $mobile: String!, $checkinDate: String, $checkoutDate: String, $numOfAdults: Int!, $numOfChilds: Int!, $totalRooms: Int!) {
  booking_enquiry(hotel_code: $hotelCode, name: $name, email: $email, mobile: $mobile, checkin_date: $checkinDate, checkout_date: $checkoutDate, no_of_adults: $numOfAdults, no_of_childs: $numOfChilds, total_rooms: $totalRooms){
    message
  }
}`;