import React from "react";
import { Box, Button, Grid, Stack, Typography,useTheme } from "@mui/material";
import { tokens } from "../../../theme";

const SecondPage = ({ roomsData, setPage }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const handleBackButton = () => setPage(1);

    return (
        <Box>
            <Stack p={2} textAlign="center" color={colors.redAccent[500]}>
                <Typography fontSize={17}>Rom not available for the seleceted date</Typography>
            </Stack>
            <Box
                ml={-2}
                mr={-2}
                p={2}
                maxHeight={"60vh"}
                overflow="auto"
                border={"1px solid #777"}
                bgcolor={"rgb(0, 0, 0, 0.2)"}
                borderRadius={2}
            >
                <Grid container spacing={1}>
                    {roomsData?.map((room, i) => (
                        <Grid item md={12} xs={12} key={i}>
                            <Stack
                                p={1}
                                borderRadius={2}
                                border={1}
                                borderColor={colors.greenAccent[600]}
                                backgroundColor={colors.primary[500]}
                                direction="row"
                                spacing={1}
                            >
                                <Stack
                                    bgcolor={colors.greenAccent[600]}
                                    p={1}
                                    alignItems="center"
                                    borderRadius={1}
                                >
                                    <small>Room No</small>
                                    <Typography fontSize={20} fontWeight={600}>
                                        {room?.room_no}
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <Typography color={"GrayText"}>Already booked</Typography>
                                    <Typography>Booking id:</Typography>
                                    <Stack>{room?.booking_id}</Stack>
                                </Stack>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
                {!roomsData?.length ? (
                    <Stack p={2} alignItems={"center"}>
                        No data found
                    </Stack>
                ) : null}
            </Box>
            <Stack mt={2}>
                <Button
                    fullWidth
                    // size="small"
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    sx={{ fontSize: "15px" }}
                    onClick={handleBackButton}
                >
                    <b>Go Back</b>
                </Button>
            </Stack>
        </Box>
    );
};

export default SecondPage;
