import React from 'react';
import { Box, Typography } from '@mui/material';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts';

function RoomNightSales({ data, colors, monthAndYear }) {
    data = data.map(({ label, value }) => ({ value, label: `${label} - ${value ?? 0}` }));
    return (
        <Box backgroundColor={colors.primary[400]} p="20px" height="100%" overflow="visible">
            <Typography variant="h5">
                ROOM NIGHT SALES ON
            </Typography>
            <Typography variant="h4" fontWeight="600" textTransform={"uppercase"}>
                {monthAndYear}
            </Typography>
            {data?.length ? (
                <Box
                    width={"100%"}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    overflow="visible"
                    mt={1}
                    // ml={-4}
                >
                    <PieChart
                        colors={['#fa89b1', '#49BFA3', '#c4ff5e']}
                        series={[
                            {
                                arcLabel: (item) => `${item.value}`,
                                arcLabelMinAngle: 45,
                                outerRadius: 100,
                                paddingAngle: 1,
                                cornerRadius: 1,
                                data,
                            },
                        ]}
                        sx={{
                            [`& .${pieArcLabelClasses.root}`]: {
                                fill: 'white',
                                fontWeight: 'bold',
                            },
                            '--ChartsLegend-itemWidth': '160px !important',
                            // '--ChartsLegend-labelSpacing': '5px',
                            // '--ChartsLegend-rootSpacing': '5px',
                            // display: "flex",
                            // justifyContent: "center",
                            // alignItems: "center",
                            // width: "100%",
                            overflow: "visible",
                            marginLeft: -10
                        }}
                        width={400}
                        height={300}
                        // legend={{
                        //     position: { vertical: "bottom", horizontal: "middle" },
                        // }}
                    />
                </Box>
            ) : (
                <Typography mt={15} mb={15} textAlign="center">
                    No Data Available...
                </Typography>
            )}
        </Box>
    )
}

export default RoomNightSales;