import React from "react";
import { Box, Button, CircularProgress, Stack, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { resendBookingEmails } from "../../../store/actions";
import { tokens } from "../../../theme";
import { Close } from "@mui/icons-material";
import { toast } from "react-toastify";
import Header from "../../../components/Header";

function ResendEmailsModal({ bookingDetails, setResendEmailModal, isForUpdate, setResendUpdatedEmailModal }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const colors = tokens(theme.palette.mode);

    const { isLoading } = useSelector(({ loading }) => ({ isLoading: loading }));

    const modalBoxStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "90vh",
        width: { sm: 400, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    const handleResendBtn = async() => {
        let res = await dispatch(resendBookingEmails({
            bookingId: bookingDetails?.booking_id,
            isForUpdate: isForUpdate,
        }))
        if(res){
            toast.success("Email sent successfully!");
            setResendUpdatedEmailModal(false);
            setResendEmailModal(false);
        }
    }

    const handleClose = () => {
        setResendUpdatedEmailModal(false);
        setResendEmailModal(false);
    }

    return (
        <>
            <Box sx={modalBoxStyle}>
                <Stack direction="row" justifyContent="space-between">
                    <Header title={`Are you sure to ${isForUpdate ? "send update" : "resend confirmation"} email?`} titleVariant="h4" />
                    <Close onClick={handleClose} sx={{ cursor: "pointer" }} />
                </Stack>
                {/* <Stack>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Guest" />
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Agent" />
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Sub agent" />
                    </FormGroup>
                </Stack> */}
                <Stack mt={2} direction="row" spacing={2}>
                    <Button fullWidth variant="outlined" onClick={handleClose}>
                        <b>Close</b>
                    </Button>
                    <Button fullWidth variant="contained" onClick={handleResendBtn}>
                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Send email</b>}
                    </Button>
                </Stack>
            </Box>
        </>
    );
}

export default ResendEmailsModal;
