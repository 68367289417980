import { styled, TextField } from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";

export const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            // borderColor: '#999',
            border: "3px solid #999",
        },
    },
});

export const UpdatableField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            // borderColor: '#c2c2c2',
            border: "3px solid #c2c2c2",
        },
    },
});

export const ChipsInputStyled = styled(MuiChipsInput)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: "3px solid #999",
        },
    },
});