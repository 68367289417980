import React, { useEffect, useRef, useState } from "react";
import { Box, Button, InputAdornment, Stack, TextField, useTheme } from "@mui/material";
import { listAllHotsoftUpdates, resetAllHotsoftUpdates } from "../../store/actions";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { ArrowForward, CalendarMonth, Check, Close, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PaginationFooter, tableStyle } from "../../components";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import moment from "moment";

const HotsoftUpdatesList = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const startInputRef = useRef();
    const endInputRef = useRef();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const { hotsoftUpdates, pageNumber, pageSize, count } = useSelector(
        ({
            loading,
            hotsoft: {
                hotsoftUpdates: { list, pageNumber, pageSize, count },
            },
        }) => ({
            isLoading: loading,
            hotsoftUpdates: list,
            pageNumber,
            pageSize,
            count,
        })
    );
    // console.log(hotsoftUpdates);
    useEffect(() => {
        document.title = "Hotsoft Updates";
        dispatch(resetAllHotsoftUpdates());
        fetchMoreData();
        // eslint-disable-next-line
    }, [dispatch]);

    const fetchMoreData = (page) => {
        let req = startDate && endDate ? 
        { 
            ...page, 
            startDate: moment(startDate, "YYYY-MM-DD").startOf("day").unix(),
            endDate: moment(endDate, "YYYY-MM-DD").endOf("day").unix(),
        } 
        : page;
        dispatch(listAllHotsoftUpdates(req));
    };

    const CustomFooter = () => {
        return (
            <PaginationFooter
                count={count}
                pageSize={pageSize}
                pageNumber={pageNumber}
                fetchMoreData={(page) => fetchMoreData(page)}
            />
        );
    }

    const handleFilterBtn = () => {
        if (startDate && endDate) {
            dispatch(resetAllHotsoftUpdates());
            dispatch(listAllHotsoftUpdates({
                startDate: moment(startDate, "YYYY-MM-DD").startOf("day").unix(),
                endDate: moment(endDate, "YYYY-MM-DD").endOf("day").unix(),
            }));
        }
    }

    const columns = [
        // {
        //     field: '' , 
        //     headerName: 'no.',
        //     renderCell:(index) => index.api.getRowIndex(index.row._id) + 1,
        //     width: 20,
        // },
        { 
            field: "start_date", 
            headerName: "Date", 
            width: 150, 
            renderCell: ({ row: { start_date } }) => moment(start_date, "M/D/YYYY").format("YYYY/MM/DD"),
        },
        // { field: "end_date", headerName: "End date", width: 150 },
        { field: "avail_count", headerName: "Hotsoft count", width: 120 },
        { field: "cron_avail_count", headerName: "Travelnet count", width: 120 },
        {
            field: "roomtype_id",
            headerName: "Roomtype",
            width: 150,
            renderCell: ({ row: { roomtype_id: { name } } }) => `${name}`,
        },
        {
            field: "stop_sell",
            headerName: "Stop sell",
            width: 120,
            renderCell: ({ row: { stopsell } }) => stopsell ? <Check color="success"/> : <Close color="error"/>,
        },
        {
            field: "_id",
            headerName: "View details",
            width: 150,
            renderCell: ({ row: { _id } }) => {
                return (
                    <Button
                        size="small"
                        variant="contained"
                        onClick={async () => {
                            navigate(`/hotsoft-update-details/${_id}`);
                        }}
                    >
                        <ArrowForward fontSize="small" />
                    </Button>
                );
            },
        },
    ];

    return (
        <Box m="20px" mb={15}>
            <Stack
                direction={{ sm: "row", xs: "column" }}
                justifyContent={{ sm: "space-between", xs: "start" }}
                alignItems={{ sm: "center", xs: "start" }}
                m="20px 0"
            >
                <Header title="HOTSOFT UPDATES" subtitle="welcome to Hotsoft Updates" />
            </Stack>
            <Stack
                direction={"row"}
                justifyContent={{ md: "flex-end", xs: "flex-start" }}
                spacing={1}
            >
                <TextField
                    fullWidth
                    size="small"
                    type="date"
                    label="Start Date"
                    placeholder="Start Date"
                    sx={{ width: 170 }}
                    inputRef={startInputRef}
                    onClick={() => startInputRef.current.showPicker()}
                    value={startDate || ""}
                    onChange={(newValue) => {
                        setStartDate(newValue.target.value)
                        if (endDate && moment(newValue.target.value).add(1, "days").format("YYYY-MM-DD") > endDate) {
                            setEndDate(moment(newValue.target.value).add(1, "days").format("YYYY-MM-DD"));
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <CalendarMonth />
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    fullWidth
                    size="small"
                    type="date"
                    label="End Date"
                    placeholder="End Date"
                    sx={{ width: 170 }}
                    inputRef={endInputRef}
                    onClick={() => endInputRef.current.showPicker()}
                    inputProps={{ min: startDate }}
                    value={endDate || ""}
                    onChange={(newValue) => setEndDate(newValue.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <CalendarMonth />
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    size="small"
                    variant="outlined"
                    onClick={handleFilterBtn}
                >
                    <Search />
                </Button>
            </Stack>
            <Box m="8px 0 0 0" height="68vh" sx={tableStyle(colors)}>
                <DataGrid
                    rows={hotsoftUpdates}
                    columns={columns}
                    components={{ Footer: CustomFooter }}
                    getRowId={(row) => row?._id}
                />
            </Box>
        </Box>
    );
};

export default HotsoftUpdatesList;
