import { post } from "../../services";
import { ACTIVATE_HOTEL_USER, ADD_NEW_USER, LIST_ALL_HOTEL_USERS } from "../../graphql";
import { RESET_ALL_HOTEL_USERS, SAVE_ALL_HOTEL_USERS, TOGGLE_LOADING } from "../types";


export const listAllHotelUsers = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            users: { pageSize, pageNumber, list },
        } = getState().users;

        let apiResponse = await post("", {
            query: LIST_ALL_HOTEL_USERS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_hotel_users: { data, hasMore },
            } = apiResponse.data.data;
            let updatedList = [...list, ...data];
            // console.log(apiResponse);
            dispatch({
                type: SAVE_ALL_HOTEL_USERS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllHotelUsers = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_HOTEL_USERS });
    };
};

export const addNewUser = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_USER,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                add_new_user: { _id },
            } = apiResponse.data.data;
            dispatch(resetAllHotelUsers());
            dispatch(listAllHotelUsers());
            return _id;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const activateUser = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ACTIVATE_HOTEL_USER,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllHotelUsers());
            dispatch(listAllHotelUsers());
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};