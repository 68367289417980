import { RESET_ALL_HOTEL_OTAS, SAVE_ALL_HOTEL_OTAS } from "../types";

const INITIAL_STATE = {
    otas: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 10,
    },
};

const otasReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SAVE_ALL_HOTEL_OTAS:
            return {
                ...state,
                otas: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_HOTEL_OTAS:
            return {
                ...state,
                otas: INITIAL_STATE.otas,
            };

        default:
            return state;
    }
};

export default otasReducer;
