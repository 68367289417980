// /*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import { Box, Button, ButtonGroup, Checkbox, IconButton, InputAdornment, MenuItem, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { resetAllBookingsData, listAllBookings } from "../../store/actions";
import { Add, ArrowBackIosNew, ArrowForward, ArrowForwardIos, CalendarMonth, Download } from "@mui/icons-material";
// import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StatusChip } from "../../components/Chips";
import { CssTextField } from "../../components/TextField";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import moment from "moment";
import "./style.css";
import ResponsiveTable from "../../components/ResponsiveTable";
import { PaginationFooter } from "../../components";
import CsvDownloader from "react-csv-downloader";

const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

const Bookings = () => {
    const d = new Date();
    const [dateNum, setDateNum] = useState(d.getMonth());
    const [year, setYear] = useState(d.getFullYear());
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const startInputRef = useRef();
    const endInputRef = useRef();
    const [searchParams] = useSearchParams();
    const tab = searchParams.get("tab");
    const [type, setType] = useState(tab || "upcoming");
    const [searchId, setSearchId] = useState("");
    const [startDate, setStartDate] = useState(moment().startOf("day").unix());
    const [endDate, setEndDate] = useState(moment().add(1, "y").endOf("day").unix());
    const [filtered, setFiltered] = useState([]);
    const [withoutCanc, SetWithoutCanc] = useState([]);
    const [selected, setSelected] = useState([]);
    const allStatuses = [
        { name: "confirmed", value: "confirmed"},
        { name: "booked", value: "booked"},
        { name: "cancelled", value: "cancelled"},
        { name: "Blocked", value: "hold"},
    ]

    const { bookingsList, pageNumber, pageSize, count } = useSelector(
        ({
            admin: {
                bookings: { list, pageNumber, pageSize, count }
            },
        }) => ({
            bookingsList: list,
            pageNumber, pageSize, count,
        })
    );

    useEffect(() => {
        dispatch(resetAllBookingsData());
        fetchMoreData();
    }, [startDate, endDate, type, dateNum]);

    useEffect(() => {
        document.title = 'Bookings';
        var withoutCancelled = bookingsList?.filter((e) => e?.status !== "cancelled");
        SetWithoutCanc(withoutCancelled);

        var data = bookingsList?.filter((e) => selected?.includes(e?.status));
        setFiltered(data);
    }, [selected, bookingsList]);

    const CustomFooter = () => {
        return (
            <PaginationFooter
                count={count}
                pageSize={pageSize}
                pageNumber={pageNumber}
                fetchMoreData={(page) => fetchMoreData(page)}
            />
        );
    }

    const fetchMoreData = (page) => {
        if(type === "recent") {
            dispatch(listAllBookings(page));
        } 
        else if (type === "upcoming") {
            let req = startDate && endDate ?
                {
                    ...page,
                    startDate: startDate,
                    endDate: endDate,
                }
                : page;
            dispatch(listAllBookings(req));
        }
        else if(type === "month") {
            let start = `01-${months[dateNum]}-${year}`;
            let end = moment(new Date(start)).endOf('month').format('DD-MMM-YYYY');
            start = moment(new Date(start)).unix();
            end = moment(new Date(end)).unix();
            // dispatch(resetAllBookingsData());
            dispatch(listAllBookings({ startDate: start, endDate: end, ...page }));
        }
    };

    const generateCsvDownloadData = (data) => {
        let filteredData = data.map((row) => (
            {
                // "Booked on": row.booked_at,
                "Booking id": row.booking_id,
                "Check-in date": moment.unix(row.checkin_date).format("YYYY-MM-DD"),
                "Check-out date": moment.unix(row.checkout_date).format("YYYY-MM-DD"),
                "Guest name": row.guest_name,
                "Guest email": row.guest_email,
                "Rate": row.custom_price ? row.custom_price : row.total_price,
                "Paid amount": row.paid_amount,
                "Total rooms": row.total_rooms,
                // "Total nights": row.total_nights,
                "Booking from": row.booking_from,
                "Status": row.status,
                "Roomtype(s)": row.roomtypes,
            }
        ));
        return filteredData;
    };

    const columns = [
        {
            field: "booking_id",
            headerName: "Booking id",
            cellClassName: "id-column--cell",
            width: 120,
            renderCell: ({ row }) => (
                <Typography
                    noWrap
                    fontSize={"small"}
                    onClick={() => navigator.clipboard.writeText(row.booking_id || "")}
                >
                    {row?.booking_id ? row.booking_id : "-"}
                </Typography>
            ),
        },
        {
            field: "guest_name",
            headerName: "Guest info",
            width: 210,
            hideInXs: true,
            renderCell: ({ row: { guest_name, guest_email } }) => {
                return (
                    <Stack sx={{ wordBreak: "break-word" }}>
                        <Typography color={colors.greenAccent[400]}>
                            <b>{guest_name}</b>
                        </Typography>
                        <Typography fontSize={"small"}>
                            {guest_email || ""}
                        </Typography>
                    </Stack>
                )
            },
        },
        {
            field: "roomtypes",
            headerName: "Roomtype(s)",
            width: 150,
            renderCell: ({ row: { roomtypes } }) => {
                if (roomtypes?.length > 1) {
                    return (
                        <>
                            <CssTextField
                                // fullWidth
                                select
                                size="small"
                                variant="standard"
                                sx={{width: 200}}
                                value={roomtypes}
                                SelectProps={{ multiple: true }}
                            >
                                {roomtypes?.map((day, i) => (
                                    <MenuItem value={day} key={i}>
                                        {/* <Checkbox sx={{ p: "0 5px" }} checked={roomtypes.indexOf(day) > -1} /> */}
                                        {day}
                                    </MenuItem>
                                ))}
                            </CssTextField>
                        </>
                    );
                } else {
                    return `${roomtypes[0]}`;
                }
            },
        },
        {
            field: "checkin_date",
            headerName: "Check-in date",
            width: 120,
            renderCell: ({ row }) => `${row?.checkin_date ? moment.unix(row.checkin_date).format("YYYY-MM-DD") : "-"}`,
        },
        {
            field: "checkout_date",
            headerName: "Check-out date",
            width: 120,
            renderCell: ({ row }) => `${row.checkout_date ? moment.unix(row.checkout_date).format("YYYY-MM-DD") : "-"}`,
        },
        // { field: "total_price", headerName: "Booking Price", width: 120 },
        { 
            field: "total_price", 
            headerName: "Price", 
            width: 80,
            renderCell: ({ row: { custom_price, total_price } }) => `${custom_price ? custom_price : total_price}`,
        },
        { field: "total_rooms", headerName: "Total rooms", width: 80 },
        { 
            field: "booking_from", 
            headerName: "Booking from", 
            width: 150,
            renderCell: ({ row: { booking_from } }) => `${booking_from?.replace("_", " ")}`, 
        },
        {
            field: "status",
            headerName: "Status",
            width: 100,
            renderCell: ({ row: { status } }) => <StatusChip status={status} />,
        },
        {
            field: "_id",
            headerName: "View details",
            width: 110,
            hideInXs: true,
            renderCell: ({ row: { booking_id } }) => {
                return (
                    <Button 
                        size="small" 
                        variant="contained" 
                        onClick={() => navigate(`/booking-details/${booking_id}`)}
                        endIcon={<ArrowForward fontSize="small" />}
                        sx={{ fontWeight: 600 }}
                    >
                        View
                    </Button>
                )
            },
        },
    ];
    return (
        <Box m="20px" mb={15}>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={smScreen ? "center" : "start"}
                m="20px 0"
            >
                <Header title="BOOKINGS" subtitle="welcome to Bookings" />
                <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                        onClick={() => navigate("/new-booking")}
                        disabled={localStorage?.getItem("isHotsoft") === "true"}
                    >
                        <Add sx={{ mr: "10px" }} />
                        Add new booking
                    </Button>
                </Box>
            </Box>
            <Box className="box1">
            <Stack className="box2" direction={"row"} justifyContent={{ md: "space-between" }} alignItems={{sm: "end", xs: "start"}} spacing={2}>
                <Stack direction="row" spacing={1}>
                    <ButtonGroup
                        disableElevation
                        size="small"
                    >
                        <Button
                            variant={type === "upcoming" ? "contained" : "outlined"}
                            onClick={() => setType("upcoming")}
                        >
                            <b>Upcoming</b>
                        </Button>
                        <Button
                            variant={type === "recent" ? "contained" : "outlined"}
                            onClick={() => setType("recent")}
                        >
                            <b>Recent</b>
                        </Button>
                        <Button
                            variant={type === "month" ? "contained" : "outlined"}
                            onClick={() => setType("month")}
                        >
                            <b>Monthly</b>
                        </Button>
                    </ButtonGroup>
                    <TextField
                        select
                        size="small"
                        variant="outlined"
                        label="Filter by status"
                        value={selected?.length ? selected : ["all"]}
                        onChange={(e) => setSelected(e?.target?.value?.filter(e => e !== "all"))}
                        sx={{ width: 150 }}
                        SelectProps={{ multiple: true }}
                    >
                        <MenuItem value={"all"}>
                            <em>All</em>
                        </MenuItem>
                        {allStatuses?.map((e) => (
                            <MenuItem value={e.value} key={e.value}>
                                <Checkbox size="small" sx={{ p: "0 5px 0 0" }} checked={selected.indexOf(e.value) > -1} />
                                {e.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        size="small"
                        variant="outlined"
                        label="Open booking with id"
                        value={searchId}
                        onChange={(e) => setSearchId(e.target.value?.replace(" ", ""))}
                        sx={{ width: 220 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton 
                                        size="small" 
                                        onClick={(e) => navigate(`/booking-details/${searchId}`)}
                                        disabled={!searchId}
                                    >
                                    <ArrowForward fontSize="small" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <CsvDownloader filename="Reports" datas={generateCsvDownloadData(selected?.length ? filtered : withoutCanc)}>
                        <IconButton title="Export as CSV" sx={{ borderRadius: "5px", bgcolor: colors.greenAccent[700] }}>
                            <Download />
                        </IconButton>
                    </CsvDownloader>
                </Stack>
                {type !== "month" ? (
                <Stack direction={"row"} spacing={1}>
                    <TextField
                        fullWidth
                        size="small"
                        type="date"
                        label="Start Date"
                        placeholder="Start Date"
                        sx={{ width: 170 }}
                        value={startDate ? moment.unix(startDate).format("YYYY-MM-DD") : ""}
                        onChange={(newValue) => {
                            setStartDate(moment(newValue.target.value, "YYYY-MM-DD").startOf("day").unix())
                            if (moment(newValue.target.value).add(1, "days").format("YYYY-MM-DD") > moment.unix(endDate).format("YYYY-MM-DD")) {
                                setEndDate(moment(newValue.target.value, "YYYY-MM-DD").add(1, "days").endOf("day").unix());
                            }
                        }}
                        inputRef={startInputRef}
                        onClick={() => startInputRef.current.showPicker()}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CalendarMonth sx={{ cursor: "pointer" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        fullWidth
                        size="small"
                        type="date"
                        label="End Date"
                        placeholder="End Date"
                        sx={{ width: 170 }}
                        inputProps={{ min: moment.unix(startDate).format("YYYY-MM-DD") }}
                        value={endDate ? moment.unix(endDate).format("YYYY-MM-DD") : ""}
                        onChange={(newValue) => {
                            setEndDate(moment(newValue.target.value, "YYYY-MM-DD").endOf("day").unix())
                        }}
                        inputRef={endInputRef}
                        onClick={() => endInputRef.current.showPicker()}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CalendarMonth sx={{ cursor: "pointer" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
                ) : (
                    <Stack direction="row" alignItems="center">
                        <IconButton onClick={() => {
                            setDateNum(dateNum > 0 ? dateNum - 1 : 11)
                            setYear(dateNum > 0 ? year : year - 1)
                        }}>
                            <ArrowBackIosNew />
                        </IconButton>
                        <Typography fontSize={20} width={100}>
                            {months[dateNum]}, {year}
                        </Typography>
                        <IconButton onClick={() => {
                            setDateNum(dateNum < 11 ? dateNum + 1 : 0)
                            setYear(dateNum < 11 ? year : year + 1)
                        }}>
                            <ArrowForwardIos />
                        </IconButton>
                    </Stack>
                )}
            </Stack>
            </Box>
            
            <ResponsiveTable 
                rows={selected?.length ? filtered : withoutCanc}
                columns={columns}
                CustomFooter={CustomFooter}
                buttonAction={(e) => navigate(`/booking-details/${e.booking_id}`)}
                cardHead={{
                    title: "guest_name",
                    subtitle: "guest_email",
                }}
            />
            {/* <Box
                m="8px 0 0 0"
                width="100%"
                height="80vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        // border: "none",
                        fontSize: 16,
                    },
                    "& .MuiDataGrid-cell": {
                        // borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={selected?.length ? filtered : withoutCanc}
                    columns={columns}
                    components={{ Toolbar: GridToolbar, Footer: CustomFooter }}
                    getRowId={(row) => row?._id}
                />
            </Box> */}
        </Box>
    );
};

export default Bookings;
