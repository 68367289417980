import {
    RESET_ALL_DASHBOARD_REPORTS,
    SAVE_ALL_DASHBOARD_REPORTS,
} from "../types";

const INITIAL_STATE = {
    dashboardReports: {},
};

const dashboardReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {

        case SAVE_ALL_DASHBOARD_REPORTS:
            return {
                ...state,
                dashboardReports: payload
            };

        case RESET_ALL_DASHBOARD_REPORTS:
            return {
                ...state,
                dashboardReports: INITIAL_STATE.dashboardReports,
            };

        default:
            return state;
    }
};

export default dashboardReducer;
