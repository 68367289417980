import React from "react";
import { Cancel } from "@mui/icons-material";
import { Stack } from "@mui/material";

function PaymentErrorPage() {

    // useEffect(() => {
    //     setTimeout(function () {
    //         window.open("https://hotels.travelnet.in/", "_self")
    //     }, 5000);
    // }, []);

    return (
        <>
            <Stack width="100%" minHeight={"100vh"} alignItems="center" justifyContent="center">
                <Stack direction="row" spacing={1} p={3} fontWeight="bold" bgcolor="red">
                    <Cancel />
                    <b>Payment Failed!</b>
                </Stack>
            </Stack>
        </>
    );
}

export default PaymentErrorPage;
