export const LIST_ALL_HOTEL_AGENTS = `query ($pageSize: Int, $pageNumber: Int, $isForDropdown: Boolean) {
  list_all_hotel_agents(page_size: $pageSize, page_number: $pageNumber, is_for_dropdown: $isForDropdown){
    message
    data {
      _id
      first_name
      last_name
      email
      mobile
      is_active
    }
    hasMore
  }
}`;

export const ADD_NEW_AGENT = `mutation ($firstName: String, $lastName: String, $email: String!, $mobile: String, $roomsAllowed: Int) {
  add_new_agent(first_name: $firstName, last_name: $lastName, email: $email, mobile: $mobile, rooms_allowed: $roomsAllowed){
    message
    _id
  }
}`;

export const LIST_AGENT_DETAILS = `query ($id: String!, $pageSize: Int, $pageNumber: Int, $isForDropdown: Boolean) {
  list_agent_details(_id: $id, page_size: $pageSize, page_number: $pageNumber, is_for_dropdown: $isForDropdown){
    message
    details {
      _id
      first_name
      last_name
      email
      mobile
      is_active
      rooms_allowed
      discount
    }
    data {
      _id
      name
      email
      mobile
      is_active
    }
    hasMore
  }
}`;

export const ADD_NEW_SUB_AGENT = `mutation ($agentId: String!, $name: String, $email: String!, $mobile: String) {
  add_new_sub_agent(agent_id: $agentId, name: $name, email: $email, mobile: $mobile){
    message
    _id
  }
}`;

export const ACTIVATE_AGENT = `mutation ($id: String!) {
  activate_agent(_id: $id){
    message
  }
}`;

export const ACTIVATE_SUB_AGENT = `mutation ($id: String!, $agentId: String!) {
  activate_sub_agent(_id: $id, agent_id: $agentId){
    message
  }
}`;

export const UPDATE_AGENT = `mutation ($id: String!, $firstName: String, $lastName: String, $mobile: String) {
  update_agent(_id: $id, first_name: $firstName, last_name: $lastName, mobile: $mobile){
    message
  }
}`;
