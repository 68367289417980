import { Backdrop, CircularProgress, useTheme } from "@mui/material";
import React from "react";

const Loader = ({ visible }) => {
    const theme = useTheme();

    return (
        <Backdrop open={visible} style={{ zIndex: 999 }}>
            <CircularProgress style={{color: theme.palette.secondary.main}}/>
        </Backdrop>
    );
};

export default Loader;
