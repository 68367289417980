import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, LinearProgress, Stack, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { tokens } from '../../../theme';
import Header from '../../../components/Header';
import RoomCreation from '../../registration/roomCreation';
import RoomSettings from '../../registration/roomSettings';

function BulkRoomtypeCreation() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [page, setPage] = useState(4);
    const [roomTypes, setRoomTypes] = useState({
        arr: [{
            name: '',
            description: "",
            no_of_beds: 1,
            min_no_of_people: 1,
            max_no_of_people: 2,
            ota_rooms: 0,
            is_private: false,
            display_room_no: localStorage?.getItem("isDependent") === "true" ? 1 : 0,
            highlights: [],
            facilities: [],
            images: [],
            rates: {
                standard_rate: null,
                child_rate: 0,
                single_occupancy_rate: 0,
                max_occupancy_rate: null,
                extra_bed_rate: 0,
            },
            rooms: [{
                room_no: "101",
                description: "",
                room_size: "",
            }],
        }]
    });

    useEffect(() => {
        document.title = 'Room type creation';
    }, []);

    return (
        <Box m="20px" mb={15}>
            <Stack
                direction="row"
                // alignItems="center"
                justifyContent={"space-between"}
                spacing={2}
                mt="20px"
            >
                <Header title="ROOM TYPE CREATION" subtitle="Create single or multiple room types" />
                <Box title="close" height={"40px"}>
                    <IconButton size='large' type="button" onClick={() => navigate(-1)}>
                        <Close fontSize='' />
                    </IconButton>
                </Box>
            </Stack>
            <Grid container mb={2}>
                <Grid item lg={12} xs={12}>
                    <LinearProgress variant="determinate" value={page === 4 ? 50: 100} />
                </Grid>
            </Grid>
            <Box
                width="100%"
                p={2}
                borderRadius={2}
                backgroundColor={colors.primary[400]}
            >
                {page === 4 && <RoomSettings setPage={setPage} roomTypes={roomTypes} setRoomTypes={setRoomTypes} colors={colors} />}
                {page === 5 && <RoomCreation setPage={setPage} roomTypes={roomTypes} setRoomTypes={setRoomTypes} colors={colors} dispatch={dispatch} />}
            </Box>
        </Box>
    )
}

export default BulkRoomtypeCreation;