import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, LinearProgress, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ArrowForward, Looks3, Looks4, Looks5, LooksOne, LooksTwo } from '@mui/icons-material';
import { listAllFacilities, listHotelProfile, resetHotelProfile, updateHotelProfile } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import BasicInfo from './basicInfo';
import Approval from './approval';
import PropertyInfo from './propertyInfo';
import RoomSettings from './roomSettings';
import RoomCreation from './roomCreation';

const initialBasicInfo = {
    title: "Mr",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirmPassword: "",
    addressLine1: "",
    addressLine2: "",
    name: "",
    country: "IN",
    state: "Kerala",
    city: "",
    phone: "",
    mobile: "",
    fax: "",
    postal_code: "",
    gstin: "",
    bank_details: {
        acc_holder_name: "",
        acc_no: "",
        ifsc: "",
        acc_type: "",
        bank_name: "",
        branch_name: "",
    }
};
const initialPropInfo = {
    name: "",
    type: "Hotel",
    address: "",
    city: "",
    location: "",
    country: "IN",
    state: "Kerala",
    postal_code: "",
    website: "",
    finance_email: "",
    finance_mobile: "",
    reservation_email: "",
    reservation_mobile: "",
    c_form_email: "",
    land_phone: "",
    fax: "",
}
const intialRoomtypeInfo = {
    arr: [{
        name: '',
        description: "",
        no_of_beds: 1,
        min_no_of_people: 1,
        max_no_of_people: 2,
        ota_rooms: 0,
        is_private: false,
        display_room_no: localStorage?.getItem("isDependent") === "true" ? 1 : 0,
        highlights: [],
        facilities: [],
        images: [],
        rates: {
            standard_rate: 0,
            child_rate: 0,
            single_occupancy_rate: 0,
            max_occupancy_rate: 0,
            extra_bed_rate: 0,
        },
        rooms: [{
            room_no: "101",
            description: "",
            room_size: "",
        }],
    }]
}

function Registration() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const colors = tokens(theme.palette.mode);
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const [page, setPage] = useState(1);
    const [basicInfoData, setBasicInfoData] = useState(initialBasicInfo);
    const [propInfoData, setPropInfoData] = useState(initialPropInfo);
    const [contactData, setContactData] = useState({name: ""});
    const [roomTypes, setRoomTypes] = useState(intialRoomtypeInfo);

    const { hotelProfile } = useSelector(
        ({
            registration: { hotelProfile },
        }) => ({
            hotelProfile,
        })
    );
    // console.log(hotelProfile);
      useEffect(() => {
        document.title = 'Registration';
        dispatch(resetHotelProfile());
        dispatch(listHotelProfile());
        dispatch(listAllFacilities());
        setBasicInfoData(hotelProfile);
        setPropInfoData(hotelProfile?.property);
        if(hotelProfile?.contact_person){
            setContactData(hotelProfile?.contact_person)
        }
        // eslint-disable-next-line
      },[dispatch]);

    const submitAndUpdateDatas = (data) => {
        console.log(data);
        dispatch(updateHotelProfile(data));
    }

    const saveProfileData = () => {
        let values = {};
        if (page === 1) {
            values = basicInfoData;
            if (values?.title === null) {
                values.title = "Mr";
            }
            if (values?.country === null) {
                values.country = "IN"
            }
            if (values?.state === null) {
                values.state = "Kerala"
            }
            // delete values?.property;
            // delete values?.contact_person;
            submitAndUpdateDatas(values);
        } else if (page === 3) {
            values.property = propInfoData;
            values.contact_person = contactData;
            submitAndUpdateDatas(values);
        }
    }

    const handleProfileSubmit = async (values) => {
        await setBasicInfoData({ ...basicInfoData, password: values?.password });
        if (basicInfoData?.password) {
            await saveProfileData();
            setPage(2);
        }
    };

    const handlePropertySubmit = async () => {
        if (propInfoData?.type === null) {
            propInfoData.type = "Hotel";
        }
        if (propInfoData?.country === null) {
            propInfoData.country = "IN"
        }
        if (propInfoData?.state === null) {
            propInfoData.state = "Kerala"
        }
        let data = {
            property: propInfoData, 
            contact_person: contactData
        };
        submitAndUpdateDatas(data);
        setPage(4);
    };

    const steps = [
        {
            name: "Basic info",
            icon: <LooksOne fontSize={smScreen ? "large" : "medium"} style={{ color: page > 0 ? "" : "gray" }} />,
        },
        {
            name: "Approval",
            icon: <LooksTwo fontSize={smScreen ? "large" : "medium"} style={{ color: page > 1 ? "" : "gray" }} />,
        },
        {
            name: "Property Info",
            icon: <Looks3 fontSize={smScreen ? "large" : "medium"} style={{ color: page > 2 ? "" : "gray" }} />,
        },
        {
            name: "Room Settings",
            icon: <Looks4 fontSize={smScreen ? "large" : "medium"} style={{ color: page > 3 ? "" : "gray" }} />,
        },
        {
            name: "Room Creation",
            icon: <Looks5 fontSize={smScreen ? "large" : "medium"} style={{ color: page > 4 ? "" : "gray" }} />,
        },
    ]

    return (
        <Box m="20px" mb={15}>
            <Stack m="20px 0">
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Header title="REGISTRATION" subtitle="complete your registration" />
                <Button size='small' variant='outlined' onClick={saveProfileData}>
                    <b>save progress</b>
                </Button>
                </Stack>
                <LinearProgress variant="determinate" value={(page * 2) * 10} />
            </Stack>
            <Grid container mb={2} spacing={{ lg: 2, xs: 1 }}>
                {steps.map((step, index) => (
                    <Grid item key={index}>
                        <Stack direction="row" alignItems="center" spacing={{ lg: 2, xs: 0.5 }}>
                            {step.icon}
                            <Typography variant={smScreen ? "h4" : ""} color={page > index ? colors.greenAccent[500] : "gray"}>
                                {step.name}
                            </Typography>
                            {index < 4 ? <ArrowForward fontSize={smScreen ? "medium" : "small"}  style={{ color: page > index ? colors.greenAccent[500] : "gray" }} /> : null}
                        </Stack>
                    </Grid>
                ))}
            </Grid>
            <Box
                width="100%"
                p={2}
                borderRadius={2}
                backgroundColor={colors.primary[400]}
            >
                {page === 1 && (
                    <BasicInfo
                        // setPage={setPage}
                        basicInfoData={basicInfoData}
                        setBasicInfoData={setBasicInfoData}
                        // saveProfileData={saveProfileData}
                        dispatch={dispatch}
                        handleProfileSubmit={handleProfileSubmit}
                        isRegistration
                    />
                )}
                {page === 2 && (
                    <Approval 
                        setPage={setPage} 
                        basicInfoData={basicInfoData} 
                    />
                )}
                {page === 3 && (
                    <PropertyInfo
                        setPage={setPage}
                        propInfoData={propInfoData}
                        setPropInfoData={setPropInfoData}
                        contactData={contactData}
                        setContactData={setContactData}
                        basicInfoData={basicInfoData}
                        setBasicInfoData={setBasicInfoData}
                        handlePropertySubmit={handlePropertySubmit}
                        isRegistration
                    />
                )}
                {page === 4 && (
                    <RoomSettings
                        setPage={setPage}
                        roomTypes={roomTypes}
                        setRoomTypes={setRoomTypes}
                        colors={colors}
                        isRegistration={true}
                    />
                )}
                {page === 5 && (
                    <RoomCreation
                        setPage={setPage}
                        roomTypes={roomTypes}
                        setRoomTypes={setRoomTypes}
                        colors={colors}
                        dispatch={dispatch}
                        isRegistration={true}
                    />
                )}
            </Box>
        </Box>
    )
}

export default Registration