import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import { addNewRate, listAllRooms, listAllRoomTypes, resetAvalableRoomsData } from "../../store/actions";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { Add, ArrowBack, Remove } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import * as yup from "yup";

const rateTypes = [
    {id:"season_rate", name: "Season Rate"}, 
    {id:"fixed_date_rate", name: "Fixed Date Rate"}, 
    {id:"weekday_rate", name: "Weekday Rate"}, 
    {id:"booking_date_rate", name: "Booking Date Rate"}, 
    {id:"occupancy_based_rate", name: "Occupancy Based Rate"}
];

function AddNewRate() {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const [selection, setSelection] = useState("room");
    const [selectedRoomType, setSelectedRoomType] = useState("");
    const [selectedRoom, setSelectedRoom] = useState("");
    const [newOffers, setNewOffers] = useState({ arr: [] });

    const { isLoading, roomTypes, rooms } = useSelector(
        ({
            loading, admin: {
                roomTypes: { list, showViewMore },
                rooms
            }
        }) => ({
            isLoading: loading,
            roomTypes: list,
            rooms
        })
    );

    useEffect(() => {
        document.title = 'New Booking';
        dispatch(listAllRooms());
        dispatch(listAllRoomTypes());
        dispatch(resetAvalableRoomsData());
    }, [dispatch]);

    const handleNewFieldBtn = () => {
        let today = moment().format("YYYY-MM-DD");
        var joined = newOffers.arr.concat({ 
            rate_type: '', 
            start_date: moment(today, "YYYY-MM-DD").unix(), 
            end_date: moment(today, "YYYY-MM-DD").unix(), 
            is_rate_fixed: true, 
            percentage: 0, 
            fixed_rate: 0, 
            is_rate_increment: true, 
        });
        setNewOffers({ arr: joined })
    }

    const handleNewFieldValueChange = (i, newValue, keyName) => {
        var valueAdd = newOffers.arr;
        if(newValue?.target?.value){
            valueAdd[i][keyName] = newValue.target.value;
        }else {
            valueAdd[i][keyName] = newValue
        }
        setNewOffers({ arr: valueAdd })
    }
    
    const removeField = (index) => {
        var arr = newOffers.arr;
        var filtered = arr.filter((e, i) => i !== index);
        setNewOffers({ arr: filtered })
      }
    

    const handleFormSubmit = async (values) => {
        let data = {
            ...values,
            offers: newOffers.arr
        };
        if(selection === "room"){
            if(selectedRoom === ""){
                return toast.error("Pls select a room");
            }
            data.roomId = selectedRoom
            data.roomType = "";
        } else {
            if(selectedRoomType === ""){
                return toast.error("Pls select a room type");
            }
            data.roomtypeId = selectedRoomType
            data.roomId = "";
        }
        // console.log(data);
        let status = await dispatch(addNewRate(data));
        if (status) {
            navigate("/pricings")
        }
    };
    const initialValues = {
        normalRate: 0,
    };
    const checkoutSchema = yup.object().shape({
        normalRate: yup.number().min(1, "Normal Rate must be greater than or equal to 1").required("Required"),
    });

    const handleSelectionChange = () => {
        if(selection === "room") {
            setSelection("room_type")
        }else {
            setSelection("room")
        }
    }

    return (
        <>
            <Box m="20px" mb={15}>
                <Grid container spacing={2}>
                    <Grid item lg={8} xs={12}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            m="20px 0"
                        >
                            <Box title="go back" height={"40px"}>
                                <IconButton type="button" onClick={() => navigate(-1)}>
                                    <ArrowBack />
                                </IconButton>
                            </Box>
                            <Header title="Add New Rate" titleVariant="h3" />
                        </Stack>

                        <Box
                            width="100%"
                            p={2}
                            backgroundColor={colors.primary[400]}
                        >
                            <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema}>
                                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={2}>
                                            <Grid item md={6} xs={12}>
                                                <Stack direction={"row"}>
                                                    <Checkbox checked={selection === "room"} onClick={handleSelectionChange}/>
                                                    <FormControl size="small" fullWidth>
                                                        <InputLabel>Room</InputLabel>
                                                        <Select
                                                            value={selectedRoom}
                                                            label="Room"
                                                            onChange={(e) => setSelectedRoom(e.target.value)}
                                                            disabled={selection !== "room"}
                                                        >
                                                            <MenuItem sx={{ display: "none" }} value={""}>
                                                                {"No Preference"}
                                                            </MenuItem>
                                                            {rooms?.list?.map((room, index) => (
                                                                <MenuItem value={room._id} key={index}>
                                                                    {room.room_no}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Stack direction={"row"}>
                                                    <Checkbox checked={selection === "room_type"} onClick={handleSelectionChange}/>
                                                    <FormControl size="small" fullWidth>
                                                        <InputLabel>Room Type</InputLabel>
                                                        <Select
                                                            // multiple
                                                            value={selectedRoomType}
                                                            label="Room Type"
                                                            onChange={(e) => setSelectedRoomType(e.target.value)}
                                                            disabled={selection !== "room_type"}
                                                        >
                                                            <MenuItem sx={{ display: "none" }} value={""}>
                                                                {"No Preference"}
                                                            </MenuItem>
                                                            {roomTypes?.map((roomType, index) => (
                                                                <MenuItem value={roomType._id} key={index}>
                                                                    {roomType.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    type="number"
                                                    placeholder="Normal Rate"
                                                    label="Normal Rate"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.normalRate}
                                                    name="normalRate"
                                                    InputProps={{ inputProps: { min: 1 } }}
                                                    error={!!touched.normalRate && !!errors.normalRate}
                                                    helperText={touched.normalRate && errors.normalRate}
                                                />
                                            </Grid>
                                            
                                            <Grid item md={12} xs={12}>
                                            {newOffers.arr.map((e, i) => (
                                                <Box spacing={1} key={i} p={2} mb={1} bgcolor={colors.primary[500]} borderRadius={2}>
                                                    <Grid container spacing={1}>
                                                        <Grid item md={6} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="date"
                                                                label="start date"
                                                                placeholder="Start date"
                                                                value={moment.unix(newOffers?.arr[i]?.start_date).format("YYYY-MM-DD")}
                                                                inputProps={{ min: moment().format("YYYY-MM-DD") }}
                                                                onChange={(newValue) => {
                                                                    let date = moment(newValue.target.value).format("YYYY-MM-DD")
                                                                    handleNewFieldValueChange(i, moment(date, "YYYY-MM-DD").unix(), 'start_date')
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="date"
                                                                label="End date"
                                                                placeholder="End date"
                                                                value={moment.unix(newOffers?.arr[i]?.end_date).format("YYYY-MM-DD")}
                                                                inputProps={{ min: moment.unix(newOffers?.arr[i]?.start_date).format("YYYY-MM-DD") }}
                                                                onChange={(newValue) => {
                                                                    let date = moment(newValue.target.value).format("YYYY-MM-DD")
                                                                    handleNewFieldValueChange(i, moment(date, "YYYY-MM-DD").unix(), 'end_date')
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item md={12} xs={12}>
                                                            <FormControl size="small" fullWidth>
                                                                <InputLabel>Rate Type</InputLabel>
                                                                <Select
                                                                    value={newOffers?.arr[i]?.rate_type}
                                                                    label="Rate Type"
                                                                    onChange={(newValue) => handleNewFieldValueChange(i, newValue, 'rate_type')}
                                                                >
                                                                    {rateTypes?.map((rateType, index) => (
                                                                        <MenuItem value={rateType.id} key={index}>
                                                                            {rateType.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                            <Stack direction={"row"} alignItems="center">
                                                                Fixed Rate 
                                                                <Checkbox 
                                                                    checked={newOffers?.arr[i]?.is_rate_fixed}
                                                                    onChange={() => {
                                                                        handleNewFieldValueChange(i, newOffers?.arr[i]?.is_rate_fixed === true ? false : true, 'is_rate_fixed')
                                                                    }}
                                                                />
                                                            </Stack>
                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                label="Fixed Rate"
                                                                placeholder="Fixed Rate"
                                                                defaultValue={newOffers?.arr[i]?.value}
                                                                disabled={newOffers?.arr[i]?.is_rate_fixed === false}
                                                                InputProps={{ inputProps: { min: 1 } }}
                                                                onChange={(newValue) => handleNewFieldValueChange(i, newValue, 'fixed_rate')}
                                                            />
                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                            <Stack direction={"row"} alignItems="center">
                                                                <Stack direction={"row"} alignItems="center">
                                                                    Increment
                                                                    <Checkbox
                                                                        checked={newOffers?.arr[i]?.is_rate_increment}
                                                                        disabled={newOffers?.arr[i]?.is_rate_fixed === true}
                                                                        onChange={() => {
                                                                            handleNewFieldValueChange(i, newOffers?.arr[i]?.is_rate_increment === true ? false : true, 'is_rate_increment')
                                                                        }}
                                                                    />
                                                                </Stack>
                                                                <Stack direction={"row"} alignItems="center">
                                                                    Decrement
                                                                    <Checkbox
                                                                        checked={!newOffers?.arr[i]?.is_rate_increment}
                                                                        disabled={newOffers?.arr[i]?.is_rate_fixed === true}
                                                                        onChange={() => {
                                                                            handleNewFieldValueChange(i, newOffers?.arr[i]?.is_rate_increment === true ? false : true, 'is_rate_increment')
                                                                        }}
                                                                    />
                                                                </Stack>
                                                            </Stack>
                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                label="Percentage"
                                                                placeholder="Percentage"
                                                                disabled={newOffers?.arr[i]?.is_rate_fixed === true}
                                                                InputProps={{ inputProps: { min: 0, max: 100 } }}
                                                                onChange={(newValue) => handleNewFieldValueChange(i, newValue, 'percentage')}
                                                            />
                                                        </Grid>
                                                        <Grid item lg={12} xs={12}>
                                                            <Button title='Remove' size='small' onClick={() => removeField(i)}>
                                                                <Remove /> <b>Remove</b>
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            ))}
                                            </Grid>

                                            <Grid item md={12} xs={12}>
                                                <Button onClick={handleNewFieldBtn}>
                                                    <Add/> <b>Add an offer</b>
                                                </Button>
                                            </Grid>

                                            {/* <Grid item md={6} xs={12}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                color="secondary"
                                                variant="outlined"
                                                sx={{ fontSize: "15px" }}
                                            >
                                                <b>Cancel</b>
                                            </Button>
                                        </Grid> */}
                                            <Grid item md={12} xs={12}>
                                                <Button
                                                    fullWidth
                                                    size="small"
                                                    type="submit"
                                                    color="secondary"
                                                    variant="contained"
                                                    sx={{ fontSize: "15px" }}
                                                >
                                                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </>
    )
}

export default AddNewRate;