import React from "react";
import { useSelector } from "react-redux";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { Formik } from "formik";
import Header from "../../components/Header";
import * as yup from "yup";

function LoginWithPassword({ onSubmit }) {
    const { isLoading } = useSelector(({ loading }) => ({
        isLoading: loading,
    }));
    const initialValues = {
        email: "",
        password: "",
    };
    const checkoutSchema = yup.object().shape({
        email: yup.string().email("Invalid email!").required("Required"),
        password: yup.string().required("Required"),
    });
    
    return (
        <Box m="20px">
            <Header title="HOTEL LOGIN" subtitle="Enter your email and password" />
            <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            required
                            variant="filled"
                            type="text"
                            label="Email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            error={!!touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                        />
                        <TextField
                            sx={{ mt: 2 }}
                            fullWidth
                            required
                            variant="filled"
                            type="password"
                            label="Password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            name="password"
                            error={!!touched.password && !!errors.password}
                            helperText={touched.password && errors.password}
                        />
                        <Box mt="20px">
                            <Button
                                fullWidth
                                size="large"
                                type="submit"
                                color="secondary"
                                variant="contained"
                                sx={{ fontSize: "15px" }}
                            >
                                {isLoading ? (
                                    <CircularProgress color="inherit" size={24} />
                                ) : (
                                    <b>LOGIN</b>
                                )}
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
}

export default LoginWithPassword;
