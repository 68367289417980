import React, { useEffect, useState } from 'react'
import { Box, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { authorizedPost, toggleLoading } from '../../store/actions';
import { LIST_GENERATED_REVENUE } from '../../graphql';
import { useDispatch } from 'react-redux';
import { tableStyle } from '../../components';
import { DataGrid } from '@mui/x-data-grid';
import { Check, Close } from '@mui/icons-material';
import moment from 'moment';

const years = (num = 10) => {
  const year = new Date(moment().add(5, "year")).getFullYear();
  return Array.from({ length: num }, (v, i) => year - num + i + 1);
}

const GeneratedRevenue = ({ colors }) => {
  const dispatch = useDispatch();
  const [revData, setRevData] = useState({});
  const [totalRev, setTotalRev] = useState("");
  const [year, setYear] = useState(moment().format("YYYY"));

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [year])

  const fetchData = async () => {
    dispatch(toggleLoading(true));
    let res = await authorizedPost(LIST_GENERATED_REVENUE, { year: year });
    if (res.status) {
      // console.log(res);
      let { list_generated_revenue: { data } } = res.data;
      setRevData(data);
      setTotalRev(data.data?.reduce((k, l) => k + l.generated_revenue, 0))
    }
    dispatch(toggleLoading(false));
  }

  const columns = [
    {
      field: "month",
      headerName: "Month",
      cellClassName: "name-column--cell",
      width: 150,
    },
    { field: "expected_revenue", headerName: "Expected revenue", width: 150 },
    { field: "generated_revenue", headerName: "Generated revenue", width: 150 },
    {
      field: "is_expecation_meets",
      headerName: "Expectation meets or not",
      width: 180,
      renderCell: ({ row: { is_expecation_meets } }) => is_expecation_meets ? <Check color="success" /> : <Close color="error" />,
    },
  ];

  return (
    <Box>
      <Stack alignItems="end" mb={2}>
        <TextField
          select
          size="small"
          value={year}
          onChange={(e) => {
            setYear(e.target.value)
          }}
        >
          {years()?.map((y) => (
            <MenuItem value={y} key={y}>
              {y}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <Box
        m="8px 0 0 0"
        height="350px"
        sx={tableStyle(colors)}
      >
        <DataGrid
          rows={revData.data || []}
          columns={columns}
          getRowId={(row) => row?._id}
        />
      </Box>
      <Box p={2} mt={2} borderRadius={2} bgcolor={colors.primary[400]}>
        <table>
          <tbody>
            <tr>
              <td><Typography mr={2}>Expected annual revenue</Typography></td>
              <td>: <b>{revData.total_expected_revenue}</b></td>
            </tr>
            <tr>
              <td><Typography mr={2}>Generated annual revenue</Typography></td>
              <td>: <b>{totalRev}</b></td>
            </tr>
          </tbody>
        </table>
        {/* {totalRev > revData.total_expected_revenue ?
        <Typography>Your property generated {totalRev - revData.total_expected_revenue} extra revenue</Typography>
        : null} */}
      </Box>
    </Box>
  );
}

export default GeneratedRevenue;