import React, { useEffect, useState } from 'react'
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { authorizedPost, toggleLoading } from '../../store/actions';
import { LIST_HOTEL_REVENUE_INPUTS } from '../../graphql';
import { useDispatch } from 'react-redux';
import { Add } from '@mui/icons-material';
import AddRevenueInputs from './addRevenueInputs';

// const initialValues = {
//   total_revenue: "",
//   data: [
//     { month: "Jan", amount: "" },
//     { month: "Feb", amount: "" },
//     { month: "Mar", amount: "" },
//     { month: "Apr", amount: "" },
//     { month: "May", amount: "" },
//     { month: "Jun", amount: "" },
//     { month: "Jul", amount: "" },
//     { month: "Aug", amount: "" },
//     { month: "Sep", amount: "" },
//     { month: "Oct", amount: "" },
//     { month: "Nov", amount: "" },
//     { month: "Dec", amount: "" },
//   ]
// }

const RevenueInputs = ({ colors }) => {
  const dispatch = useDispatch();
  const [revData, setRevData] = useState({});
  const [addPage, setAddPage] = useState(false);

  useEffect(() => {
    document.title = 'Revenue Manager';
    fetchData();
    // eslint-disable-next-line
  }, [])

  const fetchData = async () => {
    dispatch(toggleLoading(true));
    let res = await authorizedPost(LIST_HOTEL_REVENUE_INPUTS);
    if (res.status) {
      // console.log(res);
      setRevData(res.data.list_hotel_revenue_inputs.data.reverse()[0]);
    }
    dispatch(toggleLoading(false));
  }

  return (
    <Box>
      {addPage ? (
        <AddRevenueInputs colors={colors} setAddPage={setAddPage}/>
      ) : (
        <>
          <Stack alignItems="end" mb={2}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={() => setAddPage(true)}
            >
              <Add sx={{ mr: "10px" }} />
              Add Revenue
            </Button>
          </Stack>
          <Box
            width="100%"
            p={2}
            borderRadius={2}
            backgroundColor={colors.primary[400]}
          >
            {revData?.data?.length ? (
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <Typography variant='h4' color={colors.greenAccent[500]} fontWeight={700}>
                    Months
                  </Typography>
                </Grid>
                {revData?.data?.map((el, idx) => (
                  <Grid item md={6} xs={12} key={idx}>
                    <Stack spacing={2}>
                      <Box bgcolor={colors.primary[500]} p={2} borderRadius={2}>
                        <Stack direction="row" spacing={2} justifyContent="space-between">
                          <Typography variant='h4' color={colors.greenAccent[500]} fontWeight={700}>
                            {el?.month}
                          </Typography>
                          <Typography>{el?.amount || ""}</Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item md={6} xs={12}/>
                <Grid item md={6} xs={12}>
                  <Stack spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                      <Typography width={"50%"}>
                        Total annual revenue:
                      </Typography>
                      <Typography variant='h4'>{revData?.total_revenue || ""}</Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            ) : (
              <Stack mt={5} mb={5} textAlign="center">Not added</Stack>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

export default RevenueInputs;