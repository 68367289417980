import { SAVE_HOTEL_PROFILE, RESET_HOTEL_PROFILE } from "../types";

const INITIAL_STATE = {
    hotelProfile: {},
};

const registrationReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SAVE_HOTEL_PROFILE:
            return {
                ...state,
                hotelProfile: payload
            };

        case RESET_HOTEL_PROFILE:
            return {
                ...state,
                hotelProfile: INITIAL_STATE.hotelProfile,
            };

        default:
            return state;
    }
};

export default registrationReducer;
