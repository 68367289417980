import React from 'react';
import { Box, Button, Checkbox, Grid, IconButton, InputAdornment, MenuItem, Stack, Switch, Typography } from '@mui/material';
import { CssTextField } from '../../components/TextField';
import { Add, Remove } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import ImgUploaderInput from '../../components/ImgUploaderInput';
import PlusOrMinusButton from '../../components/PlusOrMinusButton';

function RoomSettings({ setPage, roomTypes, setRoomTypes, colors, isRegistration }) {

    const { highlights, facilities } = useSelector(
        ({
            admin: { highlights, facilities },
        }) => ({
            highlights, facilities,
        })
    );
    const handleNewFieldBtn = () => {
        var joined = roomTypes.arr.concat({
            name: '',
            description: "",
            no_of_beds: 1,
            min_no_of_people: 1,
            max_no_of_people: 2,
            ota_rooms: 0,
            is_private: false,
            display_room_no: localStorage?.getItem("isDependent") === "true" ? 1 : 0,
            highlights: [],
            facilities: [],
            images: [],
            rates: {
                standard_rate: null,
                child_rate: 0,
                single_occupancy_rate: 0,
                max_occupancy_rate: null,
                extra_bed_rate: 0,
            },
            rooms: [{
                room_no: "101",
                description: "",
                room_size: "",
            }],
        });
        setRoomTypes({ arr: joined })
    }

    const handleNewFieldValueChange = (i, newValue, keyName) => {
        var valueAdd = roomTypes.arr;
        if (newValue?.target) {
            valueAdd[i][keyName] = newValue.target.value;
        } else {
            valueAdd[i][keyName] = newValue
        }
        setRoomTypes({ arr: valueAdd })
    }
    const handleRateChange = (i, newValue, keyName) => {
        var valueAdd = roomTypes.arr;
        if (newValue?.target) {
            valueAdd[i].rates[keyName] = newValue?.target?.value || 0;
        } else {
            valueAdd[i].rates[keyName] = newValue || 0;
        }
        setRoomTypes({ arr: valueAdd })
    }

    const addNewRate = (i) => {
        var valueAdd = roomTypes.arr;
        if (valueAdd[i].rates.standard_rate === null) {
            valueAdd[i].rates.standard_rate = 0;
        } else if (valueAdd[i].rates.max_occupancy_rate === null) {
            valueAdd[i].rates.max_occupancy_rate = 0;
        }
        setRoomTypes({ arr: valueAdd })
    };

    const removeRate = (i, keyName) => {
        var valueAdd = roomTypes.arr;
        valueAdd[i].rates[keyName] = null;
        setRoomTypes({ arr: valueAdd })
    };
    
    // const handleMealPlansChange = (i, newValue, keyName, planfor) => {
    //     var valueAdd = roomTypes.arr;
    //     if (newValue?.target) {
    //         valueAdd[i].meal_plans[planfor][keyName] = newValue.target.value;
    //     } else {
    //         valueAdd[i].meal_plans[planfor][keyName] = newValue
    //     }
    //     setRoomTypes({ arr: valueAdd })
    // }

    const removeField = (index) => {
        if (roomTypes?.arr?.length > 1) {
            var arr = roomTypes.arr;
            var filtered = arr.filter((e, i) => i !== index);
            setRoomTypes({ arr: filtered })
        }
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(roomTypes);
        setPage(5)
    }

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                {roomTypes.arr.map((e, i) => (
                    <Box key={i} p={2} mb={1} bgcolor={colors.primary[500]} borderRadius={2}>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <Typography variant='h4' textTransform="uppercase" color={colors.greenAccent[200]} fontWeight={700}>
                                    Room Type {i + 1}
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"60%"}>
                                        Name of room type:*
                                    </Typography>
                                    <CssTextField
                                        fullWidth
                                        required
                                        size="small"
                                        type="text"
                                        placeholder="Name of room type"
                                        value={roomTypes?.arr[i]?.name}
                                        onChange={(newValue) => handleNewFieldValueChange(i, newValue, 'name')}
                                    />
                                </Stack>
                            </Grid>
                            {/* <Grid item md={6} xs={12}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"60%"}>
                                        Number of beds:*
                                    </Typography>
                                    <PlusOrMinusButton
                                        min={1}
                                        max={20}
                                        size="small"
                                        value={roomTypes?.arr[i]?.no_of_beds}
                                        handleChange={(newValue) => handleNewFieldValueChange(i, newValue, 'no_of_beds')}
                                    />
                                </Stack>
                            </Grid> */}
                            <Grid item md={6} xs={12}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"60%"}>
                                        Number of rooms:*
                                    </Typography>
                                    <PlusOrMinusButton
                                        min={1}
                                        max={200}
                                        size="small"
                                        value={roomTypes?.arr[i]?.rooms.length}
                                        handleChange={(newValue) => {
                                            let num = newValue;
                                            let arr = [];
                                            for (let j = 0; j < num; j++) {
                                                arr.push({
                                                    room_no: `10${j+1}`,
                                                    description: "",
                                                    room_size: "",
                                                })
                                            }
                                            // console.log(arr);
                                            handleNewFieldValueChange(i, arr, 'rooms')
                                            handleNewFieldValueChange(i, num, 'ota_rooms')
                                            // handleNewFieldValueChange(i, num, 'display_room_no')
                                        }}
                                    />
                                </Stack>
                            </Grid>
                            {/* <Grid item md={6} xs={12}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"60%"}>
                                        Rooms for OTA:
                                    </Typography>
                                    <PlusOrMinusButton
                                        min={0}
                                        max={roomTypes?.arr[i]?.rooms.length}
                                        size="small"
                                        value={roomTypes?.arr[i]?.ota_rooms}
                                        handleChange={(newValue) => handleNewFieldValueChange(i, newValue, 'ota_rooms')}
                                    />
                                </Stack>
                            </Grid> */}
                            {/* <Grid item md={6} xs={12}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"60%"}>
                                        Minimum number of people:*
                                    </Typography>
                                    <CssTextField
                                        fullWidth
                                        required
                                        size="small"
                                        type="number"
                                        placeholder="Minimum number of people"
                                        value={roomTypes?.arr[i]?.min_no_of_people}
                                        InputProps={{ inputProps: { min: 1 } }}
                                        onChange={(newValue) => handleNewFieldValueChange(i, newValue, 'min_no_of_people')}
                                    />
                                </Stack>
                            </Grid> */}
                            <Grid item md={6} xs={12}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"60%"}>
                                        Maximum number of people / room:*
                                    </Typography>
                                    <PlusOrMinusButton
                                        min={1}
                                        max={3}
                                        size="small"
                                        // label="Maximum number of people"
                                        value={roomTypes?.arr[i]?.max_no_of_people}
                                        handleChange={(newValue) => handleNewFieldValueChange(i, newValue, 'max_no_of_people')}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"60%"}>
                                        Highlights:
                                    </Typography>
                                    <CssTextField
                                        fullWidth
                                        select
                                        size="small"
                                        value={roomTypes?.arr[i]?.highlights}
                                        SelectProps={{ multiple: true }}
                                        onChange={(newValue) => { handleNewFieldValueChange(i, newValue, 'highlights')}}
                                    >
                                        {highlights?.list?.map((el, index) => (
                                            <MenuItem value={el._id} key={index}>
                                                <Checkbox sx={{ p: "0 5px" }} size='small' checked={roomTypes?.arr[i]?.highlights?.indexOf(el._id) > -1} />
                                                {el.name}
                                            </MenuItem>
                                        ))}
                                    </CssTextField>
                                </Stack>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"60%"}>
                                        Facilities:
                                    </Typography>
                                    <CssTextField
                                        fullWidth
                                        select
                                        size="small"
                                        value={roomTypes?.arr[i]?.facilities}
                                        SelectProps={{ multiple: true }}
                                        onChange={(newValue) => { handleNewFieldValueChange(i, newValue, 'facilities')}}
                                    >
                                        {facilities?.list?.map((el, index) => (
                                            <MenuItem value={el._id} key={index}>
                                                <Checkbox sx={{ p: "0 5px" }} size='small' checked={roomTypes?.arr[i]?.facilities?.indexOf(el._id) > -1} />
                                                {el.name}
                                            </MenuItem>
                                        ))}
                                    </CssTextField>
                                </Stack>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"60%"}>
                                        Description:
                                    </Typography>
                                    <CssTextField
                                        fullWidth
                                        multiline
                                        maxRows={Infinity}
                                        size="small"
                                        type="text"
                                        placeholder="Description"
                                        value={roomTypes?.arr[i]?.description}
                                        onChange={(newValue) => handleNewFieldValueChange(i, newValue, 'description')}
                                    />
                                </Stack>
                            </Grid>
                            {localStorage.getItem("isDependent") === "true" ?
                            <>
                                <Grid item md={12} xs={12}>
                                    <hr style={{padding: 0, border: 0, margin: 0, borderTop: "1px solid grey"}}/>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"34%"}>
                                            Private:
                                        </Typography>
                                        <Switch
                                            checked={roomTypes?.arr[i]?.is_private}
                                            onChange={(e) => handleNewFieldValueChange(i, e.target.checked, 'is_private')}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"60%"}>
                                            Number of rooms:*
                                        </Typography>
                                        <PlusOrMinusButton
                                            min={1}
                                            max={200}
                                            size="small"
                                            value={roomTypes?.arr[i]?.display_room_no}
                                            handleChange={(newValue) => handleNewFieldValueChange(i, newValue, 'display_room_no')}
                                        />
                                    </Stack>
                                </Grid>
                            </>
                            : null}
                            <Grid item md={12} xs={12}>
                                <Typography variant='h4' color={colors.greenAccent[500]} fontWeight={700}>
                                    Rates
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Stack spacing={2}>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"60%"}>
                                            Single occupancy rate:*
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="number"
                                            placeholder="Single occupancy rate"
                                            value={roomTypes?.arr[i]?.rates?.single_occupancy_rate ?? ""}
                                            InputProps={{ inputProps: { min: 1 } }}
                                            onChange={(newValue) => handleRateChange(i, newValue, 'single_occupancy_rate')}
                                        />
                                    </Stack>
                                    {roomTypes?.arr[i]?.rates?.standard_rate !== null ? (
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"60%"}>
                                            Double occupancy rate:*
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="number"
                                            placeholder="Double occupancy rate"
                                            value={roomTypes?.arr[i]?.rates?.standard_rate ?? ""}
                                            onChange={(newValue) => handleRateChange(i, newValue, 'standard_rate')}
                                            inputProps={{ min: roomTypes?.arr[i]?.rates?.single_occupancy_rate }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment sx={{ mr: -1 }} position="end">
                                                        <IconButton
                                                            size='small'
                                                            color='primary'
                                                            title='Remove rate'
                                                            onClick={() => removeRate(i, "standard_rate")}
                                                            disabled={roomTypes?.arr[i]?.rates.max_occupancy_rate !== null}
                                                        >
                                                            <Remove />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Stack>
                                    ) : null}
                                    {roomTypes?.arr[i]?.rates?.max_occupancy_rate !== null ? (
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography width={"60%"}>
                                            Triple occupancy rate:*
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            required
                                            size="small"
                                            type="number"
                                            placeholder="Triple occupancy rate"
                                            value={roomTypes?.arr[i]?.rates?.max_occupancy_rate ?? ""}
                                            onChange={(newValue) => handleRateChange(i, newValue, 'max_occupancy_rate')}
                                            inputProps={{ min: roomTypes?.arr[i]?.rates?.standard_rate }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment sx={{ mr: -1 }} position="end">
                                                        <IconButton
                                                            size='small'
                                                            color='primary'
                                                            title='Remove rate'
                                                            onClick={() => removeRate(i, "max_occupancy_rate")}
                                                        >
                                                            <Remove />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Stack>
                                    ) : null}
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Box width={"60%"} />
                                        <Button
                                            fullWidth
                                            size='small'
                                            disabled={roomTypes?.arr[i]?.rates?.max_occupancy_rate !== null || roomTypes?.arr[i]?.rates?.standard_rate === 0}
                                            onClick={() => addNewRate(i)}
                                        >
                                            <Add /> <b>Add new rate</b>
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Grid>
                            {/* <Grid item md={6} xs={12}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"60%"}>
                                        Extra bed rate:
                                    </Typography>
                                    <CssTextField
                                        fullWidth
                                        size="small"
                                        type="number"
                                        placeholder="Extra bed rate"
                                        value={roomTypes?.arr[i]?.rates?.extra_bed_rate}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        onChange={(newValue) => handleRateChange(i, newValue, 'extra_bed_rate')}
                                    />
                                </Stack>
                            </Grid> */}
                            <Grid item md={6} xs={12}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography width={"60%"}>
                                        Child rate:
                                    </Typography>
                                    <CssTextField
                                        fullWidth
                                        size="small"
                                        type="number"
                                        placeholder="Child rate"
                                        value={roomTypes?.arr[i]?.rates?.child_rate}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        onChange={(newValue) => handleRateChange(i, newValue, 'child_rate')}
                                    />
                                </Stack>
                            </Grid>
                            {/* <Grid item md={12} xs={12}>
                                <Typography variant='h4' color={colors.greenAccent[500]} fontWeight={700}>
                                    Meal plans
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Stack>
                                    <Grid container spacing={1}>
                                        <Grid item md={12} xs={12}>
                                            <Typography color={colors.greenAccent[600]} fontWeight={700}>
                                                Adult
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={6}>
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <Typography width={"40%"}>
                                                    EP:
                                                </Typography>
                                                <CssTextField
                                                    fullWidth
                                                    size="small"
                                                    type="number"
                                                    placeholder="EP"
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                    value={roomTypes?.arr[i]?.meal_plans?.adult?.ep}
                                                    onChange={(newValue) => handleMealPlansChange(i, newValue, 'ep', 'adult')}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item md={6} xs={6}>
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <Typography width={"40%"}>
                                                    CP:
                                                </Typography>
                                                <CssTextField
                                                    fullWidth
                                                    size="small"
                                                    type="number"
                                                    placeholder="CP"
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                    value={roomTypes?.arr[i]?.meal_plans?.adult?.cp}
                                                    onChange={(newValue) => handleMealPlansChange(i, newValue, 'cp', 'adult')}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item md={6} xs={6}>
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <Typography width={"40%"}>
                                                    MAP:
                                                </Typography>
                                                <CssTextField
                                                    fullWidth
                                                    size="small"
                                                    type="number"
                                                    placeholder="MAP"
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                    value={roomTypes?.arr[i]?.meal_plans?.adult?.map}
                                                    onChange={(newValue) => handleMealPlansChange(i, newValue, 'map', 'adult')}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item md={6} xs={6}>
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <Typography width={"40%"}>
                                                    AP:
                                                </Typography>
                                                <CssTextField
                                                    fullWidth
                                                    size="small"
                                                    type="number"
                                                    placeholder="AP"
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                    value={roomTypes?.arr[i]?.meal_plans?.adult?.ap}
                                                    onChange={(newValue) => handleMealPlansChange(i, newValue, 'ap', 'adult')}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Stack>
                                    <Grid container spacing={1}>
                                        <Grid item md={12} xs={12}>
                                            <Typography color={colors.greenAccent[600]} fontWeight={700}>
                                                Child
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={6}>
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <Typography width={"40%"}>
                                                    EP:
                                                </Typography>
                                                <CssTextField
                                                    fullWidth
                                                    size="small"
                                                    type="number"
                                                    placeholder="EP"
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                    value={roomTypes?.arr[i]?.meal_plans?.child?.ep}
                                                    onChange={(newValue) => handleMealPlansChange(i, newValue, 'ep', 'child')}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item md={6} xs={6}>
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <Typography width={"40%"}>
                                                    CP:
                                                </Typography>
                                                <CssTextField
                                                    fullWidth
                                                    size="small"
                                                    type="number"
                                                    placeholder="CP"
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                    value={roomTypes?.arr[i]?.meal_plans?.child?.cp}
                                                    onChange={(newValue) => handleMealPlansChange(i, newValue, 'cp', 'child')}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item md={6} xs={6}>
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <Typography width={"40%"}>
                                                    MAP:
                                                </Typography>
                                                <CssTextField
                                                    fullWidth
                                                    size="small"
                                                    type="number"
                                                    placeholder="MAP"
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                    value={roomTypes?.arr[i]?.meal_plans?.child?.map}
                                                    onChange={(newValue) => handleMealPlansChange(i, newValue, 'map', 'child')}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item md={6} xs={6}>
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <Typography width={"40%"}>
                                                    AP:
                                                </Typography>
                                                <CssTextField
                                                    fullWidth
                                                    size="small"
                                                    type="number"
                                                    placeholder="AP"
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                    value={roomTypes?.arr[i]?.meal_plans?.child?.ap}
                                                    onChange={(newValue) => handleMealPlansChange(i, newValue, 'ap', 'child')}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Grid> */}
                            <Grid item md={12} xs={12}>
                                <Typography variant='h4' color={colors.greenAccent[500]} fontWeight={700}>
                                    Images
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <ImgUploaderInput 
                                    images={roomTypes?.arr[i]?.images}
                                    onChange={(newValue) => handleNewFieldValueChange(i, newValue, 'images')}
                                />
                            </Grid>
                            {roomTypes?.arr?.length > 1 && <Grid item lg={12} xs={12}>
                                <Button title='Remove' size='small' onClick={() => removeField(i)}>
                                    <Remove /> <b>Remove</b>
                                </Button>
                            </Grid>}
                        </Grid>
                    </Box>
                ))}
                <Button fullWidth size='small' onClick={handleNewFieldBtn}>
                    <Add /> <b>Add new room type</b>
                </Button>
                <Stack mt={2} direction="row" justifyContent={isRegistration ? "space-between" : "flex-end"}>
                    {isRegistration && <Button variant='outlined' onClick={() => setPage(3)}>
                        <b>go back</b>
                    </Button>}
                    <Button variant='contained' type='submit'>
                        <b>Next</b>
                    </Button>
                </Stack>
            </form>
        </Box>
    )
}

export default RoomSettings