import React from "react";
import { Cancel } from "@mui/icons-material";
import { Stack } from "@mui/material";

function ErrorPage({ msg = "Failed!" }) {

    return (
        <>
            <Stack width="100%" minHeight={"100vh"} alignItems="center" justifyContent="center">
                <Stack direction="row" spacing={1} p={3} fontWeight="bold" bgcolor="red">
                    <Cancel />
                    <b>{msg}</b>
                </Stack>
            </Stack>
        </>
    );
}

export default ErrorPage;
