import { CheckCircle } from "@mui/icons-material";
import React from "react";
import { Stack } from "@mui/material";

function PaymentSuccessPage() {

    // useEffect(() => {
    //     setTimeout(function () {
    //         window.open("https://hotels.travelnet.in/", "_self")
    //     }, 5000);
    // }, []);

    return (
        <>
            <Stack width="100%" minHeight={"100vh"} alignItems="center" justifyContent="center">
                <Stack direction="row" spacing={1} p={3} fontWeight="bold" bgcolor="green">
                    <CheckCircle />
                    <b>Payment Success!</b>
                </Stack>
            </Stack>
        </>
    );
}

export default PaymentSuccessPage;
