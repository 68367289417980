import React, { useEffect, useState } from "react";
import { ArrowBack, FileCopy } from "@mui/icons-material";
import { Box, Button, Grid, Modal, Stack, Typography } from "@mui/material";
import { listInventoryScheduleDetails, resetInventoryScheduleDetails } from "../../store/actions";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { tableStyle } from "../../components";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import moment from "moment";
import { toast } from "react-toastify";

function InventoryScheduleDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [modal, setModal] = useState(false);
    const [modalContent, setModalContent] = useState("");

    const modalStyle = (wid) => {
        return {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { sm: wid, xs: "90%" },
            bgcolor: colors.primary[400],
            outline: "none",
            boxShadow: 24,
            p: 2,
        }
    };

    const { invScheduleDetails } = useSelector(
        ({
            calendar: { invScheduleDetails },
        }) => ({
            invScheduleDetails,
        })
    );

    useEffect(() => {
        document.title = 'Inventory schedule details';
        dispatch(resetInventoryScheduleDetails());
        fetchMoreData();
        // eslint-disable-next-line
    }, [dispatch, id]);

    const fetchMoreData = () => {
        dispatch(listInventoryScheduleDetails({ id: id }));
    }

    const columns = [
        {
            field: "timeline",
            headerName: "Timeline",
            width: 180,
            cellClassName: "name-column--cell",
            valueGetter: (params) => `${params.row.timeline ? moment.unix(params.row.timeline).format("DD-MM-YYYY - HH:mm") : "-"}`,
        },
        { field: "status", headerName: "Status", width: 150 },
        {
            field: "request",
            headerName: "Request",
            width: 320,
            renderCell: ({ row: { request } }) => (
                <Stack direction="row" alignItems="center">
                    <Typography noWrap width={230}>{request}</Typography>
                    <Button
                        size="small"
                        variant="text"
                        sx={{ fontWeight: 600 }}
                        onClick={() => {
                            setModalContent(request);
                            setModal(true);
                        }}>
                        See more
                    </Button>
                </Stack>
            ),
        },
        {
            field: "response",
            headerName: "Response",
            width: 320,
            renderCell: ({ row: { response } }) => (
                <Stack direction="row" alignItems="center">
                    <Typography noWrap width={230}>{response}</Typography>
                    <Button
                        size="small"
                        variant="text"
                        sx={{ fontWeight: 600 }}
                        onClick={() => {
                            setModalContent(response);
                            setModal(true);
                        }}>
                        See more
                    </Button>
                </Stack>
            ),
        },
    ]

    const KeyAndValue = ({ keyName, value }) => (
        <Grid item md={6} xs={12}>
            <Stack direction="row" spacing={2}>
                <Typography width={"40%"}>{keyName}:</Typography>
                <Typography>
                    {value}
                </Typography>
            </Stack>
        </Grid>
    )

    return (
        <Box m="20px" mb={15}>
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
                spacing={2}
                m="20px 0"
            >
                <Button size="small" variant="outlined" onClick={() => navigate(-1)}>
                    <ArrowBack fontSize="small" />
                </Button>
                <Typography fontWeight="bold" variant="h4">
                    INVENTORY SCHEDULE DETAILS
                </Typography>
                <Box width={40} />
            </Stack>
            <Box
                width="100%"
                p={2}
                borderRadius={1}
                backgroundColor={colors.primary[400]}
            >
                <Grid container rowSpacing={2} columnSpacing={4}>
                    <KeyAndValue keyName={"Start date"} value={invScheduleDetails?.start_date || "-"} />
                    <KeyAndValue keyName={"End date"} value={invScheduleDetails?.end_date || "-"} />
                    <KeyAndValue keyName={"OTA"} value={invScheduleDetails?.ota || "-"} />
                    <KeyAndValue keyName={"Rooms available"} value={invScheduleDetails?.rooms_available || "-"} />
                    <KeyAndValue keyName={"Api calls"} value={invScheduleDetails?.api_calls || "-"} />
                    <KeyAndValue keyName={"Roomtype"} value={invScheduleDetails?.roomtype_id?.name || "-"} />
                    <KeyAndValue keyName={"Status"} value={invScheduleDetails?.status || "-"} />
                    <Grid item md={12} xs={12}>
                        <Typography variant='h4' color={colors.greenAccent[500]} fontWeight={700}>
                            Rates
                        </Typography>
                    </Grid>
                    <KeyAndValue keyName={"Double occupancy rate"} value={invScheduleDetails?.rates?.standard_rate || 0} />
                    <KeyAndValue keyName={"Triple occupancy rate"} value={invScheduleDetails?.rates?.max_occupancy_rate || 0} />
                    <KeyAndValue keyName={"Single occupancy rate"} value={invScheduleDetails?.rates?.single_occupancy_rate || 0} />
                    <KeyAndValue keyName={"Extra bed rate"} value={invScheduleDetails?.rates?.extra_bed_rate || 0} />
                    <KeyAndValue keyName={"Child rate"} value={invScheduleDetails?.rates?.child_rate || 0} />
                </Grid>
            </Box>
            {invScheduleDetails?.calls_timeline ? (
                <>
                    <Stack
                        mt={4}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="end"
                    >
                        <Header title="CALLS TIMELINE" mb={0} />
                    </Stack>

                    <Box
                        mt={2}
                        height="60vh"
                        sx={tableStyle(colors)}
                    >
                        <DataGrid
                            rows={invScheduleDetails?.calls_timeline}
                            columns={columns}
                            getRowId={() => Math.random()}
                        />
                    </Box>
                </>
            ) : null}

            <Modal
                open={modal}
                onClose={() => setModal(false)}
            >
                <Box sx={modalStyle(600)}>
                    <Box maxHeight={"80vh"} mb={2} overflow="auto">
                        <Typography>{modalContent}</Typography>
                    </Box>
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <Button
                            size="small"
                            variant="contained"
                            endIcon={<FileCopy />}
                            onClick={() => {
                                navigator.clipboard.writeText(modalContent)
                                toast.success("Copied!");
                            }}
                        >
                            <b>Copy to clipboard</b>
                        </Button>
                        <Button size="small" onClick={() => setModal(false)}><b>Close</b></Button>
                    </Stack>
                </Box>
            </Modal>
        </Box>
    );
}

export default InventoryScheduleDetails;
