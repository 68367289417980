import React from 'react';
import { Box, Button, CircularProgress, MenuItem, Stack } from '@mui/material';
import { CssTextField } from '../../components/TextField';
import { addNewUser } from '../../store/actions';
import { Formik } from 'formik';
import Header from '../../components/Header';
import * as yup from "yup";

function AddNewUserModal({ dispatch, isLoading, setModal, colors }) {

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { sm: 400, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        role: "manager",
    };
    const checkoutSchema = yup.object().shape({
        email: yup.string().email("Invalid email").required("Required"),
    });
    const handleFormSubmit = async (values) => {
        console.log(values);
        await dispatch(addNewUser(values));
        setModal(false);
    };

    return (
        <>
            <Box sx={modalStyle}>
                <>
                    <Header title="ADD NEW STAFF" titleVariant="h4" />
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>

                                <Stack spacing={2}>
                                    <CssTextField
                                        required
                                        fullWidth
                                        size="small"
                                        type="text"
                                        label="Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.firstName}
                                        name="firstName"
                                        error={!!touched.firstName && !!errors.firstName}
                                        helperText={touched.firstName && errors.firstName}
                                    />
                                    <CssTextField
                                        required
                                        fullWidth
                                        size="small"
                                        type="email"
                                        label="Email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        name="email"
                                        error={!!touched.email && !!errors.email}
                                        helperText={touched.email && errors.email}
                                    />
                                    <CssTextField
                                        required
                                        fullWidth
                                        select
                                        size="small"
                                        value={values.role}
                                        label="Role"
                                        name="role"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"manager"}>
                                            {"manager"}
                                        </MenuItem>
                                        <MenuItem value={"admin"}>
                                            {"admin"}
                                        </MenuItem>
                                    </CssTextField>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        color="secondary"
                                        variant="contained"
                                        sx={{ fontSize: "15px" }}
                                    >
                                        {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                                    </Button>
                                </Stack>
                            </form>
                        )}
                    </Formik>
                </>
            </Box>
        </>
    )
}

export default AddNewUserModal