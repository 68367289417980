/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, Chip, Grid, MenuItem, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material'
import { listRoomDetails, updateRoom } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBack, Close, Edit } from '@mui/icons-material';
import { UpdatableField } from '../../components/TextField';
import { tokens } from '../../theme';
import { Formik } from 'formik';
import ConnectedRooms from './connected-rooms';

const textColor = {
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#dbdbdb",
  },
}

function RoomDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [tab, setTab] = useState(1);
  const [updation, setUpdation] = useState(false);
  const [selectedRoomType, setSelectedRoomType] = useState("");
  const [selectedHighlights, setSelectedHighlights] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);

  const { roomDetails, roomTypes, highlights, facilities } = useSelector(
    ({
      admin: { roomDetails, roomTypes, highlights, facilities },
    }) => ({
      roomDetails, roomTypes, highlights, facilities,
    })
  );

  useEffect(() => {
    document.title = 'Room details';
    // dispatch(resetRoomTypeDetails());
    dispatch(listRoomDetails({ id: id }));
    setSelectedRoomType(roomDetails?.roomtype_id?._id)
    setSelectedHighlights(roomDetails?.highlights?.map(a => a._id))
    setSelectedFacilities(roomDetails?.facilities?.map(a => a._id))
  }, [dispatch, id]);

  const handleFormSubmit = async (values) => {
    values.id = id;
    // values.rates = rates;
    values.roomtype_id = selectedRoomType
    values.highlights = selectedHighlights
    values.facilities = selectedFacilities
    console.log(values);
    setUpdation(updation ? false : true)
    await dispatch(updateRoom(values));
  };

  return (
    <Box m="20px" mb={15}>
      <Stack
        direction={"row"}
        alignItems={"end"}
        spacing={1}
        m="20px 0"
      >
        {!updation && <Button size='small' variant='outlined' onClick={() => navigate(-1)}>
          <ArrowBack fontSize='small' />
        </Button>}
        <Typography fontWeight="bold" variant='h4'>
          ROOM DETAILS
        </Typography>
      </Stack>

      <Box sx={{ borderBottom: 1, mb: 2, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={(e, n) => setTab(n)} variant="scrollable" scrollButtons="auto">
          <Tab value={1} label="Details" sx={{ fontWeight: "bold" }} />
          {localStorage?.getItem("isConnectedRooms") === "true" ? (  
          <Tab value={2} label="Connected Rooms" sx={{ fontWeight: "bold" }} />
          ) : null}
        </Tabs>
      </Box>

      {tab === 1 && (
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={roomDetails}
      >
        {({ values, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>

            <Box
              width="100%"
              p={2}
              borderRadius={2}
              backgroundColor={colors.primary[400]}
            >
              <Grid container columnSpacing={4} rowSpacing={2}>
                <Grid item xs={12}>
                  <Stack alignItems="end">
                    {!updation && <Button
                      size='small'
                      variant='outlined'
                      onClick={() => setUpdation(updation ? false : true)}
                      startIcon={<Edit />}
                    >
                      <b>Update</b>
                    </Button>}
                    {updation &&
                      <Stack direction="row" spacing={1}>
                        <Button
                          size='small'
                          variant='outlined'
                          onClick={() => setUpdation(false)}
                        >
                          <Close fontSize='small' />
                        </Button>
                        <Button
                          size='small'
                          type='submit'
                          variant='contained'
                        // onClick={handleFormSubmit}
                        >
                          <b>Save</b>
                        </Button>
                      </Stack>}
                  </Stack>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Stack spacing={{ md: 3, xs: 2 }}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography width={"50%"}>
                        Room No:
                      </Typography>
                      <UpdatableField
                        required
                        fullWidth
                        size='small'
                        type="text"
                        name="room_no"
                        sx={textColor}
                        disabled={!updation}
                        value={values?.room_no || ""}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography width={"50%"}>
                        Description:
                      </Typography>
                      <UpdatableField
                        fullWidth
                        size='small'
                        type="text"
                        name="description"
                        sx={textColor}
                        disabled={!updation}
                        value={values?.description || ""}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography width={"50%"}>
                        Room type:
                      </Typography>
                      <UpdatableField
                        select
                        fullWidth
                        size="small"
                        sx={textColor}
                        disabled={!updation}
                        // label="Room type"
                        value={selectedRoomType || ""}
                        onChange={(e) => setSelectedRoomType(e.target.value)}
                      >
                        {roomTypes?.list?.map((e, i) => (
                          <MenuItem value={e._id} disabled={!e?.is_active} key={i}>
                            {e.name}
                          </MenuItem>
                        ))}
                      </UpdatableField>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography width={"50%"}>
                        Room size:
                      </Typography>
                      <UpdatableField
                        fullWidth
                        size='small'
                        type="text"
                        name="room_size"
                        sx={textColor}
                        disabled={!updation}
                        value={values?.room_size || ""}
                        InputProps={{ inputProps: { min: 1 } }}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                      <Typography width={"50%"}>
                        Availability:
                      </Typography>
                      <Chip
                        variant="outlined"
                        label={roomDetails?.is_active ? "active" : "inactive"}
                        color={roomDetails?.is_active ? "primary" : "warning"}
                      />
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Stack spacing={{ md: 3, xs: 2 }}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography width={"50%"}>
                        Highlights:
                      </Typography>
                      <UpdatableField
                        select
                        fullWidth
                        size="small"
                        sx={textColor}
                        disabled={!updation}
                        value={selectedHighlights || []}
                        SelectProps={{ multiple: true }}
                        onChange={(e) => setSelectedHighlights(e.target.value)}
                      >
                        {highlights?.list?.map((e, i) => (
                          <MenuItem value={e._id} key={i}>
                            <Checkbox sx={{ p: "0 5px" }} size="small" checked={selectedHighlights?.indexOf(e._id) > -1} />
                            {e.name}
                          </MenuItem>
                        ))}
                      </UpdatableField>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography width={"50%"}>
                        Facilities:
                      </Typography>
                      <UpdatableField
                        select
                        fullWidth
                        size="small"
                        sx={textColor}
                        disabled={!updation}
                        value={selectedFacilities || []}
                        SelectProps={{ multiple: true }}
                        onChange={(e) => setSelectedFacilities(e.target.value)}
                      >
                        {facilities?.list?.map((e, i) => (
                          <MenuItem value={e._id} key={i}>
                            <Checkbox sx={{ p: "0 5px" }} size="small" checked={selectedFacilities?.indexOf(e._id) > -1} />
                            {e.name}
                          </MenuItem>
                        ))}
                      </UpdatableField>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
      )}

      {tab === 2 && (
        <ConnectedRooms
          connectedRoomsList={roomDetails?.connected_rooms}
          roomId={id}
          dispatch={dispatch} 
          colors={colors} 
        />
      )}
    </Box>
  )
}

export default RoomDetails