export const ADD_NEW_ROOM = `mutation ($roomNo: String!, $roomtypeId: String!, $description: String, $roomSize: String, $highlights: [String], $facilities: [String], $images: [String]) {
    add_new_room(room_no: $roomNo, roomtype_id: $roomtypeId, description: $description, room_size: $roomSize, highlights: $highlights, facilities: $facilities, images: $images){
      message
    }
  }
`;

export const LIST_ALL_ROOMS = `query ($pageSize: Int, $pageNumber: Int) {
    list_all_rooms(page_size: $pageSize, page_number: $pageNumber){
      message
      data {
        _id
        room_no
        no_of_beds
        min_no_of_people
        max_no_of_people
        description
        room_size
        rate
        is_active
        roomtype_id {
          name
          description
        }
      }
      hasMore
    }
  }
`;

export const UPDATE_ROOM = `mutation(
    $id: String!,
    $room_no: String!,
    $description: String,
    $room_size: String,
    $roomtype_id: String!,
    $highlights: [String],
    $facilities: [String],
    $images: [String],
  ){
  update_room(
    _id: $id,
    room_no: $room_no,
    description: $description,
    room_size: $room_size,
    roomtype_id: $roomtype_id,
    highlights: $highlights,
    facilities: $facilities,
    images: $images,
  ){
    message
  }
  }
`;

export const LIST_ROOM_DETAILS = `query ($id: String!) {
    list_room_details(_id: $id){
      message
      data {
        _id
        room_no
        description
        room_size
        is_active
        rate
        roomtype_id {
          _id
          name
          description
          no_of_beds
          min_no_of_people
          max_no_of_people
          rates {
            standard_rate
            child_rate
            sigle_occupancy_rate
            max_occupancy_rate
            extra_bed_rate
          }
        }
        highlights {
          _id
          name
          icon
        }
        facilities {
          _id
          name
          icon
        }
        connected_rooms {
          _id
          room_no
          roomtype_id {
            _id
            name
          }
        }
      }
    }
  }
`;

export const ACTIVATE_ROOM = `mutation ($id: String!) {
    activate_room(_id: $id){
      message
    }
  }
`;

export const UPDATE_CONNECTED_ROOMS = `mutation ($id: String!, $connectedRooms: [String]!) {
  update_connected_rooms(_id: $id, connected_rooms: $connectedRooms){
    message
  }
}`;