import React, { useEffect } from "react";
import Loader from "../../components/Loader";
import { Stack } from "@mui/material";

function Airpay({ paymentData }) {

    useEffect(() => {
        setTimeout(function () {
            document.getElementById("sendtoairpay").submit();
        }, 1000);
    }, []);

    return (
        <>
            <Loader visible={true} />
            <Stack width={"100%"} textAlign="center" p={2}>
                Loading payment page...
            </Stack>
            <form method="POST" action="https://payments.airpay.co.in/pay/index.php" id="sendtoairpay">
                <input
                    type="hidden"
                    name="privatekey"
                    value={paymentData?.private_key}
                />
                <input type="hidden" name="mercid" value={paymentData?.mid} />
                <input type="hidden" name="checksum" value={paymentData?.checksum} />
                <input type="hidden" name="currency" value="356" />
                <input type="hidden" name="isocurrency" value="INR" />
                <input type="hidden" name="chmod" value="" />
                <input type="hidden" name="buyerEmail" value={paymentData?.email} />
                <input type="hidden" name="buyerPhone" value={paymentData?.guest_mobile} />
                <input type="hidden" name="buyerFirstName" value={paymentData?.first_name} />
                <input type="hidden" name="buyerLastName" value={paymentData?.last_name} />
                <input type="hidden" name="buyerAddress" value="" />
                <input type="hidden" name="buyerCity" value="" />
                <input type="hidden" name="buyerState" value="" />
                <input type="hidden" name="buyerCountry" value="" />
                <input type="hidden" name="buyerPinCode" value={paymentData?.buyer_pincode} />
                <input type="hidden" name="amount" value={paymentData?.amount} />
                <input type="hidden" name="txnsubtype" value="" />
                <input type="hidden" name="orderid" value={paymentData?.order_id} />
            </form>
        </>
    );
}

export default Airpay;
