export const LIST_ALL_DASHBOARD_REPORTS = `query{
    list_all_dashboard_reports{
      message
      data{
        checkins
        checkouts
        occupancies{
          roomtype
          total_rooms
          booked_rooms
        }
        upcoming_online_bookings{
          month
          bookings
        }
      }
    }
  }
`;

export const LIST_ALL_HOTEL_REPORTS = `query ($month_and_year: String) {
  list_all_hotel_reports(month_and_year: $month_and_year) {
    message
    data {
      room_nights {
        direct_bookings
        ota_bookings
        website_bookings
        ota_data {
          ota
          count
        }
      }
      roomtype_nights {
        monthly {
          _id
          name
          data {
            month
            count
          }
        }
        yearly {
          _id
          name
          data {
            year
            count
          }
        }
      }
      booking_counts {
        upcoming {
          month
          count
        }
        previous {
          month
          count
        }
      }
    }
  }
}`;