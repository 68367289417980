/*eslint-disable*/
import React, { useEffect } from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import { listAllRates, resetAllRatesData } from "../../store/actions";
import { useDispatch } from "react-redux";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router";
import moment from "moment";

const Pricings = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();

  const { ratesData, showViewMore } = useSelector(
    ({
      admin: { rates: { list, showViewMore }},
    }) => ({
      ratesData: list,
      showViewMore,
    })
  );

  useEffect(() => {
    document.title = 'Pricings';
    dispatch(resetAllRatesData());
    fetchMoreData();
  },[dispatch]);

  const fetchMoreData = () => {
    let today = moment().format("YYYY-MM-DD");
    dispatch(listAllRates({startDate: moment(today, "YYYY-MM-DD").unix(), endDate: moment(today, "YYYY-MM-DD").unix()}));
  }

  const CustomFooter = () => {
    return (
      <GridFooterContainer sx={{borderRadius: "0 0 5px 5px"}}>
        <Stack width="100%" alignItems="center">
          {showViewMore ? <Button fullWidth onClick={fetchMoreData}>
            <b>view more</b>
          </Button> : <Typography variant="h6">All datas listed</Typography>}
        </Stack>
      </GridFooterContainer>
    );
  }
  
  const columns = [
    { field: "_id", headerName: "Id", cellClassName: "name-column--cell", width: 200  },
    { field: "current_rate", headerName: "Current Rate", width: 150  },
    // { field: "status", headerName: "Status", width: 100 },
    {
      field: "roomtype",
      headerName: "Room Type",
      width: 250,
      renderCell: ({ row: { roomtype } }) => {
        return (
          <Box>
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {roomtype !== null ? roomtype.name : ""}
            </Typography>
          </Box>
        );
      },
    },
  ];
  return (
    <Box m="20px" mb={15}>
      <Stack 
        direction={{sm: "row", xs: "column"}} 
        justifyContent={{sm: "space-between", xs: "start"}} 
        alignItems={{sm: "center", xs: "start"}}
        m="20px 0"
      >
        <Header title="PRICINGS" subtitle="welcome to Pricings" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={() => navigate("/new-pricing")}
          >
            <Add sx={{ mr: "10px" }} />
            Add new rate
          </Button>
        </Box>
      </Stack>
      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            // borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={ratesData}
          columns={columns}
          components={{ Footer: CustomFooter }}
          getRowId={(row) => row?._id}
        />
      </Box>
    </Box>
  );
};

export default Pricings;
