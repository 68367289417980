import React, { useState } from 'react'
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { useDispatch } from 'react-redux';
import Header from "../../components/Header";
import RevenueInputs from './revenueInputs';
import GeneratedRevenue from './generatedRevenue';
import RatesInRevManager from './rates';
import { useNavigate, useSearchParams } from 'react-router-dom';

const RevManage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const [searchParams, setSearchParams] = useSearchParams();
  var tabNum = searchParams.get("tab");
  const [tab, setTab] = useState(parseInt(tabNum) || 1);

  const handleTabChange = (num) => {
    setTab(num); 
    navigate(`/revenue-manager?tab=${num}`)
  }

  return (
    <Box m="20px" mb={15}>
      <Header title="REVENUE MANAGER" subtitle="Welcome to revenue manager" />
      <Box sx={{ borderBottom: 1, mb: 2, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={(e, n) => handleTabChange(n)} variant="scrollable" scrollButtons="auto">
          <Tab value={1} label="Expected revenue" sx={{ fontWeight: "bold" }} />
          <Tab value={2} label="Generated revenue" sx={{ fontWeight: "bold" }} />
          <Tab value={3} label="Rates" sx={{ fontWeight: "bold" }} />
        </Tabs>
      </Box>

      {tab === 1 && <RevenueInputs colors={colors}/>}
      {tab === 2 && <GeneratedRevenue colors={colors}/>}
      {tab === 3 && <RatesInRevManager dispatch={dispatch} colors={colors}/>}
    </Box>
  );
}

export default RevManage;