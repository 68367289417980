export const LOGIN_WITH_OTP = `query ($email: String!, $otp: String!) {
  hotel_login(email: $email, otp: $otp) {
      message
      token
    }
  }
`;

export const SEND_OTP = `mutation ($email: String!) {
    send_otp(email: $email) {
      message
    }
  }
`;

export const LOGIN_WITH_PASSWORD = `query ($email: String!, $password: String!) {
    login_with_password(email: $email, password: $password){
      message
      token
    }
  }
`;

export const VERIFY_HOTEL_TOKEN = `query ($token: String!) {
    verify_hotel_token(token: $token){
      status
    }
  }
`;

export const LIST_ALL_STAFF_HOTELS = `query ($pageSize: Int, $pageNumber: Int) {
  list_all_staff_hotels(page_size: $pageSize, page_number: $pageNumber){
    message
    data {
      _id
      name
    }
    hasMore
  }
}`;

export const GENERATE_HOTEL_TOKEN = `query ($hotelId: String!) {
  generate_hotel_token(hotel_id: $hotelId){
    message
    token
  }
}`;