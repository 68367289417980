import React, { useState } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { CssTextField } from "../../components/TextField";
import { authorizedPost } from "../../store/actions";
import { ADD_NEW_REVENUE_INPUT } from "../../graphql";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ArrowBack } from "@mui/icons-material";

const initialValues = [
  { month: "Jan", amount: "" },
  { month: "Feb", amount: "" },
  { month: "Mar", amount: "" },
  { month: "Apr", amount: "" },
  { month: "May", amount: "" },
  { month: "Jun", amount: "" },
  { month: "Jul", amount: "" },
  { month: "Aug", amount: "" },
  { month: "Sep", amount: "" },
  { month: "Oct", amount: "" },
  { month: "Nov", amount: "" },
  { month: "Dec", amount: "" },
];

const AddRevenueInputs = ({ colors, setAddPage }) => {
  const navigate = useNavigate();
  const [months, setMonths] = useState(initialValues);
  // eslint-disable-next-line
  const [temp, setTemp] = useState("");

  const handleNewFieldValueChange = (i, newValue, keyName) => {
    var valueAdd = months;
    if (newValue?.target) {
      valueAdd[i][keyName] = newValue.target.value;
    } else {
      valueAdd[i][keyName] = newValue;
    }
    setMonths(valueAdd);
    setTemp(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let values = {
      totalRevenue: months.reduce((k, l) => k + parseFloat(l.amount), 0),
      data: months,
    };
    // console.log(values);
    let res = await authorizedPost(ADD_NEW_REVENUE_INPUT, values);
    if (res.status) {
      toast.success("Data saved");
      setAddPage(false);
      navigate(0);
    }
  };

  return (
    <Box>
      <Stack
        direction={"row"}
        alignItems={"end"}
        spacing={1}
        mb={2}
      >
        <Button size='small' variant='outlined' onClick={() => setAddPage(false)}>
          <ArrowBack fontSize='small' />
        </Button>
        <Typography fontWeight="bold" variant='h4'>
          ADD EXPECTED REVENUE
        </Typography>
      </Stack>
      <Box width="100%" p={2} borderRadius={2} backgroundColor={colors.primary[400]}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Typography variant="h4" color={colors.greenAccent[500]} fontWeight={700}>
                Months
              </Typography>
            </Grid>
            {months?.map((el, idx) => (
              <Grid item md={6} xs={12} key={idx}>
                <Stack spacing={2}>
                  <Box bgcolor={colors.primary[500]} p={2} borderRadius={2}>
                    <Grid container rowSpacing={2} columnSpacing={4}>
                      <Grid item md={12} xs={12}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography variant="h4" color={colors.greenAccent[500]} fontWeight={700}>
                            {el?.month}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography width={"50%"}>Amount:*</Typography>
                          <CssTextField
                            fullWidth
                            required
                            size="small"
                            type="number"
                            placeholder="Amount"
                            value={el?.amount || ""}
                            onChange={(newValue) =>
                              handleNewFieldValueChange(idx, newValue, "amount")
                            }
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
              </Grid>
            ))}
            <Grid item md={12} xs={12}>
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Button size="small" variant="outlined" onClick={() => setAddPage(false)}>
                  <b>Cancel</b>
                </Button>
                <Button type="submit" size="small" variant="contained">
                  <b>Submit</b>
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default AddRevenueInputs;
