import React from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

function Approval({ setPage, basicInfoData }) {

    const handleSubmit = async (values) => {
        setPage(3);
    };

    return (

        <Box>
            <Grid container spacing={{ md: 4, xs: 2 }}>
                <Grid item md={6} xs={12}>
                    <Stack spacing={2}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography width={"40%"}>
                                Full Name:
                            </Typography>
                            <Typography>
                                {basicInfoData?.title} {basicInfoData?.first_name || "-"} {basicInfoData?.last_name}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography width={"40%"}>
                                Email:
                            </Typography>
                            <Typography>
                                {basicInfoData?.email || "-"}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography width={"40%"}>
                                Address:
                            </Typography>
                            <Stack>
                                <Typography>
                                    {basicInfoData?.address1 || "-"}
                                </Typography>
                                <Typography>
                                    {basicInfoData?.address2}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Stack spacing={2}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography width={"40%"}>
                                Property/Chain Name:
                            </Typography>
                            <Typography>
                                {basicInfoData?.name || "-"}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography width={"40%"}>
                                Country:
                            </Typography>
                            <Typography>
                                {basicInfoData?.country || "-"}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography width={"40%"}>
                                State:
                            </Typography>
                            <Typography>
                                {basicInfoData?.state || "-"}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography width={"40%"}>
                                City:
                            </Typography>
                            <Typography>
                                {basicInfoData?.city || "-"}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography width={"40%"}>
                                Phone Number:
                            </Typography>
                            <Typography>
                                {basicInfoData?.phone || "-"}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography width={"40%"}>
                                Mobile Number:
                            </Typography>
                            <Typography>
                                {basicInfoData?.mobile || "-"}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography width={"40%"}>
                                Fax:
                            </Typography>
                            <Typography>
                                {basicInfoData?.fax || "-"}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography width={"40%"}>
                                Postal Code:
                            </Typography>
                            <Typography>
                                {basicInfoData?.postal_code || "-"}
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Box>
                        <Stack direction="row" justifyContent="space-between">
                            <Button size='small' variant='outlined' onClick={() => setPage(1)}>
                                <b>Go back & Update</b>
                            </Button>
                            <Button type="submit" size='small' variant='contained' onClick={handleSubmit}>
                                <b>Confirm</b>
                            </Button>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Approval