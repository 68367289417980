import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkRoomtypePricing, resetRoomtypePricingData } from "../../store/actions";
import { Button, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import PlusOrMinusButton from "../../components/PlusOrMinusButton";
import { toast } from "react-toastify";

function SelectedRoomsModal({ roomtypeId, setPage, selected, colors }) {
    // console.log(selected);
    const dispatch = useDispatch();
    const datas = localStorage.getItem("checkingData");
    const [numOfAdults, setNumOfAdults] = useState(selected?.max_no_of_people < 2 ? 1 : 2);
    const [numOfChilds, setNumOfChilds] = useState(0);
    const [totalRooms, setTotalRooms] = useState(1);

    const { isLoading, hotelDetails, pricing } = useSelector(({ loading, bookingEngine: { hotelDetails, pricing } }) => ({
        isLoading: loading,
        hotelDetails,
        pricing,
    }));
    
    let { min_child_age, max_child_age } = hotelDetails;

    useEffect(() => {
        dispatch(resetRoomtypePricingData());
        dispatch(
            checkRoomtypePricing({
                roomtypeId: roomtypeId,
                ...{
                    ...JSON.parse(datas),
                    numOfAdults: numOfAdults,
                    numOfChilds: numOfChilds,
                    totalRooms: totalRooms,
                    mealPlanId: selected.meal_plan._id,
                },
            })
        );
        // eslint-disable-next-line
    }, [numOfAdults, numOfChilds, totalRooms]);

    const handleContinue = () => {
        if((numOfAdults + numOfChilds) > selected?.max_no_of_people) {
            return toast.error("Total number of people cannot exceed maximum occupancy");
        }
        if(totalRooms > selected?.available_rooms) {
            return toast.error("Number of rooms cannot exceed available rooms");
        }
        let values = {
            ...JSON.parse(datas),
            numOfAdults : numOfAdults,
            numOfChilds: numOfChilds,
            totalRooms: totalRooms,
        }
        console.log(values);
        localStorage.setItem("checkingData", JSON.stringify(values));

        setPage(3)
    }
    return (
        <>
            <Grid container spacing={1} mb={2}>
                <Grid item xs={12}>
                <Typography textTransform="uppercase" fontWeight={700} fontSize={20} color={colors.greenAccent[500]}>
                    {selected?.name}
                </Typography>
                </Grid>
                {/* <Grid item xs={6}>
                    <Typography><b>Rooms</b></Typography>
                    <PlusOrMinusButton
                        min={1}
                        max={selected?.available_rooms}
                        name="totalRooms"
                        value={totalRooms}
                        handleChange={(value) => setTotalRooms(value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    {selected?.display_room_no ? (
                        <Stack justifyContent="end" height={"100%"}>
                        <Typography>Total rooms: {selected?.display_room_no * selected?.available_rooms}</Typography>
                        </Stack>
                    ) : null}
                </Grid>
                <Grid item xs={6}>
                    <Typography><b>Adults / Room</b></Typography>
                    <PlusOrMinusButton
                        min={1}
                        max={selected?.max_no_of_people}
                        name="numOfAdults"
                        value={numOfAdults}
                        handleChange={(value) => setNumOfAdults(value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography><b>Children / Room</b></Typography>
                    <PlusOrMinusButton
                        min={0}
                        max={selected?.max_no_of_people}
                        name="numOfChilds"
                        value={numOfChilds}
                        handleChange={(value) => setNumOfChilds(value)}
                    />
                    <Typography>
                        Child Age: {min_child_age || 0} to {max_child_age || 18}
                    </Typography>
                </Grid> */}
                {/* <Grid item xs={12}>
                    <Typography>
                        Child age: {min_child_age || 0} to {max_child_age || 18}
                    </Typography>
                </Grid> */}
            </Grid >
            <Stack maxHeight={"60vh"} overflow="auto">
                {pricing?.rates?.map((e, i) => (
                    <Stack 
                        key={i} 
                        p={1} 
                        border={1} 
                        borderColor={"GrayText"}
                        direction="row" 
                        justifyContent="space-between"
                    >
                        <Typography>{e.date}</Typography>
                        <Typography>{(e.price + selected.meal_plan.rate || 0).toFixed(2)}</Typography>
                    </Stack>
                ))}
            </Stack>
            <Stack 
                p={1} 
                direction="row" 
                justifyContent="space-between"
            >
                <Typography>Total</Typography>
                <Stack textAlign="end">
                    <Typography fontWeight={"bold"}>₹{(pricing?.total_price1 + selected?.meal_plan?.rate || 0).toFixed(2) || "-"}</Typography>
                    <Typography color={"#aaa"} fontSize={"small"}>Plus taxes</Typography>
                </Stack>
            </Stack>
            <Button 
                fullWidth 
                variant="contained" 
                onClick={handleContinue}
                disabled={isLoading || pricing?.total_price1 < 0}
            >
                {isLoading ? (
                    <CircularProgress color="inherit" size={24} />
                ) : (
                    <b>Continue</b>
                )}
            </Button>
        </>
    );
}

export default SelectedRoomsModal;
